import { inject, Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, filter, Subject } from 'rxjs';
import { IModalReturn } from '../models/troly_object';


const duration = 15000;

@Injectable({
	providedIn: 'root',
})
export class WindowService {

	/**
	* The name or identifier of the current class, not otherwise available when running in "production mode". 
	* It is used to output debugging information on the console, and also attached to translations of labels, product tours, etc
	*/
	public readonly __name:string = 'WindowService';
	
	
	private snackBar: MatSnackBar = inject(MatSnackBar);
	private messageTimeout;

	indicator = new BehaviorSubject<string>('');
	pendingChanges:number=0;

	public layoutActionNotifier = new BehaviorSubject(null);

	/**
	 * 
	 */

	public onAnyModalClose$: Subject<[any, IModalReturn]> = new Subject<[any, IModalReturn]>();

	private setClearTimer() {
		if (this.messageTimeout) {
			clearTimeout(this.messageTimeout);
		}

		this.messageTimeout = setTimeout(() => {
			this.indicator.next('');
		}, duration);
	}

	saving(message?: string) {
	  this.pendingChanges = this.pendingChanges + 1;
	  this.indicator.next(message || `Saving ${'.'.repeat(this.pendingChanges)}`);
	  this.setClearTimer();
	}

	saved(message?: string) {
	  this.pendingChanges = this.pendingChanges - 1;
	  if (this.pendingChanges == 0) {
		this.indicator.next(message);
		this.setClearTimer();
		return true;
	  } 
	  return false;
	}

	failed(message?: string) {
	  this.pendingChanges = this.pendingChanges - 1;
	  this.indicator.next(message || 'Could not save changes');
	  //this.setClearTimer();
	}

	// @description Open a material snack-bar with a message and optional action
	// @param message [String] the message to display on the snackbar
	// @param style [String] the styling of the snackbar, e.g. success, error, warning
	// @param manualDismiss [Boolean] whether or not the snackbar should auto dismiss
	// @param actionCallback [Function] a function to run when the snackbar action is clicked
	// @param actionString [String] the action button string
	openSnackBar(message: string, style?:'success'|'error'|'warning'|'none', manualDismiss?: boolean, actionCallback?: Function, actionString?: string) {
	  // set the config up
	  let config: MatSnackBarConfig = {
		//horizontalPosition: 'left',
		verticalPosition: 'top',
		panelClass: ['troly-snackbar-x', `troly-snackbar-${style}`],
	  };

	  // if we aren't dismissable then auto dismiss after 5 seconds
	  if (manualDismiss) {
		// TODO - enable manual dismiss, requires some extra logic
		// See material link above 'Dismissal' header
	  } else {
		config.duration = 3000;
	  }

	  // open returns a ref to the snackbar which we need to use
	  // if an action was passed in
	  let snackBarRef = this.snackBar.open(message, actionString, config);

	  // if we have an action then we subscribe to 'onAction'
	  // this is triggered when the snackbar is closed by any means
	  // e.g. action is clicked or duration expires
	  if (actionString) {
		snackBarRef.onAction().subscribe(() => {
			actionCallback();
		});
	  }
	}

	recordUpdateNotification(message: string, url?: string) {
		let snackBarRef = this.snackBar.open(message, (url ? 'View' : ''), {
		  duration: 90000,
		  verticalPosition: 'bottom',
		  horizontalPosition: 'right',
		  panelClass: ['troly-snackbar-x', `troly-snackbar-bottom`],
		});

		snackBarRef.onAction().subscribe(() => {
			debugger;
			document.location = url;
		});
	 }


	public currentCardTargeted$: BehaviorSubject<string> = new BehaviorSubject("");
	public currentFieldTargeted$: BehaviorSubject<string> = new BehaviorSubject("");
	protected route: ActivatedRoute = inject(ActivatedRoute);

	constructor() {
		
		this.route.fragment.pipe(filter(_ => !!_)).subscribe((_) => {
			const parts = _.split('-');
			this.currentCardTargeted$.next(parts[0]);

			if (parts.length > 1) {
				this.currentFieldTargeted$.next(parts[1]);
			} else {
				this.currentFieldTargeted$.next("");
			}

		});


		this.modalService.activeInstances.subscribe((_) => { this.modalStack = _; });


		this.debug$.next(window.localStorage.getItem('de')?.split('|').filter(_ => _) || []);
		this.debug$.subscribe((_) => {
			if (_.length == 0) { window.localStorage.removeItem('de'); console.info('Debugging ended (OFF)') } 
			else { window.localStorage.setItem('de', _.join('|')); console.info('Debugging activated (ON)', _) }
		});

	}
	
	protected modalStack: NgbModalRef[] = [];
	public get currentModal(): NgbModalRef|null {
		if (this.modalStack.length == 0) { return null; }
		return this.modalStack[this.modalStack.length-1];
	}

	protected modalService: NgbModal  = inject(NgbModal); // allows opening and interacting with modals in a TrolyComponent-standard fashion */

	public openModal(template, options?: any): boolean {

		this.modalService.open(template, options);

		this.currentModal?.result.then((result:IModalReturn) => {
			this.onAnyModalClose$.next([template,result]);
		}, (reason) => { 
			this.onAnyModalClose$.next([template,reason]);
		});

		return this.modalService.hasOpenModals();
	}

	public clearModal(count:number=1): void {
		this.currentModal?.dismiss();
		if (count > 1) { this.clearModal(count-1); }
	}
	public readyToOpen(): boolean {
		return !this.modalService.hasOpenModals();
	}


	public debug$: BehaviorSubject<string[]> = new BehaviorSubject([]);
	public log(message: string, level: string = 'INFO') {
		if (this.debug$.value.includes(level)) {
			if (level == 'INFO') {
				console.log(`[${level.toUpperCase()}] ${message}`);
			} else if (level == 'TRACE') {
				console.debug(`[${level.toUpperCase()}] ${message}`);
			} else if (level == 'API') {
				console.info(`[${level.toUpperCase()}] ${message}`);
			} else if (level == 'ERROR') {
				console.error(`[${level.toUpperCase()}] ${message}`);
			} 

		}
	}
	
	protected debugToggle(aspect:string, enable:boolean) {
		if (this.debug$.value.includes(aspect)) {
			if (enable == false) {
				this.debug$.next(this.debug$.value.filter(_ => _ != aspect));
			}	
		} else {
			if (enable == true) {
				this.debug$.next([aspect, ...this.debug$.value]);
			}
		}
	}

	public set debugHints(value:boolean) { this.debugToggle('HINTS', value); }
	public set debugForms(value:boolean) { this.debugToggle('FORMS', value); }
	public set debugInfo(value:boolean) { this.debugToggle('INFO', value); }
	public set debugAPI(value:boolean) { this.debugToggle('API', value); }
	public set debugTrace(value:boolean) { this.debugToggle('TRACE', value); }

}
