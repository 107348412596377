import { Injectable, inject } from '@angular/core';
import * as IntroJs from 'intro.js/intro.js';



import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { User } from '../models/troly/user.model';
import { CompanyService } from './troly/company.service';
import { UserService } from './troly/user.service';

@Injectable({
	providedIn: 'root',
})
export class IntrojsService {

	/**
	 * The name or identifier of the current class, not otherwise available when running in "production mode". 
	 * It is used to output debugging information on the console, and also attached to translations of labels, product tours, etc
	 */
	public readonly __name:string = 'IntrojsService';

	private introJs = IntroJs();
	private baseUrl = `https://hub.troly.co/app.troly.co/json/`;

	private readonly STORAGE_KEYS = {
		CSRF_TOKEN: 'cs',
		SELECTED_COMPANY: 'co',
		ACTIVE_USER: 'us',
		USER_CACHE: 'uc',
	}

	//protected trolyApi: TrolyApi = inject(TrolyApi);
	protected httpClient: HttpClient = inject(HttpClient);

	protected userService: UserService = inject(UserService);
	protected companyService: CompanyService = inject(CompanyService);
	protected authenticatedUser:User;

	constructor() {
		//super();

		this.introJs._introJsCurrentSteps = [];
		this.introJs.setOptions({ hidePrev: true, doneLabel: 'Close', steps: [] });

		this.introJs.onexit((_) => { this.introJs.removeHints(); });

		this.introJs.oncomplete((_) => {
			if (this.introJs._introJsCurrentSteps.length > 0) {
				this.addAsCompleted(this.introJs._introJsCurrentSteps);
				this.introJs._introJsCurrentSteps = [];
			}
			//this.introJs.removeHints(); // not needed --  this is included in onexit()

		})
	}


	/**
	 * 
	 * @param target 
	 */
	public clear(target?: 'completed' | 'data') {

		if (!target || target == 'completed') {
			this.userService.save(new User({ id: this.authenticatedUser.id, pref_platform_tours: {} })).subscribe();
		}

		if (!target || target == 'data') {
//			this.trolyApi.deleteDbKey(`${this.__name}`)
		}
	}

	get completed(): string[] {

		if (this.authenticatedUser.pref_platform_tours && this.authenticatedUser.pref_platform_tours['all']) {
			return this.authenticatedUser.pref_platform_tours['all'] || [];
		}
		return [];
	}

	public addAsCompleted(value: string[]) {

		let currentVal = {}

		if (this.authenticatedUser.pref_platform_tours && typeof this.authenticatedUser.pref_platform_tours === 'object') { 
			currentVal = this.authenticatedUser.pref_platform_tours
		}

		currentVal[this._introJsCurrentCollection] = value;
		currentVal['all'] = currentVal['all'] || [];
		currentVal['all'] = [...currentVal['all'], ...value];

		this.userService.save(new User({ id: this.authenticatedUser.id, pref_platform_tours: currentVal })).subscribe();
	}

	get available(): number {
		let cacheKey = this.STORAGE_KEYS + this.constructor.name;
		return 0;
//		return parseInt(this.trolyApi.cachedUiKey(cacheKey) || '0')
	}

	public addAsAvailable(value: number) {
		let cacheKey = this.STORAGE_KEYS + this.constructor.name;
		let currentVal = this.available;
		currentVal += value
//		this.trolyApi.cachedUiKey(cacheKey, currentVal);
	}

	private dataObservables: BehaviorSubject<any>[] = [];

	public loadPage(page: string): Observable<any> {

		let url = `${this.baseUrl}${page}.json`
		let cacheKey = `${this.__name}.${page}.page`

		if (!this.dataObservables[cacheKey]) {
			this.dataObservables[cacheKey] = new BehaviorSubject(null);

			let cache// = this.trolyApi.cachedDbKey(cacheKey);

			if (cache != null) {
				this.dataObservables[cacheKey].next(cache);
			} else {

				// trigger a request to our WP article with the introjs steps
				const headers = new HttpHeaders();
				headers.set('Accept', 'application/json; charset=utf-8');
				//headers.set('Cache-Control', 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0')
				//headers.set('Pragma','no-cache')
				//headers.set('Expires','0') // bypass disk caching??
				//https://stackoverflow.com/questions/53232382/how-to-disable-caching-with-httpclient-get-in-angular-6

				// The above doesn't seem to work, it always uses the browser cache when present which is a problem, as we want
				// a refresh every 24hrs. Hence, we are forcefully adding a timestamps to 'muck' the browser into getting a new file.
				this.httpClient.get(url + "?" + (new Date()).getTime(), { headers: headers }).subscribe((_json) => {
					if (_json) {
						// parse the pellets to a JS object
						//this.trolyApi.storeDbKey(cacheKey, _json);
						this.dataObservables[cacheKey].next(_json);

						if (_json['meta'].walkthroughCount) {
							this.addAsAvailable(_json['meta'].walkthroughCount)
						}
					}
				},
					(error) => {
						console.error(`Troly Intro - failed to load from ${url}`, error);
					});
			}
		}

		return this.dataObservables[cacheKey];
	}

	protected _introJsCurrentSteps: string[] = [];
	protected _introJsCurrentCollection: string;
	/**
	 * 
	 * @param pages is either the name of the 
	 * @param service 
	 * @returns 
	 */
	public displayIntro(pages: string[]) {

		//if (this.route.snapshot.fragment) {
//			return; // when there's a page fragment being targeted, we want to keep the focus on that, and not the intros.
	//	}
		let collectionToRender = pages[0]
		let containedInCollection = pages[1] || pages[0];

		if (false && !this.authenticatedUser.hasCompletedWalkthrough(collectionToRender)) { // at the moment we're not checking which of the steps were, or weren't done, only if it's overall been completed before

			// locate the WP introjs identifier for this page
			this.loadPage(containedInCollection).pipe(filter(_ => !!_), take(1)).subscribe((_data) => {

				if (!_data[collectionToRender]) {
					console.error(`component ${collectionToRender} not found in page ${containedInCollection}.json`, _data)

				} else if (_data[collectionToRender].walkthrough) {

					this.introJs.setOption('steps', []);
					this.introJs.setOption('steps', null);
					this.introJs.removeHints();
					this.introJs._introJsCurrentSteps = [];
					this.introJs._introItems = []; // this seems to be the only one working to clear previously set steps.
					//this.introJs.refresh();

					if (containedInCollection != collectionToRender && _data[containedInCollection] && _data[containedInCollection].walkthrough) {
						// PARENT: add steps not completed already, 
						let parentSteps = this.addWalkthoughSteps(_data[containedInCollection].walkthrough);
						if (parentSteps.length > 0) {

							// and record the current steps to be marked as completed oncomplete
							this.introJs._introJsCurrentSteps.push(...parentSteps);

							// force the pageSummary to be displayed
							//this.localCache.storeUiKey(`show-page-summary.${containedInCollection}`, 'yes')
						}
					}

					// CHILDREN add steps not completed already, and record the current steps to be marked as completed oncomplete
					this.introJs._introJsCurrentSteps.push(...(this.addWalkthoughSteps(_data[collectionToRender].walkthrough)));

					//this.introJs.refresh();

					this.introJs.start();
					this._introJsCurrentCollection = collectionToRender;
				}
			})
		}
	}

	/**
	 * Adds a new step to the walkthrough and attaches the correct marker
	 * Each step is validated and has not been part of a completed walkthrough before.
	 * @param steps - the list of steps coming directly from a page from the troly-content
	 * @returns the introJS-formatted filtered steps that have not been completed.
	 */
	protected addWalkthoughSteps(steps: any[]): string[] {
		let stepsAdded = [];

		steps.filter(_ => !this.authenticatedUser.hasCompletedWalkthrough(_.uuid)).map((step) => {

			stepsAdded.push(step.uuid);

			let newStep = {
				intro: step.intro,
				uuid: step.uuid,
				position: step.position,
			};

			if (document.querySelector(`.${step.uuid}`)) {
				newStep['element'] = document.querySelector(`.${step.uuid}`);
			}

			this.introJs.addStep(newStep);
		});
		return stepsAdded;
	}
}
