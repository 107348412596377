<div id="apps" selectable class="{{ containerCss }}">
    <help *ngIf="helpCollection" [collection]="helpCollection"></help>
    <div class="{{ bsCssClassPrefix }}-header" *ngIf="showCardHeader">
		<h4 class="{{ bsCssClassPrefix }}-title"><span [innerHtml]="__name + '.Titles.'+(label ? 'w_label' : 'wo_label') | translate : { label:label }"></span><socket-status></socket-status></h4>
		<p class="card-subtitle">Data communications between {{ selectedCompany.business_name }} and your <a routerLink="/apps/dashboard" [queryParams]="{status:'installed'}">connected systems</a>.</p>
    </div>
    <div class="{{ containerCss == 'card' ? 'card-body' : '' }}">
        <div class="row" *ngIf="toolbar">
            <div class="col-12 toolbar">
					<div class="toolbar-left">
						
						<ng-container *ngIf="toolbar.includes('_all') || toolbar.includes('status')">

							<span ngbDropdown class="btn-card-action" *ngVar="queriesApplied(['severity','user_id']).length as filtersApplied" autoClose="outside">
								<button class="btn btn-primary" ngbDropdownToggle>
									<span *ngIf="filtersApplied == 0">{{ __name + '.Filter.BySeverity.all' | translate: {count:totalRecords} }}</span>
									<span *ngIf="filtersApplied == 1" [innerHtml]="'GenericFilters.applied_one' | translate: {count:filtersApplied}"></span>
									<span *ngIf="filtersApplied >= 2" [innerHtml]="'GenericFilters.applied_multiple' | translate: {count:filtersApplied}"></span>
									<i matSuffix class="bx bx-chevron-down"></i>
								</button>
								<div ngbDropdownMenu orderable class="dropdown-menu-sm">
									<button ngbDropdownItem (click)="resetAllQueryWithAttribute(['severity','user_id'])"
										[disabled]="filtersApplied == 0"
										>{{ 'GenericFilters.reset' | translate }}
									</button>
		
									<h6>{{ __name + '.Filter.BySeverity._type' | translate }}</h6>
									<button ngbDropdownItem *ngFor="let t of ['info','debug']"
										[listFilter]="{ 'severity':t }" [listFilterComponent]="this"
									>{{ __name + '.Filter.BySeverity.' + t | translate }}</button>
									
									<h6>{{ __name + '.Filter.BySeverity._severity' | translate }}</h6>
									<button ngbDropdownItem *ngFor="let t of ['warning', 'error']"
										[listFilter]="{ 'severity':t }" [listFilterComponent]="this"
									>{{ __name + '.Filter.BySeverity.' + t | translate }}</button>

									<button ngbDropdownItem *ngVar="anyQueryApplied(['user_id']) as userFilter"
										[listFilter]="{ user_id:'yes' }" [listFilterComponent]="this" [listFilterToggleValues]="{user_id:['yes','no']}"
									>{{ __name + '.Filter.BySeverity.user_id'  | translate }}
										<span class="badge bg-success" *ngIf="userFilter?.['user_id'] == 'yes'">Yes</span>
										<span class="badge bg-danger" *ngIf="userFilter?.['user_id'] == 'no'">No</span>
									</button>

								</div>

							</span>
						</ng-container>


						<ng-container *ngVar="processorsInstalled() as processorsInstalled">

							<ng-container *ngIf="processorsInstalled.length > 0 && toolbar.includes('_all') || toolbar.includes('processor')">
								
								<span ngbDropdown class="btn-card-action" *ngVar="queriesApplied(['processor']).length as filtersApplied" autoClose="outside">
									<button class="btn btn-primary" ngbDropdownToggle *ngVar="'ByInterface' as filterName">
										<span *ngIf="filtersApplied == 0" [innerHtml]="__name + '.Filter.'+filterName+'.all' | translate: {count:totalRecords}"></span>
										<span *ngIf="filtersApplied == 1" [innerHtml]="'GenericFilters.applied_one' | translate: {count:filtersApplied}"></span>
										<span *ngIf="filtersApplied >= 2" [innerHtml]="'GenericFilters.applied_multiple' | translate: {count:filtersApplied}"></span>
										<i matSuffix class="bx bx-chevron-down"></i>
									</button>
									<div ngbDropdownMenu orderable class="dropdown-menu-sm">
										
										<button ngbDropdownItem *ngFor="let t of processorsInstalled"
											[listFilter]="{ processor:t }" [listFilterComponent]="this"
										>{{ 'Models.Integration.Values.processor.' + t | translate }}</button>
										
									</div>
									
								</span>
							</ng-container>
						</ng-container>

						<ng-container *ngIf="!this.loggable_type && (toolbar.includes('_all') || toolbar.includes('loggable_type'))">
								
							<span ngbDropdown class="btn-card-action" *ngVar="queriesApplied(['loggable_type']).length as filtersApplied" autoClose="outside">
								<button class="btn btn-primary" ngbDropdownToggle *ngVar="'ByLoggableType' as filterName">
									<span *ngIf="filtersApplied == 0" [innerHtml]="__name + '.Filter.'+filterName+'.all' | translate: {count:totalRecords}"></span>
									<span *ngIf="filtersApplied == 1" [innerHtml]="'GenericFilters.applied_one' | translate: {count:filtersApplied}"></span>
									<span *ngIf="filtersApplied >= 2" [innerHtml]="'GenericFilters.applied_multiple' | translate: {count:filtersApplied}"></span>
									<i matSuffix class="bx bx-chevron-down"></i>
								</button>
								<div ngbDropdownMenu class="dropdown-menu-sm">
									
									<button ngbDropdownItem *ngFor="let t of ['Customer','Product','Order','Warehouse','User','Club','Task']"
										[listFilter]="{ loggable_type: t }" [listFilterComponent]="this"
									>{{ __name + '.Filter.ByLoggableType.' + t | translate }}</button>
									
								</div>
								
							</span>
						</ng-container>

					</div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <table class="table table-striped table-hover sortable {{ loading.records }}">
                    <thead><tr>
                        <th></th>
								<th style="width: 130px;cursor:unset;" class="asc" sortable="created_at" (sort)="onSort($event)">
									<span *ngIf="applicationCol">{{ 'Models.IntegrationLog.Attributes.provider' | translate }}</span>
									<span *ngIf="!applicationCol">{{ 'Models.IntegrationLog.Attributes.created_at' | translate }}</span>
								</th>
                        <th [style.width]="'calc(100% - 130px)'">{{ 'Models.IntegrationLog.Attributes.message' | translate }}</th>
                        <th></th>
							</tr></thead>
                    <tbody>
                        <tr *ngFor="let log of records" [highlightable]="log">
                            <td>
										<div class="avatar-xs"><span class="avatar-title rounded-circle font-size-20 bg-soft" [ngClass]="{
											'bg-light' : log.severity == 'debug',
											'bg-primary' : log.severity == 'info',
											'bg-warning' : log.severity == 'warning',
											'bg-danger' : log.severity == 'error'
										}">
											<i class="bx" [ngClass]="{
                                    'bxs-chevron-right-circle' : log.severity == 'debug',
                                    'bxs-check-circle' : log.severity == 'info',
                                    'bxs-info-circle' : log.severity == 'warning',
                                    'bxs-x-circle' : log.severity == 'error',

                                    'text-light' : log.severity == 'debug',
                                    'text-primary' : log.severity == 'info',
                                    'text-warning' : log.severity == 'warning',
                                    'text-danger' : log.severity == 'error'
                                }"></i></span></div>
                            </td>
                            <td>
                                <h6 *ngIf="applicationCol">
                                    {{ 'Models.Integration.Values.provider.' + log.provider | translate }}
                                </h6>
										  	<p><a href (click)="!!toggleFromNow();" class="text-primary">
												<span *ngIf="!__fromNow">{{ moment(log.created_at).format(datetimeShort) }}</span>
												<span *ngIf="__fromNow">{{ moment(log.created_at).fromNow()  }}</span>
									  		</a></p>
                            </td>
                            <td>
										<ng-container *ngVar="log.user() as user">
											<p *ngIf="!log.operation.match(' ')"><strong>{{ __name + '.Processor.Operations.' + log.operation | translate }}</strong>
												<span class="d-block float-end" *ngIf="user && !user.isReadOnly(selectedCompany.id)">{{ user.toString(false) }}</span>
											</p>
											<p *ngIf="log.operation.match(' ')"><strong>{{ log.operation }}</strong>
												<span class="d-block float-end" *ngIf="user && !user.isReadOnly(selectedCompany.id)">{{ user.toString(false) }}</span>
											</p>
										</ng-container>
										<p [innerHTML]="log.message"></p>
										<code *ngIf="selectedRecords.includes(log.id)">{{ log.data }}</code>
                            </td>
                            <td style="width: 20px;" class="text-center">
                                <span ngbDropdown container="body">
											<button ngbDropdownToggle>
													<i class="bx bx-dots-vertical-rounded"></i>
											</button>
											<div ngbDropdownMenu>
													<button *ngIf="advancedCol" ngbDropdownItem (click)="layoutAction({createIntegration:true, integration:{provider:log.provider}})">
														<i class="bx bx-cog"></i>
														<span>Configure {{ 'Models.Integration.Values.provider.'+log.provider | translate }}</span>
													</button>
													<ng-container *ngIf="log.loggable_id != this.loggable_id">

														<button ngbDropdownItem *ngIf="advancedCol && log.loggable_type == 'Order'" [routerLink]="['/orders', log.loggable_id]">
															<i class="bx bx-dollar-circle"></i>
															<span>Go to Order</span>
														</button>

														<button ngbDropdownItem *ngIf="advancedCol && log.loggable_type == 'Customer'" [routerLink]="['/customers',log.loggable_id]">
															<i class="bx bx-user-circle"></i>
															<span>Go to Customer</span>
														</button>

														<button ngbDropdownItem *ngIf="advancedCol && log.loggable_type == 'Product'" [routerLink]="['/products',log.loggable_id]">
															<i class="bx bx-package"></i>
															<span>Go to Product</span>
														</button>

													</ng-container>
													<button *ngIf="log.data" ngbDropdownItem (click)="toggleRecordSelection(log, $event, 1)">
														<i class="bx bx-bug"></i>
														<span>Technical Details</span>
													</button>
											</div>
                                </span>
                            </td>
                        </tr>
                    </tbody>

                    <caption *ngIf="records?.length == 0" class="col-12 p-5">
                        <status-hint  
									icon="bx-tachometer"
                            class="text-secondary"
                            size="small"
                        >
								<p class="font-size-12" *ngIf="!loggable_id">No records were synchronized so far. Visit the <a closeBeforeNavigate routerLink="/apps/dashboard">Marketplace</a> to enable 3rd party systems and better access existing and new sales.</p>
                    		<p class="font-size-12" *ngIf="loggable_id">This record has not yet been synchronized. Visit the <a routerLink="/apps/dashboard">Marketplace</a> to enable 3rd party systems and better access existing and new sales.</p>
							</status-hint>
                    </caption>

						  <caption class="table-pagination" *ngIf="totalRecords > pageSize">
							<p>{{ recordRange }} of {{ totalRecords }} items</p>
							<ul class="pagination justify-content-center">
                        <ngb-pagination [collectionSize]="totalRecords" [pageSize]="pageSize" [(page)]="currentPage"
                            [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"
                            (pageChange)="gotoPage($event)" class="pg-item"
                            [disabled]="!isLoaded('records')"></ngb-pagination>
                        </ul>
                        <p>
                            <select class="custom-select" [value]="pageSize" (change)="changePageSize($event)">
                                <option value="10">10 per page</option>
                                <option value="25">25 per page</option>
                                <option value="50">50 per page</option>
                                <option value="100">100 per page</option>
                            </select>
                        </p>
                    </caption>

                </table>
        
            </div>
        </div>
        
    </div>
</div>