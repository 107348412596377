import { Injectable } from '@angular/core';

import { Order } from '../../models/troly/order.model';
import { ITrolyService, TrolyService } from './troly.service';

import { Observable, of } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { TrolySearch } from '../../models/form_objects';
import { Company } from '../../models/troly/company.model';
import { Document } from '../../models/troly/document.model';
import { Payment } from '../../models/troly/payment.model';
import { Shipment } from '../../models/troly/shipment.model';
import { SalesStat } from '../../models/troly/stats.model';
import { OrderTag, Tag } from '../../models/troly/tag.model';
import { TrolyObject } from '../../models/troly_object';
import { uuid } from '../../models/utils.models';
import { ITaggableService, TaggableModule } from './modules/taggable.module';

@Injectable({
	providedIn: 'root',
})
/*
	 This class is in charge of all loading and unloading of a order's profile.
	 There can only ever be a single profile loaded for a given app instance.

	 i.e. if you load an order, it will load and set the current order to the order in question
*/
export class OrderService extends TrolyService<Order> implements ITaggableService<OrderTag>, ITrolyService<Order> {

	/**
	 * The name or identifier of the current class, not otherwise available when running in "production mode". 
	 * It is used to output debugging information on the console, and also attached to translations of labels, product tours, etc
	 */
	public readonly __name:string = 'OrderService';

	/**
	 * And provides additional (reused) functions for dealing with tags within the context of a Customer
	 */
	public taggableModule: TaggableModule = new TaggableModule(this);

	constructor() { super('orders'); }

	/**
	 * Is responsible for updating a tag attached to this a Order record.
	 * @param verb 
	 * @param id 
	 * @param obj 
	 * @param record 
	 * @returns 
	 */
	public updateTag(verb, id:uuid, obj?:OrderTag, record?:Order): Observable<OrderTag> {
		// this uses a call (instead of create/save/delete) to more easily handle every operations in one call. Also must handles a raw api HttpResponse 
		return this.call<OrderTag>(verb, obj, `${record?.id || this.record$.value.id}/tags/${id}`).pipe(filter(_ => !!_), map(_ => new OrderTag(_.body['order_tag'])))
	}
	
	public searchTags(params?: {}, record?: Order): Observable<TrolySearch<Tag>> {

		let obj = this.make({id: params['order_id'] || record?.id })
		delete params['order_id']

		return this.call<TrolySearch<Tag>>('get', obj, 'tags/search', params).pipe(map(_ => {
			let search = new TrolySearch<Tag>(_.body, 'tags');
			search.results = search.results.map(_ => new Tag(_));
			return search;
		 }))
	}

	public makeObjectTag(payload: {}): OrderTag { return payload instanceof OrderTag ? payload : new OrderTag(payload); }

	public make(payload: {} = {}): Order { return payload instanceof Order ? payload : new Order(payload); }

	/**
	 * 
	 * @param payload 
	 * @returns 
	 */
	public createShipment(payload?:Order, params?: {}): Observable<Order> {

		payload ||= new Order();
		payload['id'] ||= this.record$.value.id;

		payload.shipment ||= {} as Shipment
		payload.shipment._destroy = false;

		params ||= {}
		params['with_shipment'] = true

		return this.put(payload, null, params).pipe(
			map(_ => this.make(_.body[this.singular_node])),
			tap((_) => {
				this._next(_); // just in case we are editing the record currently being viewed
				this.updateInCache(_);
			}));
	}

	/**
	 * 
	 * @param payload 
	 * @returns 
	 */
	public deleteShipment(payload?:Order, params?: {}): Observable<Order> {

		payload ||= new Order();
		// ! we normally allow service methods to override the ID of the current record being edited;
		// ! here we force it, to ensure data integrity (the right shipment from the write order..)
		payload['id'] = this.record$.value.id;
		payload.shipment ||= {} as Shipment
		payload.shipment.id = this.record$.value.shipment.id;
		payload.shipment._destroy = true;

		return this.put(payload, null, params).pipe(
			map(_ => this.make(_.body[this.singular_node])),
			tap((_) => {
				this._next(_,'shipment'); // forcing the shipment attriute to be flushed
				this.updateInCache(_);
			}));
	}

	/**
	 * 
	 * @param service 
	 * @returns 
	 */
	public loadPayments(params?: {}, record?: Order): Observable<Payment[]> {

		params ||= {}
		record ||= this.record$.value

		let obj = this.make({id: params['order_id'] || record.id })
		delete params['order_id']

		const force = record && record.isStale('payments')

		if (!force && record.payments) { return of(record.payments) }
	
		return this.getList<Payment>(obj, 'payments', params, force).pipe(filter(_ => !!_), 
			map(list => list.map(o => new Payment(o))),
			tap(list => this._next_attr(obj.id, 'payments', list))
		)
	}

	/**
	 * Retrieves the documents associated to the current order OR the order_id specified in params
	 * @param service 
	 * @returns 
	 */
	public loadDocuments(params?: {}, record?: Order): Observable<Document[]> {

		params ||= {}
		record ||= this.record$.value

		let obj = this.make({id: params['order_id'] || record.id })
		delete params['order_id']

		const force = record && record.isStale('documents')

		if (!force && record.documents) { return of(record.documents) }
	
		return this.getList<Document>(obj, 'documents', params, force).pipe(filter(_ => !!_), 
			map(list => list.map(o => new Document(o))),
			tap(list => this._next_attr(obj.id, 'documents', list))
		)
	}

	/**
	 * 
	 * @param service 
	 * @returns 
	 */
	public loadOrderTags(params?: {}, record?: Order): Observable<OrderTag[]> {

		params ||= {}
		record ||= this.record$.value

		let obj = this.make({id: params['order_id'] || record.id })
		delete params['order_id']

		const force = record && record.isStale('order_tags')

		if (!force && record.order_tags) { return of(record.order_tags) }
	
		return this.getList<OrderTag>(obj, 'tags', params, force, 'order_tags').pipe(filter(_ => !!_), 
			map(list => list.map(o => new OrderTag(o))),
			tap(list => this._next_attr(obj.id, 'order_tags', list))
		)
	}

	/**
	 * 
	 * @param params 
	 * @param force 
	 * @returns 
	 */
	public loadSalesStat(params?: {}, record?: Company): Observable<SalesStat> {

		params ||= {}
		//record ||= this.record$.value
		
		params['limit'] = params['limit'] || 1
		params['range'] = params['range'] || 'alltime'

		//let obj = this.make({id: params['order_id'] || record?.id })  // in order to load different warehouses or products, or else, we must explicitly set in params
		let obj = new SalesStat();//{id: params['order_id'] || record?.id })  // we never pull SALES stats for a specific order (404 -- doesn't exist)
		delete params['company_id']

		const force = record && record.isStale('orders_stat')

		return this.getSingleObject<SalesStat>(obj, params, 'stat', force, 'stock_stat').pipe(filter(_ => !!_), map(o => new SalesStat(o)))
	}

	public loadSalesStats(params?: {}, record?: Company): Observable<SalesStat[]> {

		params ||= {}
		//record ||= this.record$.value
		
		params['limit'] = params['limit'] || 18
		params['range'] = params['range'] || 'month'

		//let obj = this.make({id: params['order_id'] || record?.id })  // in order to load different warehouses or products, or else, we must explicitly set in params
		delete params['company_id']

		const force = record && record.isStale('orders_stats')

		return this.getSingleObject<SalesStat[]>(new TrolyObject(), params, 'stats', force, 'sales_stats').pipe(filter(_ => !!_)).pipe(map(list => list.map(o => new SalesStat(o)) ))
		//return this.getList<SalesStat>(new TrolyObject(), 'stats', params, force, 'sales_stats').pipe(filter(_ => !!_), map(list => list.map(o => new SalesStat(o))))
	}

}
