import { AbstractControl, FormArray, FormGroup, ValidatorFn, ɵFormGroupValue } from "@angular/forms";
import { MatSelect } from "@angular/material/select";
import * as moment from 'moment';
import { BehaviorSubject } from "rxjs";
import { onlyUnique } from "../components/base.component";
import { FormObject, ITrolyFormField } from "./form_objects";
import { ApiObjectError, TrolyObject } from "./troly_object";

/**
 * A customised `FormGroup` adding or improving ootb NG functionality.
 * 
 * loading: provides a status indicator of the stage the form is at
 * code: stores an overall form status code, indicating successes and errors, and generally handled via the `FormStatusComponent`
 * 
 */
export class TrolyFormGroup extends FormGroup {

	/**
	 * Contains a success cod for ou :)
	 */
	public successCode?: string = null;

	/**
	 * 
	 */
	public errorCode?: string = null;
	public errorDetails?: string[] = [];

	/**
	 * 
	 */
	public infoCode?: string = null;

	/**
	 * 
	 */
	public code?: string = null;
	public codeChanges$ = new BehaviorSubject<string>(null);


	/**
	 * 
	 */
	public _dirty = {}
	public _assigned = {}

	/**
	 * 
	 */
	public loading: 'loading' | 'loaded' | 'loading-error' | 'loaded-demo' = 'loading';

	/**
	 * 
	 * @param newCode 
	 * @param errorDetails 
	 */
	public resetCodes(newCode?: { info?: string, success?: string, error?: string }, errorDetails?): void {

		this.code = this.infoCode = this.errorCode = this.successCode = null;
		
		//this.updateValueAndValidity(); // not sure why this is here?

		if (newCode) {
			if (newCode.info) {
				this.infoCode = newCode.info
			}
			if (newCode.success) {
				this.successCode = newCode.success; this.markAsPristine();
				this.loading = 'loaded';
			}
			if (newCode.error) { this.errorCode = newCode.error }

			this.code = newCode.error || newCode.success || newCode.info;
			this.codeChanges$.next(this.code);
		}

		if (errorDetails) { this.errorDetails = errorDetails };

		this.hideFormStatusMessage = false;
	}

	/**
	 * Enables / Disables the auto-saving and loading indicators on the form. Generally, a form with no save button should have this enabled, and a form WITH a save button / submit function should have this disabled
	 */
	public liveLoading: boolean = true;
	public get cssClass(): string {
		return (this.liveLoading ? this.loading : 'no-live-loading') + (this.successCode && !this.hideFormStatusMessage ? ' success' : '');
	}

	public get cannotSubmit(): boolean {
		return this.loading != 'loaded' || this.invalid || this.pristine
	}
	/**
	 * 
	 */
	public seconds: number = -1;
	protected _countdown: any;
	public _timer: any = null;

	public countdown(seconds?: number): number {
		this.seconds = seconds || 2;
		this._countdown = setInterval(() => {
			this.seconds = this.seconds - 1;
		}, 1000);
		return this.seconds * 1000; // showing 3-2-1-0, but we're acting on '1', not '0'
	}

	/**
	 * Cancels the default _countdown and _timer
	 * @returns 
	 */
	public clearClock(): boolean {
		if (this._countdown) {
			clearInterval(this._countdown);
			this._countdown = null;
		}

		if (this._timer) {
			clearTimeout(this._timer);
			this._timer = null;
		}

		this.seconds = 0;

		return this._countdown == null && this._timer == null;
	}

	/**
	 * Dynamically checks whether a field is required and circumvent the console warning regarding status changed after the form as loaded
	 * @param field Name of the field to enquire about
	 * @returns Whether or not this field is currently required for this form.
	 */
	public isFieldRequired(field: string): boolean {
		let f: AbstractControl = this.get(field);
		if (!f || !f.validator) {
			return false;
		}

		const validator = f.validator({} as AbstractControl);
		return (validator && validator.required);
	}

	public savingStatuses: {[key:string]:'SAVING'|'SAVED'|'INITIALISING'|'ERROR'} = {};
	public savingStatus(attr:string): 'SAVING'|'SAVED'|'INITIALISING'|'ERROR' { return this.savingStatuses[attr] }

	/**
	 * Generic helper function to set a saving status (or many) to a specific value.
	 * @param payload 
	 * @param status 
	 * @param prefix 
	 */
	protected setSavingStatuses(payload: FormObject | string, status:'SAVING'|'SAVED'|'INITIALISING'|'ERROR', prefix?: string) {
		prefix = prefix || '';
		
		if (typeof payload != 'string') {
			Object.keys(payload).filter(_ => _[0] != '_' && _ != 'id').forEach((attr) => {
				if (payload[attr] instanceof Array && this.get(attr) instanceof FormArray) {
					payload[attr].forEach((rec,i) => { 
						// ↳ it's possible for the payload to have an array, which is NOT associated to a FormArray
						//   eg. Customer.notify_payments are an [] of strings, stored as such
						this.array(attr).controls.forEach((subGroup, i) => {
							if (subGroup.get('id').value == rec['id']) {
								this.setSavingStatuses(rec, status, `${attr}.${rec['id']}.`) // because saving Statuses are ID-based, this will mark as SAVED correctly, but not the form element which is index-based
								this.setSavingStatuses(rec, status, `${attr}.${i}.`)			 // this will mark the form element as pristine, but not the savingStatus
							}
						});
					});
				} else {
					this.setSavingStatuses(attr, status, prefix)
				}
			});
		} else if (payload.indexOf('.') > 0) {
			const words = payload.split('.');
			const firstWord = words[0];
			const restOfString = words.slice(1).join('').trim();
			this.setSavingStatuses(restOfString, status, `${firstWord}.`)
		} else {
			this.savingStatuses[`${prefix}${payload}`] = status;
			if (this.get(`${prefix}${payload}`)) { 
				if (status == 'SAVED' || status == 'INITIALISING') { this.get(`${prefix}${payload}`).markAsPristine(); }
			}
		}
	}
	/**
	 * Takes whatever payload is being saved and marks it as 'SAVING' in the `savingStatuses` object, for UI references purposes
	 * @param payload 
	 */
	public startSaving(payload: FormObject | string) {
		this.setSavingStatuses(payload,'SAVING')
	}
	public savingError(payload: FormObject | string) {
		this.setSavingStatuses(payload,'ERROR')
	}
	public doneSaving(payload: FormObject | string) {
		this.setSavingStatuses(payload,'SAVED')
	}
	public isSaved(attr?:string): boolean {
		if (attr) {
			return this.savingStatuses[attr] && this.savingStatuses[attr] == 'SAVED';
		}
		return Object.keys(this.savingStatuses).filter(_ => this.savingStatuses[_] == 'SAVING').length == 0
	}
	public isSaving(attr?:string): boolean {
		if (attr) {
			return this.savingStatuses[attr] && this.savingStatuses[attr] == 'SAVING';
		}
		return Object.keys(this.savingStatuses).filter(_ => this.savingStatuses[_] == 'SAVING').length > 0
	}

	/**
	 * 
	 * @param value 
	 * @param options 
	 */
	override patchValue(value: ɵFormGroupValue<any>, options?: {
		onlySelf?: boolean;
		emitEvent?: boolean;
	}): void {
		
		super.patchValue(value, options)

		// !Note: This is required because our form builder (see `FormObject.toFormGroup`) will not create the required FormGroup(s) within the FormArray IF there are no values. As a result when patching the form, no group details (attributes) is present in the form as template to patch the array
		if (value._trolyPropertyArray) {
			// loop through all properties of type Array for which we have received data to patch
			Object.keys(value._trolyPropertyArray).filter(_ => value._trolyPropertyArray[_] === Array && value[_] !== undefined).forEach((property) => {
				let fields = this.formFields.find(_ => typeof _ == 'object' && _[property]);
				if (fields) {
					let formArray = (this.get(property) as FormArray)

					value[property].forEach((element, i) => {
						// We should not need to cast element into a TrolyObject.
						// The constructor is creating proper objects out of the array, yet for some reason
						// element -- here -- has the `_trolyModelName`, but doesn't respond to TrolyObject functions
						// it seems like the 'pseudo class system' loses the typing in the Observers 'next' calls.
						let patchableObject = new TrolyObject(element._trolyModelName, element);
						// !! tiny bit of a hack here: we are trying to use the TrolyObject infrastructure to create/patch new objects which are NOT TrolyObjects.. 
						//	 .. so we pass-on the parent's _trolyPropertyArray to the child, so that at least, we can declare Array properties somewhere 'clean' (ie in the Model) -- ShippingRule._trolyPropertyArray
						patchableObject._trolyPropertyArray = value._trolyPropertyArray;
						//Object.assign(patchableObject, element)
						
						// we also could rely solely on the array index -- here `i` -- but looking up a certain attribute is more flexible -- as long as attributes values stick!
						let index = formArray.controls.map(_ => _.get('id') && _.get('id').value).indexOf(element['id']);
						if (index >= 0) {
							formArray.at(index).patchValue(patchableObject.patchableValues());
						} else { 
							formArray.push(patchableObject.toFormGroup(fields[property]));
						}
					});

					if (this.patchArrayClearsUnknown) {
						const knownRecords = value[property].map(_ => _.id);
						// This leverage is the fact that all records are being received so any known records have been pushed or updated
						// Whereas unknown records are left in the formArray, so we remove them.
						while (formArray.length > knownRecords.length) { formArray.removeAt(formArray.length-1) }
					}
				}
			})
		}
	}


	/**
	 * Marks the specified form controls as dirty and touched, and updates their attributes/values accordingly, and validity.
	 * 
	 * @param values - An object containing the key/values to patch.
	 * @param notify - Optional. Specifies whether to emit events when updating the form.
	 * @returns A boolean indicating whether any form controls were patched.
	 */
	public patchDirty(values: {}, notify: boolean = true): void {
		
		
		let deep_changes: boolean = false;
		for (let key in values) {
			const k = this.get(key);
			if (k) {
				if (key.match(/\./)) { 
					// for some reason, when deep patching (eg. 'company_customers.0.track_staff_id'), the natime angular patch doesn't set the value?
					deep_changes = true;
				}
				k.markAsTouched({ onlySelf: !notify, emitEvent: notify });
				k.markAsDirty({ onlySelf: !notify, emitEvent: notify });
				k.setValue(values[key]); 
			} else {
				console.warn(`Patching form failed, ${key} is missing`)
			}
		}
		
		if (deep_changes) { 
			this.updateValueAndValidity({ emitEvent: notify }) 
		}
	}

	/**
	 * Updates the value of the input to an empty string and patch provided changes to the form.
	 * 
	 * @param input - The MatSelect input element.
	 * @param change - The changes to be applied.
	 */
	public patchAndClear(input: MatSelect, change: {}) {
		input.value = ''
		this.patchDirty(change)
	}
	
	/**
	 * Updates the value of a checkbox form control and patches the changes to the parent form if the control is enabled.
	 * 
	 * @param key - The key of the checkbox form control.
	 * @param status - The new status of the checkbox form control.
	 * @param value - The value to be added or removed from the checkbox form control's value array.
	 */
	public patchDirtyCheckbox(key: string, status: boolean, value?:string): void {

		if (this.get(key).enabled) {
			let patch = {};
			if (!value) {
				patch[key] = status
			} else {
				let values = this.get(key).value || []
				if (status && !values.includes(value)) {
					values.push(value)
					patch[key] = values
					
				} else if (!status && values.includes(value)) {
					patch[key] = values.filter((_) => _ != value)
					values = values.filter((_) => _ != value)
					//form.get(key).setValue(values.filter((_) => _ != value))
					// it *seems* that when patching an array (as value) onto an angular form, the values
					// are only added and anything "pacthed" as "not there anymore" is ignored
				}
				this.get(key).setValue(values)
			}
			this.patchDirty(patch);
		}
	}

	/**
	 * Controls whether an array attribute being patched onto the form will see previous values cleared or not. By default
	 * we assume that records are updated (and returned) individually (eg PUT /companies/:id/products/:id) returns a single product, not all products in the comapny/table)
	 * but in some cases (eg subproducts as bundle_content in a product), we want to clear the previous values because we are receiving back all 'sub' records
	 * @param value 
	 * @param options 
	 */
	public patchArrayClearsUnknown:boolean = false;

	public array(arrayName:string): FormArray {
		return this.get(arrayName) as FormArray;
	}
	public group(attr:string): FormGroup {
		return this.get(attr) as FormGroup;
	}
	public isGroup(attr:string): boolean {
		return this.get(attr) instanceof FormGroup
	}
	public isArray(attr:string): boolean {
		return this.get(attr) instanceof FormArray
	}
	public subArray(arrayName1:string, i:number, arrayName2:string): FormArray {
		return (this.get(arrayName1) as FormArray).at(i).get(arrayName2) as FormArray;
	}


	/**
	 * CSS class name to apply when the a form status should be hidden method is called. @see FormStatusComponent, @see TrolyFormGroup.hideStatusIn()
	 */
	hideFormStatusMessage: boolean = false;

	public errorDetailsPush(result, newStatus:string='RESULTS') {
		if (newStatus) { this.resetCodes({success:newStatus}) }
		this.errorDetails.unshift(result)
	}
	/**
	 * Checks whether the current object for API received errors.
	 * @param result 
	 * @returns 
	 */
	public assignValidationErrors(result: TrolyObject | { errors: ApiObjectError }): boolean {

		if (result && result.errors && Object.keys(result.errors).length > 0) {

			this.enable();
			
			// we assume that all fields are on this form, able to show their own errors;
			let unhandledErrors = false
			let formErrorsAdded = false;

			let errorDetails = [];
			Object.keys(result.errors).forEach((k) => {
				let _errors = { server: true };
				result.errors[k].forEach(msg => {
					_errors[msg] = true;
					errorDetails.push(`${k}: ${msg}`);
				});

				if (this.controls[k]) {
					// the field that caused an error may not be present in the form.
					// also - setErrors doesn't work due to lifecycle validation clearing this 
					// -- https://github.com/angular/angular/issues/48515
					this.get(k).setErrors({
						'incorrect': true,
						'server': result.errors[k]
					});
					
					formErrorsAdded = true;
				} else {
					unhandledErrors = true;
				}
			});

			if (unhandledErrors && errorDetails.length > 0) {
				this.resetCodes({ error: 'SERVER' });
				this.errorDetails = errorDetails;
				return true;
			}

			// Errors returned by the server should NOT be considered as a form validation error -- but as a field error. 
			// The frontend SHOULD HAVE handled/validated the value beforehand, so here, we're not invalidating the whole form.
			return formErrorsAdded // return true if any controls were set as having errors.
		}

		return false;
	}

	/**
	 * Extract the dirty attributes from a form
	 * @param form FormGroup The form to read for changes 
	 * @param controls: an array of attributes to process 
	 * @param rootkey: use processing nested objects
	 * @returns a new TrolyObject of the same modelname containing only changed attributes, or null
	 */
	public getChanges(record?: TrolyObject, controls?: { [key: string]: AbstractControl<any, any>; }, rootkey?: string): null | FormObject {

		let changedProperties = null;

		if (!this.errors) {

			controls = controls || this.controls;

			if (rootkey && !rootkey.endsWith('.')) { rootkey = `${rootkey}.`; }
			else { rootkey ||= '' } 
 
			// let creatingObj: boolean = !rootkey && (!controls['id'] || !controls['id'].value) 
			// ?? Not sure why all attributes of children would considered as new irrespective of whether they have changed or not -- if see https://github.com/troly/troly-app/blame/develop/src/app/core/models/troly_form.ts#L353 

			// we are in a creating mode if we don't an ID or have one without value, and no within a nested object
			let creatingObj: boolean = (!controls['id'] || !controls['id'].value)

			Object.keys(controls).filter(_ => !this.isSaving(rootkey + _)).forEach((name) => {

				if (name == 'id') { return; }

				// as per https://angular.io/api/forms/AbstractControl#status
				// control.valid is false when the form is disabled 
				// so we cannot disable a form before having extracted the changes!
				//if (!controls[name].invalid && (creatingObj || controls[name].dirty || controls['controls'])) {
				
				// ?? likewise -- if a form control was correctly built, only dirty attributes need to be saved.
				// ??? if we need override and save ALL attributes in ALL cases, use `model.beforeSave` (see  how product.model.ts implements this)
				if (creatingObj || controls[name].dirty) {
				//if (creatingObj || rootkey || controls[name].dirty) {

					if (controls[name]['controls']) {

						if (controls[name] instanceof FormArray) {
							
							// if an attribute is dirty, and there are no elements in the array, we still want to push an empty array (eg, all elements were removed)
							changedProperties = changedProperties || {};
							changedProperties[name] = changedProperties[name] || [];

							(controls[name].value as Array<any>).forEach((rec, i) => {
								if (rec['controls']) {
									let changes:FormObject = this.getChanges(rec, controls[name]['controls'].at(i)['controls'], `${name}` + (rec['id'] ? `.${rec['id']}` :`.${i}`));
									if (changes) {
										changedProperties[name].push(changes);
									}
								} else {
									// here we are patching an array of 'plain' values (not an array of objects)
									// so we just assign the same/full value directly
									changedProperties[name] = controls[name].value
								}
								});

							if (changedProperties[name].length == 0) { delete changedProperties[name] }
							
						} else {
							let changes:FormObject = this.getChanges(null, controls[name]['controls'], rootkey + name);
							if (changes) {
								changedProperties = changedProperties || {}
								changedProperties[name] = changes
							}
						}

					} else {

						changedProperties = changedProperties || {}

						if (moment.isMoment(controls[name].value)) {
							// The MomentDateAdapter changes the value to be a Moment object
							changedProperties[name] = controls[name].value.format(name.endsWith('_date') ? "YYYY-MM-DD" : "YYYY-MM-DD HH:mm:ss");
							// Here we are removing the time zone to store the date alone, make sure the date value is assumed to be timeless
						} else {
							changedProperties[name] = controls[name].value;
						}
					}
				}
			}, this);

			// if present, ensure we have an ID to save any changes made to the form
			if (changedProperties && controls['id'] && controls['id'].value?.toString() != '') { changedProperties['id'] = controls['id'].value; }
		}

		return changedProperties && Object.keys(changedProperties).length > 0 ? changedProperties : null;
	}

	// list of fields configured for the form, used to keep a copy of the fields configuration in order to patchArray when the controls are 'empty' eg. == [] so we can reflect and apply correct formgroup
	public formFields: ITrolyFormField[] = [];

	/**
	 * 
	 * @param attribute_name 
	 * @returns 
	 */
	public getControls(attribute_name: string): AbstractControl[] {
		let fa = this.get(attribute_name) as FormArray
		return fa && fa.controls;
	}

	public getControl(attribute_name: string): FormGroup {
		return this.get(attribute_name) as FormGroup
	}

	public isBlankValue(attr: string): boolean {
		return this.get(attr).value == null || this.get(attr).value == undefined || this.get(attr).value == '';
	}

	/**
	 * 
	 */
	public customValidators = {

		valueInList(list: any[]): ValidatorFn {
			return (control: AbstractControl): { [key: string]: any } => {
				return list.find(_ => _.toString() == (control.value || '').toString()) ? null : { 'valueInList': false };
			}
		},

		greaterThan(field: string): ValidatorFn {
			return (control: AbstractControl): {[key: string]: any} => {
				const group = control.parent;
				const fieldToCompare = group.get(field);
				if (control.value == null || control.value == "" || fieldToCompare.value == null || fieldToCompare.value == "") {
					// if either of the fields is not set, then another validator should handle this
					return null;
				}

				const errCheck = Number(fieldToCompare.value) > Number(control.value);
				return errCheck ? {'greaterThan': {greaterThan: fieldToCompare.value, actual: control.value }} : null;
			}
		},


		lessThanOrEqualTo(field: string): ValidatorFn {
			return (control: AbstractControl): {[key: string]: any} => {
				const group = control.parent;
				const fieldToCompare = group.get(field);
				if (control.value == null || control.value == "" || fieldToCompare.value == null || fieldToCompare.value == "") {
					// if either of the fields is not set, then another validator should handle this
					return null;
				}

				const errCheck = Number(fieldToCompare.value) <= Number(control.value);
				return errCheck ? {'lessThanOrEqualTo': {lessThanOrEqualTo: fieldToCompare.value, actual: control.value }} : null;
			}
		}

	}


	/**
	 * Toggles the status of one or multiple fields in this form.
	 * 
	 * @param fields - The field or fields to toggle the status of.
	 * @param forceEnabled - Optional. If set to `true`, forces the fields to be enabled. If set to `false`, forces the fields to be disabled. If not provided, toggles the status of the fields.
	 * @returns void
	 */
	public toggleFieldStatus(fields:string|string[], forceEnabled?:boolean): void {
		if (typeof fields == 'string') { fields = [fields] }
		fields.filter(onlyUnique).forEach((field) => {
			const f = this.get(field);
			if (forceEnabled == null) {
				if (f.enabled) { f.disable() }
				else { f.enable() }
			} else if (forceEnabled) { 
				f.enable() 
			} else { 
				f.disable() 
			}
		})
	}
}