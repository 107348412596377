import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, SimpleChanges, inject } from '@angular/core';
import { Validators } from '@angular/forms';
import { filter } from 'rxjs';

import { TrolyModal } from 'src/app/core/components/troly.modal';
import { Company } from 'src/app/core/models/troly/company.model';
import { Task } from 'src/app/core/models/troly/task.model';
import { TaskService } from 'src/app/core/services/troly/task.service';
import { TrolyValidationError } from 'src/app/core/services/troly/troly.interceptor';
import { TrolyAngularModule } from 'src/app/shared/angular.module';
import { TrolyMaterialModule } from 'src/app/shared/material.module';
import { TrolySharedModule } from 'src/app/shared/shared.module';

@Component({
	selector: 'create-task-modal',
	templateUrl: './create-task.modal.html',
	styleUrls: ['./create-task.modal.scss'],
	standalone: true,
	imports: [TrolyAngularModule, TrolyMaterialModule, TrolySharedModule]
})
export class CreateTaskModal extends TrolyModal<Task> {

	/**
	 * The name or identifier of the current class, not otherwise available when running in "production mode".
	 * @see TrolyComponent.__name (Override)
	 */
	override readonly __name:string = 'CreateTaskModal';

	/**
	 * Task being created or edited by this component (and also afiliated with the component's form)
	 */
	@Input() record?: Task = new Task()

	/**
	 * Record **fields** being managed by the form. These will ALL be posted on create, and only changes will be PUT'ed on update.
	 */
	_formFields = ['id', 'name', 'latest_progress_notes', 'taskable_id', 'taskable_type', 'details', 'taskable_id', 'created_by_id', 'due_date', 'priority', 'assigned_to_id', 'assigned_by_id', 'progress_value', 'status'];
	
	
	/**
	 * Default values for the main component' form.
	 * Note: TaskableType is required -- only the API can create (internally) tasks without a taskable_type and these are known as "opportunities".
	 */
	_formDefaults: Object = { taskable_type: '', priority:'med', progress_value:0, status:'pending' }

	/**
	 * Additional services used this this component. 
	 * ? Keeping in mind CompanyService and UserService are already available in TrolyComponent. 
	 */
	protected taskService: TaskService = inject(TaskService);

	/**
	 * This is the taskable object "string" representation sent to the <search-component> and avoiding it to search on editing a task.
	 */
	taskable_name: string = '';

	constructor() {
		super();

		// setting the default service for forms + obtaining/saving data in this component.
		this.service = this.taskService;

		this._formDefaults['created_by_id'] = this.authenticatedUser.id;
		this._formDefaults['taskable_id'] = null;
		this.initForm()
	}

	protected receiveInputParams(changes: SimpleChanges): void {
		
		if (changes.record?.currentValue) {
			if (this.record.taskable) {
				this.taskable_name = this.record.taskable.toString();
			}
		}

		super.receiveInputParams(changes);

		if (this.nowEditingRecord) {
			this.form.get('taskable_type').disable();
			this.form.get('taskable_id').disable();

			if (this.form.get('created_by_id').value != this.authenticatedUser.id) {
				this.form.get('due_date').disable();
				this.form.get('assigned_to_id').disable();
			}
		}
	}

	protected loadData(): void {
		super.loadData()

		this.subscribeDistinct<Company>(this.companyService).subscribe((_) => {
			this.initRecordAttributeOnce('users', this.companyService.loadUsers(), this.selectedCompany); // safe within subscribeRecord
		})
	}

	/// subscribe change notifiers and react to changes
	protected attachChangeNotifiers(): void {

		this.form.get('taskable_type').valueChanges.pipe(filter(_ => !this.nowEditingRecord)).subscribe((_) => {

			// When the type of task to create changes (only on creation, not possible on edit)
			// add/remove valudations+default values
		
			this.taskable_name = '';
			this.form.get('taskable_id').setValue(null);

			if (['Order', 'Customer', 'Product'].includes(_)) {
				// based on the type of task "class" we set validation to either
				// be a human-entered name, or the dropdown searched value
				this.form.get('name').setValidators(null);
				this.form.get('name').setErrors(null);
				this.form.get('taskable_id').setValidators([Validators.required]);
				this.form.patchValue({ taskable_id: this.record.taskable_id });
			} else {
				this.form.get('taskable_id').setValidators(null);
				this.form.get('taskable_id').setErrors(null);
				this.form.get('name').setValidators(this.record._name); // default validation for the field
			}
			this.form.updateValueAndValidity();
		})

		this.form.get('assigned_to_id').valueChanges.subscribe((_) => {
			// record or forget who has assigned this task to a user.
			this.form.get('assigned_by_id').setValue(this.authenticatedUser.id);
		})

	}

	onSubmit(): void {

		this.markAsLoading(this.form);
		this.form.disable(this.NO_EMIT); // ensure any subscribers (eg. valueChanges) are not called

		let changes = this.form.getChanges();

		if (!changes) {
			this.enableFormNoChanges()
		} else {

			this.service.saveOrCreate(new Task(changes),{with_taskable:true}).subscribe({
				next: (_record: Task) => {

					this.form.patchValue({ 'id': _record.id });

					this.form.resetCodes({ success: (this.record.id ? 'SAVED' : 'CREATED') });

					this.service.updateRecordsCounter('tasks-count-outstanding', { to: (new Date()).toLocaleDateString(), status: 'pending' });

					this.record = _record;

					this.form._timer = setTimeout(() => {
						this.resolveModal('timeout');
					}, this.form.countdown());

				},
				error: (err: HttpErrorResponse) => {

					if (err instanceof TrolyValidationError) {
						this.form.assignValidationErrors(err.error);
						this.markAsLoaded(this.form);
					} else {
						this.unknownApiError('3215-tomatoes-violet-CONDUCTOR')
					}
				}
			});
		}
	}

	/**
	 * Search Component has returned a record (based on taskable_type selected)
	 * @param $event 
	 */
	onSearchSelect($event): void {
		this.taskable_name = $event.toString();
		this.form.patchDirty({ taskable_id: $event.id });
	}

	/**
	 * 
	 * @param value 
	 */
	togglePriority(value:'low'|'med'|'high') {
		const current = this.form.get('priority').value	
		if (current != value) {
			this.form.patchDirty({priority:value})
		} else {
			if (value == 'low' || value == 'high') {
				// 'unset' the priority by setting it back to (not user-selectable displayed) 'medium' 
				this.form.patchDirty({priority:'med'})
			}
		}
	}
}
