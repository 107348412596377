import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { buildCorrectIntegrationObject, Integration } from '../../models/troly/integration.model';
import { SystemStat } from '../../models/troly/stats.model';
import { uuid } from '../../models/utils.models';
import { ITrolyService, TrolyService } from './troly.service';

@Injectable({
	providedIn: 'root',
})
export class IntegrationService extends TrolyService<Integration> implements ITrolyService<Integration> {

	/**
	 * The name or identifier of the current class, not otherwise available when running in "production mode". 
	 * It is used to output debugging information on the console, and also attached to translations of labels, product tours, etc
	 */
	public readonly __name:string = 'IntegrationService';

	public currentLang: string;
	public currentCompanyId: uuid;

	constructor() { super('integrations'); }

	/**
	 * Retrieves the object requested we are looking for, without notifying subscribers (As opposed to `_find()`)
	 * @param id `uuid` for the desired api object
	 * @param filter any retrieval options as required -- see `CompaniesController.rb`
	 * @returns `Observable<T>` 
	 */
	find(id: uuid|string, params?: {}, method?:string): Observable<Integration> {
		// this `as uuid` is a typescript cheat -- the normal find method takes an id:uuid parameter.
		// however this /integrations/ endpoint supports receiving a provider name as id (eg. `/integrations/Mailchimp.json`) to retrieve an integration available
		// in the end a uuid is a string, so nothing would break, however in pure 'object approach' this would be illegal (and may be picked up in the future by the compiler)
		return super.find(id as uuid, params, method)
	}

	public make(payload: {} = {}): Integration { 
		
		return payload instanceof Integration ? payload : buildCorrectIntegrationObject(payload, this.currentLang); 
		
	}

	public loadSystemStats(params?: {}, record?: Integration): Observable<SystemStat[]> {

		params ||= {}
		record ||= this.record$.value
		
		params['limit'] = params['limit'] || 2
		params['range'] = params['range'] || 'month'

		let obj = this.make({id: params['integration_id'] || record?.id })  // in order to load different warehouses or products, or else, we must explicitly set in params
		delete params['integration_id']

		const force = record && record.isStale('stats')

		return this.getSingleObject<SystemStat[]>(obj, params, 'stats', force).pipe(filter(_ => !!_)).pipe(map(result => result['system_stats'].map(_ => new SystemStat(_))))
	}

}