import { FormObject, ISortable } from "./form_objects";
import { TopbarNotification } from "./topbar.models";
import { User } from "./troly/user.model";
import { TrolyFormGroup } from "./troly_form";
import { uuid } from "./utils.models";

/**
 * 
 */
export class TrolyObject extends FormObject implements ISortable {

	/**
	 * The unique record identifier as set by the API. This is a uuid so technically not (db-) globally unique.
	 */
	declare public id?: uuid;

	/**
	 * Model errors when API validation fails. This should not be relied on and instead, the frontend forms should be doing most of the validation
	 */
	declare public readonly errors?: ApiObjectError;

	/**
	 * Date when this record was created
	 */
	declare public readonly created_at?: Date;

	/**
	 * Date when this record was last udpated
	 */
	declare public readonly updated_at?: Date;

	/**
	 * Object Metada stored in Websolr -- When running a index-only search, we use these attributes to display the results. See `SearchDropdown.component.ts`, see `Customer.rb`
	 */
	declare public metadata?: {};

	/**
	 * FormObject constructor ensures we're not mistakenly casting one object into another via 
	 * @param model 
	 * @param values 
	 */
	constructor(model?: string, values?: Object) {

		super();

		if (model && typeof(model) == 'object') {
			console.warn(`WARNING: TrolyObject incorrectly instantiated. The model name should be a string, not an object.`, model);
		}

		this._trolyModelName = model || '';
		
		if (values) {

			//if (values[this._trolyModelName]) { values = values[this._trolyModelName]; }

			Object.keys(values).forEach(_ => this[_] = values[_], this)
	
			if (values['_trolyModelName']) {
				// we never want to assign/override the model name
				if (values['_trolyModelName'] != model) {
					// In some cases we need to forcefully cast an existing object into a TrolyObject (from an abstract method
					// which only needs to access the TrolyObject methods), essentially this is not a typecasting error so we're not going to raise it
					// 
					// Particularily this hapens within patchValueArray where the objects in the collection seem to lose access to the class methods 
					// (and indirectly the TrolyObject methods).. no sure why. eg. customer.company_customers and customer.memberships -- each collections 
					// are lists of CompanyCutomer and Membership objects
					if (model != '') {
						console.warn(`WARNING: An attempt to cast a Troly Object (${values['_trolyModelName']}) into another (${model}) was detected, and halted.`);
					}
				}

				if (values['id'] && values['_trolyModelName'] != this._trolyModelName) {
					// when a modal is opened, the current record is automatically passed to the modal see TrolyComponent.openModal()
					// the modal then instantiate a fresh object, and we need to ensure the ID is not copied across if the model name is different
					// preventing (eg.) a customer object being typecasted into a Interaction in CreateInteractionCard.resetOnNavigate
					delete values['id'];
				}
			}
		}

		Object.keys(this).filter(key => values && values[key]).forEach((key) => {
			if (key.match(/_date$/) || key.match(/_at$/) || key.match(/_until$/) || key.match(/_after$/) || key.match(/_on$/)) {
				this[key] = this.toDateWithOffset(values[key], key);
			}
		}, this)

		if (false && values && values['metadata']) {
			this.metadata = JSON.parse(values['metadata']);
			Object.keys(this.metadata).forEach((key) => {
				Object.assign(this, {[key]:this.metadata[key]})
			})
		}
	}

	public toString() : string {
		if (this._trolyModelName) {
			return `${this._trolyModelName.charAt(0).toUpperCase()}${this._trolyModelName.substring(1).toLowerCase()}=${this.id}`;
		} else {
			return `Object=${this.id}`;
		}
	}

	public postChangesSaved(updatedObject: TrolyObject): TrolyObject {
		return super.postChangesSaved(updatedObject) as TrolyObject;
	}
	public confirmChangesSaved(form:TrolyFormGroup, payload:FormObject, result:TrolyObject): TrolyObject {
		return super.confirmChangesSaved(form, payload, result) as TrolyObject;
	}


	/**
	 * Checks whether this record is readonly (which is not the same as being 'trashed'). Models supporting readonly/trash override this method
	 * @returns boolean
	 */
	public isReadOnly(): boolean { return false; }

	public toNotification(notification?: Partial<TopbarNotification>): TopbarNotification {
		return super.toNotification(Object.assign({
			id: this.id,
			_trolyModelName: this._trolyModelName,

			data: this,
			type: this._trolyModelName,
			status: this.created_at == this.updated_at ? 'new' : 'info', 	// if we have an indication of whether a record was just created, we use that as default
			timestamp: this.updated_at																							 // this is show as "notification time"
		}, notification)) as TopbarNotification
	}

	/**
	 * Default sort function to compare two objects. Mimicks and leverates the native `string.localeCompare()` function and adds direction.
	 * @param b
	 * @param asc 
	 * @returns 
	 */
	public localeCompare(b:TrolyObject, asc:boolean=true): number {
		if (asc) { return this.toString().localeCompare(b.toString()) }
		else { return b.toString().localeCompare(this.toString()) }
	}

	toLocalStorage(): TrolyObject {
		return this
	}

	/**
	 * Used to compare objects betwen each other like obj1 = obj2 without bothering on all the properties being equal
	 * @returns a unique string representing this object.
	 */
	valueOf(): string {
		return `<${this._trolyModelName}${this._trolyModelName && this.id ? '=' : ''}${this.id}/>`;
	}

	/**
	 * 
	 * @param obj 
	 * @returns 
	 */
	public sameAs(obj: TrolyObject): boolean {

		// this is not technically accurate, however typescript seems to drop the 'class' 
		// objects are assigned to when collections are manipulated and this is just a pain (namely, list.component retrieving clubs and caching to localStorage)
		return this.id == obj?.id;
		//return this._trolyModelName == obj?._trolyModelName && this.id == obj?.id;
	}

	/**
	 * Used to detect that an object was just created (is new record) or else was saved. Often used with `TrolyService.saveOrCreate()`
	 * @returns boolean
	 */
	public isNewRecord?(): boolean {
		// The typescript compiler complains about this method, not being defined in FormObject.
		// Marking it as optional resolves this?! ( see https://github.com/KnisterPeter/react-to-typescript-definitions/issues/95#issuecomment-215741764 )
		return this.created_at && this.updated_at && this.created_at.getTime() == this.updated_at.getTime();
	}

	/**
	 * Helper function to retrieve a user from a collection, often a `provider_data`-stored user.
	 * @param id 
	 * @param collection 
	 * @returns 
	 */
	public _user<T>(id:uuid, collection:User[]): User | null {
		let u = null;
		if (collection) { u = collection.find(_ => _.id == id) } // This is an object level method and should always deal with instantiated objects
		return u
	}

}

export interface IModalReturn {
	record?: TrolyObject
	trigger: string
}

export interface CdnMountedImage {
	url?: string 
	thumbnail?: { url:string }
	small?: { url:string }
	medium?: { url:string }
}

export interface ApiObjectError {
	[field:string]: string[] 
}

export class Job extends TrolyObject {

	constructor(values?: Object) {
		super('job', values);
	}

}