import { Component, Input, SimpleChanges, inject } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TrolyModal } from 'src/app/core/components/troly.modal';
import { Document } from 'src/app/core/models/troly/document.model';
import { DocumentService } from 'src/app/core/services/troly/document.service';


@Component({
  selector: 'document-viewer-modal',
  templateUrl: 'document-viewer.modal.html'
})
export class DocumentViewerModal extends TrolyModal<Document> {

	__name:string='DocumentViewerModal'

	@Input() record:Document = new Document();
	
	safeDocumentUrl:SafeResourceUrl = null;

	protected documentService: DocumentService = inject(DocumentService);

	constructor(
		private sanitizer: DomSanitizer) { 
			super();

			this.localCache.attachStorageNotifier<string>(`${this.__name}.iframeHeight`).subscribe((_) => this.iframeHeight = _ );
	
	}

	protected receiveInputParams(changes: SimpleChanges): void {
		if (changes.record) {
			this.safeDocumentUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.record?.data?.url.replace(/\.svg$/,'.png') + '#navpanes=0');
		}
		super.receiveInputParams(changes);
	}

	iframeHeight:string = '400px';
	public setHeight(value):boolean {
		this.localCache.storeUiKey<string>(`${this.__name}.iframeHeight`, value);
		return false;
	}

}
