import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Directive, inject } from '@angular/core';
import { Observable } from 'rxjs';

import { Company } from 'src/app/core/models/troly/company.model';
import { User } from 'src/app/core/models/troly/user.model';
import { environment } from 'src/environment/environment';
import { uuid } from '../models/utils.models';
import { WindowService } from './window.service';

@Directive({
	providers: [ HttpClient, WindowService ]
})
export abstract class TrolyApi {

	/**
	 * The name or identifier of the current class, not otherwise available when running in "production mode". 
	 * It is used to output debugging information on the console, and also attached to translations of labels, product tours, etc
	 */
	public readonly __name:string = 'TrolyApi';

	/**
	 * 
	 */
	protected apiBaseUrl;

	protected httpClient: HttpClient = inject(HttpClient);
	/**
	 * 
	 */
	constructor() {

	}

	/**
	 * 
	 * @param verb 
	 * @param requestUrl 
	 * @param params 
	 * @param body 
	 * @param headers 
	 * @returns 
	 */
	protected call<T>(verb, requestUrl, params?, body?, headers?): Observable<HttpResponse<T>> {
	
		headers ||= {};
		const withCredentials = headers['withCredentials'] != undefined ? headers['withCredentials'] : true;
		delete headers['withCredentials']

		const options = {
			headers: this.apiHeaders(headers),
			observe: 'response' as 'response',
			params: params,
			body: body,
			withCredentials: withCredentials,
		};

		if (this.apiBaseUrl == undefined) {
			const region = localStorage.getItem('db') || 'us-nyc';
			this.apiBaseUrl = environment.TROLY_API_URL.replace('//api.', `//api-${region}.`);
		}
		return this.httpClient.request<T>(verb, `${this.apiBaseUrl}${requestUrl}`, options);
	}

	/**
	 * 
	 * @param verb 
	 * @param url 
	 * @param options 
	 * @returns 
	 */
	public httpCall<T>(verb, url, options?: {}): Observable<HttpResponse<T>> {
		return this.httpClient.request<HttpResponse<T>>(verb, url, options);
	}

	/**
	 * Ensures the default API headers required by all Troly API service calls. 
	 * The X-Active-Company allows setting and (in some cases) changing the current active company
	 * The X-CSRF-Token is used to enforce CORS in all mutable (create/update) requests
	 * @param headers 
	 * @returns 
	 */
	protected apiHeaders(headers?: any): {} {

		headers = headers || {};

		if (!headers['Accept']) {
			headers['Accept'] = 'application/vnd.troly.co-v3, application/json, text/plain, */*, ';
		}

		if (!headers['Content-Type']) {
			headers['Content-Type'] = 'application/json';
		}

		if (this.auth) {
			headers['X-Authorization'] = `Token consumer=${this.auth.consumer}, resource=${this.auth.resource}, key=${this.auth.token}`;
			// when authenticating with a token/X-Authorization, there's no 'session' so we don't want to send the company 'selector'
			delete headers['X-Active-Company']; 
		} else {
			let co = this.storedCompany();
			if (co && co.id && !headers['X-Active-Company']) {
				headers['X-Active-Company'] = co.id.toString();
			}
		}

		let csrf = this.storedCsrf();
		if (csrf && !headers['X-CSRF-Token']) {
			headers['X-CSRF-Token'] = csrf;
		}

		return headers;
	}

	protected set auth(value:ITokenAuth) { window.localStorage.setItem('pu', JSON.stringify(value)); };
	protected get auth(): ITokenAuth|null { const pu = window.localStorage.getItem('pu'); return pu && pu != "" ? JSON.parse(window.localStorage.getItem('pu')) : null; };

	/**
	 * Defines the localStorage keys used by the services for various purposes.
	 */
	private readonly STORAGE_KEYS = {
		CSRF_TOKEN: 'cs',
		SELECTED_COMPANY: 'co',
		ACTIVE_USER: 'us',
		USER_CACHE: 'uc',
		API_TOKEN: 'to'
	}

	/**
	 * 
	 * @param csrf 
	 * @returns 
	 */
	public storedCsrf(csrf?: string): string {
		if (csrf) {
			if ((typeof csrf) == undefined) {
				window.localStorage.removetItem(this.STORAGE_KEYS.CSRF_TOKEN);
			} else {
				window.localStorage.setItem(this.STORAGE_KEYS.CSRF_TOKEN, csrf);
			}
		}
		return window.localStorage.getItem(this.STORAGE_KEYS.CSRF_TOKEN);
	}

	/**
	 * 
	 * @param user 
	 * @param action 
	 * @returns 
	 */
	public storedUser(user?: User, action?: string): User {
		action = action || 'logout'

		if (user) {
			if ((typeof user.id) == undefined) {
				window.localStorage.removeItem(this.STORAGE_KEYS.ACTIVE_USER);
			} else {
				if (user instanceof User) {
					window.localStorage.setItem(this.STORAGE_KEYS.ACTIVE_USER, JSON.stringify(user.toLocalStorage(action)));
				} else {
					console.warn(`storedUser has not updated, data received is NOT instanceof User`, user)
				}
			}
		}
		let result = window.localStorage.getItem(this.STORAGE_KEYS.ACTIVE_USER);
		return result ? new User(JSON.parse(result)) : new User();
	}

	/**
	 * 
	 * @param company 
	 * @param action 
	 * @returns 
	 */
	public storedCompany(company?: Company, action?: string): Company {

		action = action || 'logout'

		if (company) {
			if ((typeof company.id) == undefined) {
				window.localStorage.removetItem(this.STORAGE_KEYS.SELECTED_COMPANY);
			} else {
				if (company instanceof Company) {
					window.localStorage.setItem(this.STORAGE_KEYS.SELECTED_COMPANY, JSON.stringify(company.toLocalStorage(action)));
				} else {
					console.warn(`storedCompany has not updated, data received is NOT instanceof Company`, company)
				}
			}
		}
		let value = window.localStorage.getItem(this.STORAGE_KEYS.SELECTED_COMPANY);
		return value ? new Company(JSON.parse(value)) : new Company();
	}

	/** 
	 * External related API calls. Should be used exceptionally
	 */
	public externalGet(requestUrl, params?, additionalOptions?, headers?, method?): Observable<any> {

		let options = {
			headers: new HttpHeaders(headers),
			observe: 'response' as 'response',
			params: params,
		};

		if (additionalOptions) {
			options = { ...options, ...additionalOptions };
		}

		if (!method || method == 'get') {
			return this.httpClient.get<any>(requestUrl, options);

		} else if (method == 'delete') {
			return this.httpClient.delete<any>(requestUrl, options);

		} else if (method == 'put') {
			delete options['params'];
			return this.httpClient.put<any>(requestUrl, params, options);

		} else if (method == 'post') {
			delete options['params'];
			return this.httpClient.post<any>(requestUrl, params, options);

		}
	}

	/**
	 * 
	 * @param size 
	 * @returns 
	 */
	genRanHex = size => [...Array(size)].map(() => Math.floor(Math.random() * 16).toString(16)).join('');
	genUUID = ():uuid => `${this.genRanHex(8)}-${this.genRanHex(4)}-${this.genRanHex(4)}-${this.genRanHex(4)}-${this.genRanHex(12)}`;
}

export interface ITokenAuth {
	consumer:uuid,
	resource:uuid,
	token:string
}
