import { TrolyObject } from "../troly_object";
import { uuid } from "../utils.models";

/** Notes on interfaces vs clases 
 * Angular will not automatically type cast responses from httpClient into an object.
 * Normall an interface 'acts as a contract' and allows for typescript to accurately check types on compile.
 * However interfaces don't have reusable methods, which translates to typ-ecasting from httpClient responses 
 * to only enforce object attributes, not methods so we need to lean on a 'helper' or 'handler' or 'Model' class to do that, and that needs to be type-casted by hand
 * 
 * see: https://stackoverflow.com/questions/50693272/angular-casting-httpclient-response-class-or-interface
 * 
 * Finally, given httpClient will not 'type-cast' into a class, but the compiler will use the class definitition like it does an interface.
 * so there's no real advantades to using an interface in our case: if we want methods on objects returned through httpclient calls, we need to manually cast into a class of any sort.
 * 
 * The only problem left is the lack of a recognition of an interface (TrolyObject) being implemented by a parent class (TrolyObject) and not recognised as 'implemented' by the child class.
*/
export class Award extends TrolyObject {

	declare reach: string;
	declare internal_comments: string;
	declare shared_comments: string;
	declare description: string;
	declare tagline: string;

	declare name: string;
	declare rating_max: string;
	declare rating_value: number;
	declare rating_scale: number;

	declare data_received: Object;
	declare documents_submitted: Object;

	declare award_url: string;
	declare website_url: string;
	declare award_date: Date;

	declare reviewer_name: string;
	declare reviewer_org: string;
	declare reviewer_title: string;

	declare status: 'verified'|'pending'|'unverified'|'rejected';
	declare visibility: 'internal'|'limited'|'public';
	
	declare verified_at: Date;
	declare verified_by: string;
	declare verified_by_id: uuid;
	declare last_verification_at: Date;
	declare next_verification_at: Date;

	constructor(values?: Object) {
		super('award', values);

	}
}
