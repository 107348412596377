import { ValidatorFn, Validators } from "@angular/forms";
import { TrolyObject } from "../troly_object";
import { uuid } from "../utils.models";
import { Customer } from "./customer.model";
import { Document } from "./document.model";
import { Membership } from "./membership.model";
import { Message } from "./message.model";
import { Order } from "./order.model";
import { Payment } from "./payment.model";
import { Shipment } from "./shipment.model";
import { Task } from "./task.model";
import { User } from "./user.model";

export class Interaction extends TrolyObject {

	declare company_id:uuid
	declare customer_id:uuid
	_customer_id: ValidatorFn[] = [Validators.required]
	declare order_id:uuid
	
	declare interactable_id:uuid
	declare interactable_type:string
	declare interactable:Customer|Order|Shipment|Membership|Payment|Task|Document|Message

	declare body: string;
	
	declare direction:'received'|'sent'|'internal'
	declare source: 'system'|'company'|'user'|'customer'|'appdirect'|'appindirect';
	declare operation: string;
	declare user_id: uuid;
	declare sentiment: {};
	declare data: {}

	declare slug: string; // used to pull the correct translation

	declare provider_data: {
		users: User[]
	}

	constructor(values?: Object) {
		super('interaction', values);
		if (values) {
			let k = 'user'
			if (values[k]) { this[k] = new User(values[k]); }

			if (values['interactable']) {
				if (values['interactable_type'] == 'Payment') { this.interactable = new Payment(this.interactable['product']) }
				if (values['interactable_type'] == 'Order') { this.interactable = new Order(this.interactable['order']) }
				if (values['interactable_type'] == 'Customer') { this.interactable = new Customer(this.interactable['customer']) }
				if (values['interactable_type'] == 'Task') { this.interactable = new Task(this.interactable['task']) }
				if (values['interactable_type'] == 'Shipment') { this.interactable = new Shipment(this.interactable['shipment']) }
				if (values['interactable_type'] == 'Message') { this.interactable = new Message(this.interactable['message']) }
				if (values['interactable_type'] == 'Membership') { this.interactable = new Membership(this.interactable['membership']) }
				if (values['interactable_type'] == 'Document') { this.interactable = new Document(this.interactable['document']) }
			}
		}
	}

	public get user(): User | null {
		return super._user(this.user_id, this.provider_data?.users || [])
	}

	get bodyAsEditedHTML(): string {
		const edited = this.created_at < this.updated_at ? ' <em>(edited)</em>' : '';
		return this.body.replace(/\n/gi, "<br/>") + edited
	}


	public getIcon(): string | null {
		switch (this.interactable_type) {
			case 'Customer': return 'mdi mdi-book-account-outline';
			case 'Document': return 'mdi mdi-file-document-outline';
			/*case 'Message':
				switch (this.operation) {
					case 'email': return this.direction == 'received' ? 'mdi mdi-email-receive-outline': 'mdi mdi-email-outline';
					case 'call': return 'mdi mdi-phone-in-talk-outline';
					case 'sms': return 'mdi-cellphone-text';
				}*/
			case 'Order': return 'mdi mdi-cart-outline';
			case 'Payment': return 'bx bx-dollar-circle';
			case 'Shipment': return 'mdi mdi-truck-delivery-outline';
			case 'Task': return 'bx bx-task';
			default:
				switch (this.operation) {
					case 'email': return this.direction == 'received' ? 'mdi mdi-email-receive-outline': 'mdi mdi-email-outline';
					case 'call': return 'mdi mdi-phone-in-talk-outline';
					case 'sms': return 'mdi mdi-cellphone-text';
					case 'visit': return 'bx bx-map';
				}
			
		}

		/*
		'bx-user-voice': s.what == 'visit',
		'mdi-timeline-clock-outline': s.what == 'delay',
		'mdi-card-account-details-star-outline': s.what == 'membership',
		'bx-analyse': s.what == 'analytics',
		'mdi-account-cash-outline': s.what == 'referrals',
		'mdi-ticket-confirmation-outline': s.what == 'coupon',
		*/
			
	}

}