import { ValidatorFn, Validators } from '@angular/forms';
import { TopbarNotification } from '../topbar.models';
import { CdnMountedImage, TrolyObject } from '../troly_object';
import { uuid } from '../utils.models';
import { PipelineStat, SalesStat } from './stats.model';

export class Club extends TrolyObject {

	override _trolyPropertyArray = { segments: Array, incentives: Object, settings: Object }

	/** Human-readable name to recognise and advertise this club */
	declare short_name: string;
	_short_name: ValidatorFn[] = [Validators.required]

	declare long_name: string;
	declare tagline: string;

	/** General description of this club structure, operations and accessibility */
	declare description: string;
	declare secondary_description: string;

	declare max_members_count_internal: number;
	declare max_members_count: number;
	declare availability_details: string;

	/** Whether or not this club is considered 'wholesales' -- this matters for margins calculations */
	declare is_wholesales: boolean;
	declare is_wholesales_warning_internal: string;
	declare is_wholesales_notes_internal: string;

	/** List of 1-liner benefits offered to club members, each separated by \n */
	declare benefits: string;

	/** short explanation of who should be picking this over any other */
	declare who_chooses_this: string;

	/** Company who created+operates this club */
	declare company_id: uuid;
	_company_id:Validators[] = [Validators.required]

	declare customer_id: uuid;
	_customer_id:Validators[] = [Validators.required]

	/** Array of ids+name for individual segments to assign club members to */
	declare segments?: IClubSegment[];

	/** Staff member responsible for this club success */
	declare sales_manager_id: uuid;

	/** The preferred location/warehouse for members to PICKUP their order from (and where stock is depleted from) */
	declare orders_warehouse_id: uuid;
	/** The preferred location/warehouse for members orders to SHIP OUT OF (and where stock is depleted from) */
	declare shipments_warehouse_id: uuid;

	/** The club to renew members to when a full commitment is reached (success) */
	declare renew_to_id_success: uuid;
	/** The club to renew members to when membership commitment is NOT reached (failed) */
	declare renew_to_id_failure: uuid;

	/** Defines the availability for new members to signup to this club */
	declare visibility: string;
	_visibility: ValidatorFn[] = [Validators.required]
	declare signup_qr_code: CdnMountedImage;

	/**
	 * Tag used to identify customers on the waitlist for this club
	 */
	declare waitlist_tag_id: uuid;
	declare waitlist_auto_invite: boolean;

	/** The amount to be charged to members on a regular basis */
	declare prepayment_value: number;
	_prepayment_value: ValidatorFn[] = [Validators.min(1)]

	/** The schedule under which to charge members */
	declare prepayment_schedule: string;

	/** Number of purchases expected to consider a member committed */
	declare purchases: number;
	_purchases: ValidatorFn[] = [Validators.required, Validators.min(2), Validators.max(12)]

	/** The length of time allowed to place the number of purchases required */
	declare duration: number
	_duration: ValidatorFn[] = [Validators.required, Validators.pattern(/1\.month|3\.months|6\.months|12\.months/)]

	/** The measured value of the commitment expected of club members */
	declare commitment_value: number
	_commitment_value: ValidatorFn[] = [Validators.required, Validators.min(1)]

	/** The unit to measure the commitment expected of club members */
	declare commitment_unit: string
	_commitment_unit: ValidatorFn[] = [Validators.required, Validators.pattern(/dollars|units|weight/)]

	/** List of product variety that are counted towards commitment being met, empty means any */
	declare commitment_variety: string[]

	/** The various benefits settings afforded to club members including reward points and discounts */
	declare incentives: {};

	/** The renewals, club run and other club settings applied to operate this club */
	declare settings: {};

	/** The club run settings applied in the last club run ( @see BatchOrder ), to automatically pre-select in the next */
	declare last_batch_params: {};

	/**
	 * Purchases placed (dates and order_ids) and accounted against this membership' commitment
	 */

	/** Date at which the FIRST of X purchases was recorded ( @see Club.purchases ) */
	declare purchase_1_date: Date
	/** Date at which the SECOND of X purchases was recorded ( @see Club.purchases ) */
	declare purchase_2_date: Date
	/** Date at which the THIRD of X purchases was recorded ( @see Club.purchases ) */
	declare purchase_3_date: Date
	/** Date at which the FOURTH of X purchases was recorded ( @see Club.purchases ) */
	declare purchase_4_date: Date
	/** Date at which the FIFTH of X purchases was recorded ( @see Club.purchases ) */
	declare purchase_5_date: Date
	/** Date at which the SIXTH of X purchases was recorded ( @see Club.purchases ) */
	declare purchase_6_date: Date
	/** Date at which the SEVENTH of X purchases was recorded ( @see Club.purchases ) */
	declare purchase_7_date: Date
	/** Date at which the EIGHT of X purchases was recorded ( @see Club.purchases ) */
	declare purchase_8_date: Date
	declare purchase_9_date: Date
	declare purchase_10_date: Date
	declare purchase_11_date: Date
	declare purchase_12_date: Date


	/** 
	 * Additional Settings Attributes 
	 */
	declare allow_custom_order: boolean
	declare allow_template_order: boolean
	declare customer_editable: boolean
	declare renewal_trigger: 'time' | 'fulfilment' | 'no' 
	declare club_sales_rounding: string

	declare prepayment_send_notification_on_trigger: boolean
	declare prepayment_threshold_business_task: boolean
	declare prepayment_threshold_order_creation: boolean
	declare prepayment_trigger_balance: boolean
	_prepayment_trigger_balance: ValidatorFn[] = [Validators.min(5)]

	/**
	 * This record will be 'trashed' on deletion, appear in the trash, and can be restored, or will forcefully be deleted after a period of time, or manually.
	 */
	declare trashed_at: Date
	declare trashed_by_id: uuid
	declare trashed_reason: string

	/** 
	 * Additional Incentives Attributes 
	 */
	declare member_discount: string;
	declare member_discount_value: number;
	declare member_discount_mode: string;
	_member_discount: ValidatorFn[] = [Validators.required]
	declare club_discount: string;
	declare club_discount_value: number;
	declare club_discount_mode: string;
	_club_discount: ValidatorFn[] = [Validators.required]
	declare affiliate_discount: string;
	declare affiliate_discount_value: number;
	declare affiliate_discount_mode: string;
	_affiliate_discount: ValidatorFn[] = [Validators.required]
	declare qualify_orders_within_days: number

	declare reward_affiliate_amount: number;
	declare reward_affiliate_unit: string;
	declare reward_affiliate_expiry: string;
	declare reward_member_amount: number;
	declare reward_member_unit: string;
	declare reward_member_expiry: string;
	declare reward_club_amount: number;
	declare reward_club_unit: string;
	declare reward_club_expiry: string;
	declare reward_renewal_amount: number;
	declare reward_renewal_expiry: string;

	declare pipeline_stat?:PipelineStat;
	declare sales_stat?:SalesStat;

	constructor(values?: Object) {
		super('club', values)

		let value = this.affiliate_discount?.toString();
		if (value) {
			if (value.match(/[\$\%]$/)) { this.affiliate_discount_mode = value.slice(-1); } 
			else { this.affiliate_discount_mode = '%'; }
			this.affiliate_discount_value = parseFloat(value.replace(/[\$\%]/,''));
		} else if (this.affiliate_discount_mode && this.affiliate_discount_value) {
			this.affiliate_discount = `${this.affiliate_discount_value}${this.affiliate_discount_mode}`
		}

		value = this.member_discount?.toString();
		if (value) {
			if (value.match(/[\$\%]$/)) { this.member_discount_mode = value.slice(-1); } 
			else { this.member_discount_mode = '%'; }
			this.member_discount_value = parseFloat(value.replace(/[\$\%]/,''));
		} else if (this.member_discount_mode && this.member_discount_value) {
			this.member_discount = `${this.member_discount_value}${this.member_discount_mode}`
		}

		value = this.club_discount?.toString();
		if (value) {
			if (value.match(/[\$\%]$/)) { this.club_discount_mode = value.slice(-1); } 
			else { this.club_discount_mode = '%'; }
			this.club_discount_value = parseFloat(value.replace(/[\$\%]/,''));
		} else if (this.club_discount_mode && this.club_discount_value) {
			this.club_discount = `${this.club_discount_value}${this.club_discount_mode}`
		}	


	}

	public toString(full:boolean=true) : string {
		return full ? `${this.short_name}, ${this.purchases} x ${this.commitment_value} (${this.segments?.length} segments)` : `${this.short_name}`
	}

	public toNotification(notification?: Partial<TopbarNotification>): TopbarNotification {
		return super.toNotification(Object.assign({
			link: '/customers/sales',
			badge: this.visibility,
			linkFragment: 'clubs',
			linkParams: { highlight: this.id } // will trigger the highlightable directive to highlight the record
		}, notification)) as TopbarNotification
	}

	public toDateWithOffset(date: string, attribute?: string): any {
		return attribute == 'renewal_trigger' ? date : super.toDateWithOffset(date, attribute)
	}

	public patchableValues() {
		let result = super.patchableValues()
		result['commitment_unit'] ||= '$'
		return result
	}

	public attritionRate(segment_id?:uuid) {
		
		if (!this.pipeline_stat) { return 0; }

		let result, new_total, net_gain;

		if (segment_id) {
			net_gain = this.pipeline_stat?.members_new_per_segment[segment_id.toString()] - this.pipeline_stat?.members_lost_per_segment[segment_id.toString()]
			new_total = this.pipeline_stat?.members_count_per_segment[segment_id.toString()]
		} else {
			net_gain = this.pipeline_stat?.members_new - this.pipeline_stat?.members_lost
			new_total = this.pipeline_stat?.members_count
		}
		
		if (new_total > 0) { result =  net_gain / new_total }
		
		return result ? result * 100 : 0
	}	

	public beforeSave(originalObject:Club): boolean {
		
		this.club_discount ||= 			`${this.club_discount_value}${this.club_discount_mode}`;
		this.member_discount ||= 		`${this.member_discount_value}${this.member_discount_mode}`;
		this.affiliate_discount ||= 	`${this.affiliate_discount_value}${this.affiliate_discount_mode}`;

		delete this.club_discount_mode; delete this.club_discount_value;
		delete this.member_discount_mode; delete this.member_discount_value;
		delete this.affiliate_discount_mode; delete this.affiliate_discount_value;
		
		return super.beforeSave(originalObject);
	}
}

export interface IClubSegment {
	id: string
	name: string
}