import { Component, Input, SimpleChanges } from '@angular/core';
import { TrolyModal } from 'src/app/core/components/troly.modal';
import { Address } from 'src/app/core/models/troly/address.model';
import { uuid } from 'src/app/core/models/utils.models';
import { CompanyService } from 'src/app/core/services/troly/company.service';
import { CustomerService } from 'src/app/core/services/troly/customer.service';
import { TrolyValidationError } from 'src/app/core/services/troly/troly.interceptor';


@Component({
  selector: 'create-address-modal',
  templateUrl: './create-address.modal.html',
  styleUrls: ['./create-address.modal.scss']
})
export class CreateAddressModal extends TrolyModal<Address> {

	__name:string='CreateAddressModal'

	/**
	 * When specified, forces addresses to be created against this customer, else, against the company
	 */
	@Input() customer_id!: uuid;

	@Input() record:Address = new Address();

	_formFields: string[] = ['id', 'label', 'internal_notes', 'line1','line2','line3','line4','suburb','postcode', 'state', 'country', 'delivery_instructions', 'is_manual_override', 'sync_warehouse', 'lat','lng','unit','number','street','state_abbr','region','formatted_address','google_response'];
	_formDefaults = {label:'Home', google_response: {}}

	constructor(
		protected customerService: CustomerService) {

		super();
		
		this.initForm();
	}

	onSubmit(): void {
		if (this.customer_id) {
			this.onSubmitForm(this.customerService, this.customer_id)
		} else {
			this.onSubmitForm(this.companyService, this.selectedCompany.id)
		}
	}

	onSubmitForm(service:CustomerService|CompanyService, record_id:uuid): void {
		
		this.markAsLoading(this.form);
		
		let changes = this.form.getChanges(this.record);

		this.form.disable();
		service.createAddress(new Address(changes), <any>service.make({id:record_id})).subscribe({
			next: (_:Address) => {
			
				this.form.resetCodes({success: (this.record.id ? 'SAVED':'CREATED') });
				
				this.record = _;
				this.recordSaved.emit(_);

				this.form.enable();this.form.patchValue(_);this.form.disable();
				
				// delay redirection by a second so the user can see the success message
				this.form._timer = setTimeout(() => {
					// See routerNaviate: https://www.tektutorialshub.com/angular/angular-navigating-between-routes/
					this.resolveModal('timeout');
				}, this.form.countdown());
			
			}, 
			error: (err) => {

				if (err instanceof TrolyValidationError) {
					this.form.assignValidationErrors(err.error);
					this.markAsLoaded(this.form);
				} else {
					this.unknownApiError('7283-autoban-ANIMAL-concrete')
				}
			}
		});
  }

	protected receiveInputParams(changes: SimpleChanges): void {
		if (changes.record?.currentValue) {
			this.record = new Address(changes.record.currentValue);
		}
		this.form.get('label').setValue(this.customer_id ? 'Home' : 'Office');
		super.receiveInputParams(changes);
	}

}
