import { Component, Input } from '@angular/core';
import { TrolyComponent } from 'src/app/core/components/troly.component';

@Component({
    selector: 'guru',
    templateUrl: './guru-marker.widget.html',
    styleUrls: ['./guru-marker.widget.scss'],
})
export class GuruMarkerComponent extends TrolyComponent {

    @Input() style:string='';

}