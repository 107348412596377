import { Validators } from "@angular/forms";
import { CdnMountedImage, TrolyObject } from "../troly_object";
import { uuid } from "../utils.models";
import { Company } from "./company.model";
import { Document } from "./document.model";

export class Tag extends TrolyObject {

	declare company_id?:uuid
	declare label: string;
	_label: Validators[] = [Validators.required, Validators.minLength(2), Validators.maxLength(50)];

	declare slug: string;
	_slug: Validators[] = [Validators.required, Validators.minLength(10), Validators.maxLength(25)];
	
	declare category: 'custom'|'type'|'color'|'region'|'variety'|'aroma'|'award'|'closure'|'container'|'config'|'marketing'|'material';
	declare usage: string;
	_usage: Validators[] = [Validators.required];

	declare company: Company;
	declare feedback_qr_code?: CdnMountedImage;

	declare visibility: 'system'|'public'|'limited'|'internal'|'team'|'user';

	declare event_lat: number;
	declare event_lng: number;
	declare feedback_url_override: string;

	declare documents: Document[];

	declare campaign_details:string;
	declare campaign_staff_id:uuid;
	declare campaign_staff_name:string;
	declare campaign_start_date:Date;
	declare campaign_end_date:Date;

	constructor(values?: Object) {
		super('tag', values);

		if (values) {
			let k = 'documents'
			if (values[k] && values[k].length && !(values[k][0] instanceof Document)) {
				values[k].map((obj, i) => { this[k][i] = new Document(obj) });
			}

			k='company'
			if (values[k]) { this[k] = new Company(values[k]); }
		}
	}

	get geo_coords_qr_code(): Document { return this.getDocumentByIdent('geo_coords_qr_code') }
	get feedback_doc(): Document { return this.getDocumentByIdent('feedback_doc') }
	protected getDocumentByIdent(ident:string): Document { return this.documents?.find(_ => _.ident == ident); }

	public getIcon(): string | null {
		switch (this.category) {
			case 'type':
				switch (this.usage) {
					case 'products-beer': return 'mdi mdi-beer-outline';
					case 'products-wine': return 'mdi mdi-bottle-wine-outline';
				}
			case 'color': return 'mdi mdi-palette';
			case 'aroma': return 'mdi mdi-flower-tulip-outline';
			case 'region': return 'mdi mdi-earth';
			case 'variety': return 'mdi mdi-fruit-grapes-outline';
			case 'award': return 'mdi mdi-license';
			case 'closure': return 'mdi mdi-bottle-soda';
			case 'container': return 'mdi mdi-package-variant-closed';
			case 'config': return 'mdi mdi-puzzle';
			case 'marketing': return 'mdi mdi-account-heart';
			case 'custom':
				switch (this.usage) {
					case 'customers': return 'mdi mdi-account-circle-outline';
					case 'orders': return 'mdi mdi-calendar-outline';
					case 'products': return 'mdi mdi-calendar';
				}
			}
	}
	public getBadgeColour(): string {
		switch (this.category) {
			case 'closure': case 'container': return 'badge-soft-dark';
			case 'type': case 'region': case 'color': case 'aroma': case 'variety': case 'award': case 'material': return 'badge-soft-warning text-orange';
			case 'marketing': return 'badge-soft-success';
			case 'custom':
				switch (this.usage) {
					case 'customers': return 'badge-soft-primary';
					case 'orders': return 'badge-soft-pink';
					case 'products': return 'badge-soft-success';
			}
		}
	}

	public getAccentColour(prefix:string|string[]=['bg']): string {
		if (!Array.isArray(prefix)) { prefix = [prefix] }

		let result = '';

		if (this.category == 'custom') {
			switch (this.usage) {
				case 'customers': result = prefix.map(_ =>  `${_}-primary`).join(' '); break;
				case 'orders': result = prefix.map(_ =>  `${_}-pink`).join(' '); break;
				case 'products': result = prefix.map(_ =>  `${_}-success`).join(' '); break;
			}
		} else {
			result = prefix.map(_ =>  `${_}-dark`).join(' ');
		}

		return result;
	}

	public toString() : string {
		
		if (this.label) { return this.label; }

		var slug = this.slug?.replace(/\-([a-zA-Z]*?)/, function(v) { return v.toUpperCase(); }) || ''
		slug = slug.replace(/\-([a-zA-Z]*?)/, function(v) { return v[0].toUpperCase() + v.slice(1); })

		return slug.replaceAll('_', ' ').replaceAll('-', ', '); 
	}

	public get public() : boolean { return this.visibility == 'public' }
	public get limited() : boolean { return this.visibility == 'limited' }
	public get system() : boolean { return this.visibility == 'system' }
	public get internal() : boolean { return this.visibility == 'internal' || this.visibility == 'team' || this.visibility == 'user' }
}

export class CompanyTag extends TrolyObject {
	map(arg0: (_: any) => import("./company.model").BillingPeriod): any {
		throw new Error('Method not implemented.');
	}

	declare company_id: uuid;
	declare tag_id: uuid;
	declare tag: Tag;
	declare weight: number

	/** Date at which this is due for renewal */
	declare review_at: Date;
	/** Registration, confirmation or certification number */
	declare reference: string;
	/** Any submitted documents, reference numbers or else are contained here */
	declare data: {}

	constructor(values?: Object) {
		super('customer_tag', values);

		if (values && values['tag']) { this.tag = new Tag(values['tag']) }
	}

}
export class CustomerTag extends TrolyObject {

	declare customer_id: uuid;
	declare tag_id: uuid;
	declare tag: Tag;
	declare weight: number

	declare purchases_confirmed: number
	declare tagged_by_admin: number
	declare tagged_by_customer: number
	declare purchases_initiated: number

	constructor(values?: Object) {
		super('customer_tag', values);

		if (values && values['tag']) { this.tag = new Tag(values['tag']) }
	}
}

export class OrderTag extends TrolyObject {

	declare order_id: uuid
	declare tag_id: uuid
	declare tag: Tag;
	declare weight: number

	constructor(values?: {}) {
		super('order_tags', values)

		if (values && values['tag']) { this.tag = new Tag(values['tag']) }
	}
}

export class ProductTag extends TrolyObject {

	declare product_id: uuid
	declare tag_id: uuid
	declare tag: Tag;

	/** amount of the tag present in the product (eg 95% Shiraz) */
	declare weight: number

	declare purchases_confirmed: number
	declare tagged_by_admin: number
	declare purchases_initiated: number

	constructor(values?: {}) {
		super('product_tags', values)

		if (values && values['tag']) { this.tag = new Tag(values['tag']) }
	}
}