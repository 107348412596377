import { Component, OnDestroy, inject } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { Company } from '../models/troly/company.model';
import { User } from '../models/troly/user.model';
import { TrolyObject } from '../models/troly_object';
import { WindowService } from '../services/window.service';

// Even though this is called 'Component' we declare as a directive
// as that is the requirement for base class components in Angular 9+
@Component({
	selector: 'root-component',
	template: '',
})

// Base component that enables a single method of destroying observable
// subscriptions when a component is destroyed
export abstract class RootComponent implements OnDestroy {

	/**
	 * The name or identifier of the current class, not otherwise available when running in "production mode". 
	 * It is used to output debugging information on the console, and also attached to translations of labels, product tours, etc
	 * @see TrolyComponent.log
	 */
	public readonly __name:string = 'RootComponent';

	// Setting the authenticatedUser/selectedCompany here reduces our code duplication
	// additionally, ALL components MAY (or may NOT -- see pages w/ public.layout) have a user+company record.
	// see https://www.codemag.com/article/1805021/Security-in-Angular-Part-1
	public authenticatedUser: User; 	 /* contains the currently authenticated user. */
	public selectedCompany: Company; /* contains the currently selected / active company */

	// when subscribing to an observable the child component
	// will use this in a `takeUntil()` to ensure the
	// subscription is destroyed when the child component is
	//
	// See: https://medium.com/@benlesh/rxjs-dont-unsubscribe-6753ed4fda87 (creator of RxJS)
	public observablesDestroy$ = new Subject();

	public windowService: WindowService = inject(WindowService);

	constructor() {
		this.windowService.debug$.pipe(takeUntil(this.observablesDestroy$)).subscribe((d) => { this._debug = d; });
	}

	// This is where the magic of unsubscribing all observables happens
	// if the child component ever needs to override `ngOnDestroy()`
	// it MUST MUST call `super.ngOnDestroy()` or else this BaseComponent
	// ngOnDestroy will not run and any observables will stay subscribed
	// causing memory leaks every single time that component is initialised
	// as more and more subscriptions will pile up that never get destroyed
	ngOnDestroy() {
		// unsubscribe when component is destroyed to avoid memory leaks
		//this.observablesDestroy$.complete();
		this.observablesDestroy$.next(true);
		this.observablesDestroy$.complete();
	}

	/**
	 * 
	 */
	public logInfo(message: string) { this.windowService.log(message, 'INFO'); }
	public logTrace(message: string) { this.windowService.log(message, 'TRACE'); }
	public logWarning(message: string) { this.windowService.log(message, 'WARN'); }
	public logError(message: string) { this.windowService.log(message, 'ERROR'); }
	
	protected _debug:string[] = [];
	public debug(level?:string):boolean { if (level == undefined) { return this._debug.length > 0; } else { return this._debug.includes(level) } }

	protected readonly CACHE_KEYS = {
		'IS_CONDENSED': 'nav.is-condensed.persist',
		'IS_VERTICAL': 'nav.prefer-sidebar.persist',
		'DARK_MODE': 'system.dark-mode.persist',
		'DRAWER_MODE': 'layout.drawer-mode.persist',
	}


	// 888b     d888               888          888      888    888                        888 888 d8b
	// 8888b   d8888               888          888      888    888                        888 888 Y8P
	// 88888b.d88888               888          888      888    888                        888 888
	// 888Y88888P888  .d88b.   .d88888  8888b.  888      8888888888  8888b.  88888b.   .d88888 888 888 88888b.   .d88b.
	// 888 Y888P 888 d88""88b d88" 888     "88b 888      888    888     "88b 888 "88b d88" 888 888 888 888 "88b d88P"88b
	// 888  Y8P  888 888  888 888  888 .d888888 888      888    888 .d888888 888  888 888  888 888 888 888  888 888  888
	// 888   "   888 Y88..88P Y88b 888 888  888 888      888    888 888  888 888  888 Y88b 888 888 888 888  888 Y88b 888
	// 888       888  "Y88P"   "Y88888 "Y888888 888      888    888 "Y888888 888  888  "Y88888 888 888 888  888  "Y88888
	//                                                                                                               888
	//                                                                                                          Y8b d88P
	//                                                                                                           "Y88P"

	/**
	 * 
	 * @param template 
	 * @param vars 
	 * @param options 
	 * @param clearPrevious 
	 * @returns 
	 */
	public openModal(template, vars?: TrolyObject|{}, options?: any, clearPrevious:boolean=true): boolean {

		options ||= {}

		if (clearPrevious) { this.windowService.clearModal(); }
		
		if (this.windowService.readyToOpen() || !clearPrevious) {

			options['backdrop'] ||= 'static' // The default behaviour is to prevent clicking on the backdrop, but allow the escape key
			options['keyboard'] ||= true		// This allows to easily prevent a dirty form from being dismissed. See `TrolyModal.resolveModal`

			this.windowService.openModal(template, options);
		}

		return false; // used to prevent navigation on (click)="!!openModal"
	}

	public openLargeModal(template, vars?: any, options?: any): boolean {
		options ||= {};
		options['size'] ||= 'lg';
		return this.openModal(template, vars, options);
	}

	public openXLargeModal(template, vars?: any, options?: any): boolean {
		options ||= {};
		options['size'] ||= 'xl';
		return this.openModal(template, vars, options);
	}

	private multiClickTracking:{[key:string]:number}={}
	protected checkMultiClick(key, total:number=2):boolean {
		
		key = `${this.__name}.${key}`
		
		this.multiClickTracking[key] = (this.multiClickTracking[key] || 0) + 1;

		setTimeout(() => { this.multiClickTracking[key]=0; }, 600)
		
		if (this.multiClickTracking[key] >= total) {
			this.multiClickTracking[key] = 0;
			return true;
		} else {
			return false;
		}
	}

}