
import { ValidatorFn, Validators } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { Club, IClubSegment } from 'src/app/core/models/troly/club.model';
import { CdnMountedImage, TrolyObject } from '../troly_object';
import { uuid } from "../utils.models";
import { Address } from "./address.model";
import { Carton } from "./carton.model";
import { CompanyTemplate } from "./company_template.model";
import { CompanyUser } from "./company_user.model";
import { Customer } from './customer.model';
import { Document } from "./document.model";
import { buildCorrectIntegrationObject, Integration, PaymentIntegration, ShippingIntegration } from "./integration.model";
import { IMiniLedger } from './ledger.model';
import { Membership } from './membership.model';
import { PaymentCard } from './payment_card.model';
import { Product } from './product.model';
import { ShippingRule } from "./shipping_rule.model";
import { DemographicStat, PipelineStat, PipelineStatSample, SalesStat, StockStat, StockStatSample, SystemStat } from "./stats.model";
import { CompanyTag, Tag } from "./tag.model";
import { Task } from './task.model';
import { User } from "./user.model";
import { Warehouse } from "./warehouse.model";


/** Notes on interfaces vs clases 
 * Angular will not automatically type cast responses from httpClient into an object.
 * Normall an interface 'acts as a contract' and allows for typescript to accurately check types on compile.
 * However interfaces don't have reusable methods, which translates to typ-ecasting from httpClient responses 
 * to only enforce object attributes, not methods so we need to lean on a 'helper' or 'handler' or 'Model' class to do that, and that needs to be type-casted by hand
 * 
 * see: https://stackoverflow.com/questions/50693272/angular-casting-httpclient-response-class-or-interface
 * 
 * Finally, given httpClient will not 'type-cast' into a class, but the compiler will use the class definitition like it does an interface.
 * so there's no real advantades to using an interface in our case: if we want methods on objects returned through httpclient calls, we need to manually cast into a class of any sort.
 * 
 * The only problem left is the lack of a recognition of an interface (TrolyObject) being implemented by a parent class (TrolyObject) and not recognised as 'implemented' by the child class.
*/
export class Company extends TrolyObject {

	/** 
	 * Business Information 
	 */

	/** Full company name as registered legally in the country identified by `alpha2` and/or `state` */
	declare legal_name: string;
	/** Short compay name or "Brand name", or "doing business as", "trading as", etc.  */
	declare business_name: string;

	/** Short description for the company. Normally 2-3 sentences short paragraph */
	declare description: string;
	/** Legal company registration number in the country identified by `alpha2` or `state` */
	declare number: string;

	/** Country identifier, alpha2 as per ISO3166 */
	declare alpha2: string;
	/** Currency identifier, as a 3-letter code, uppercase */
	declare currency: string;

	declare lat: number
	declare lng: number
	declare timezone: string;
	declare locale: string;

	declare country: {}

	/** Company logo, as uploaded to CDN and with pre-transformations */
	declare logo_img?: CdnMountedImage // this is a mounted uploader and could be nil, or an object with urls and additional info
	/** Hero image is a larger company photo used as branding element */
	declare hero_img?: CdnMountedImage

	/** Contains a url to the company hero image, OR a seasonal/production specific system defined image */
	declare hero_img_url: string

	declare website_url: string
	declare website_url_qr: string
	declare website_qr_code?: CdnMountedImage;
	declare phone: string
	declare phone_alt: string
	declare mobile: string
	declare email: string
	_email: ValidatorFn[] = [Validators.email, Validators.maxLength(250)]

	/* Various SHORT URLs generated for / usable by this record */
	declare short_urls: { [name: string]: string|{} }
	
	declare feedback_url: string;
	declare feedback_url_qr: string;
	declare feedback_url_override: string

	declare vcard_url_qr: string;

	
	/**
	 * A list of generated documents for this product
	 */
	declare documents?: Document[];

	/**
	 * Addresses, Cards, Billing and Shipping
	 */
	declare cards: PaymentCard[]
	declare primary_card_id: uuid
	declare backup_card_id: uuid
	declare addresses: Address[]
	declare primary_address: Address;
	declare primary_address_id: uuid
	declare billing_address_id: uuid
	declare packaging: Carton[];
	declare orders_warehouse_id: uuid;
	declare shipments_warehouse_id: uuid;

	declare plan_cost_min_usage_fee: number
	declare plan_cost_max_usage_fee: number
	declare plan_cost_usage_threshold: number
	declare plan_cost_support_incident_fee_included: number
	declare plan_cost_support_incident_fee_over: number
	
	declare term_renewal_date: Date
	declare term_renewal_plan: 'starter' | 'pro' | 'grow'
	declare term_precommitment_payments?: number
	declare term_renew_to_precommitment_payments?: number

	/** A list of partners */
	declare affiliated_companies: Company[]
	declare billing_period?: BillingPeriod;
	declare platform_membership?: Membership;
	declare platform_company?: Company;
	declare platform_customer?: Customer;
	declare platform_plans?: Club[];

	declare is_locked_at: Date;
	declare is_test_mode: boolean;
	declare is_beta_unlocked: boolean

	declare is_integration_unlocked: boolean;
	declare is_integration_premium_unlocked: boolean;

	declare unlocked_premium_at: Date;
	declare unlocked_premium_by_id: uuid;

	declare last_invoiced_at: Date;
	declare last_charge_attempted_by_id: uuid


	/**
	 * General Account Characteristics
	 */
	declare annual_revenue: string
	declare primary_variety: string
	declare problem_at_signup: string

	/** ID of the associated GUEST customer */
	declare guest_customer_id: uuid;
	declare invite_code: string;
	declare invite_qr_code?: CdnMountedImage;
	declare feedback_qr_code?: CdnMountedImage;

	declare invites_recorded: ForwardLedger[];
	declare account_adjustments: ForwardLedger[];
	declare invites_available: number;
	declare invited_by_code: string;
	declare invited_by_business_name: number;

	declare invites_pending: [];
	declare invites_sent: [];

	declare payment_last_payment_date:Date;
	declare payment_last_payment_status:string;
	declare payment_total_payments_made:number;

	/** 
	 * Company Virtual Attributes 
	 */

	declare analytics_google_analytics_id: string
	declare analytics_google_tag_manager_id: string
	declare analytics_meta_pixel_id: string

	declare merchant_linkedin_url: string
	declare merchant_pinterest_url: string
	declare merchant_facebook_url: string
	declare merchant_twitter_url: string
	declare merchant_instagram_url: string
	declare merchant_tiktok_url: string
	declare merchant_youtube_url: string
	declare merchant_reddit_url: string
	declare merchant_snapchat_url: string
	declare merchant_googlemaps_url: string
	declare merchant_googleplace_name: string
	declare merchant_liquor_licence_no: string
	declare merchant_privacy_policy_url: string
	declare merchant_terms_and_conditions_url: string
	declare merchant_volume_pricing_allows_mixed_product:boolean;
	declare public_offer_external_reviews:string;
	declare public_capture_feedback_as:'5-stars'|'simple'|'emojis';
	declare public_material_forms_appearance:MatFormFieldAppearance;

	declare merchant_yelp_url:string; 
	declare merchant_tripadvisor_url:string;
	
	declare refund_order_limit: number;
	declare refund_timeframe: number;
	declare refund_lock_timeframe: number;
	declare refund_daily_limit: number;
	
	declare payment_scheduling: string
	/** Currency in which this company is transacting */
	declare payment_currency: string
	declare payments_payout_notifications: string
	declare payment_preferred_gateway: uuid
	declare payment_club_gateway: uuid
	
	declare strategy_dtc_ecommerce: number
	declare strategy_dtc_onpremise: number
	declare strategy_dtc_subscription_sales: number
	declare strategy_dtc_events: number
	declare strategy_dtc_influencer: number
	declare strategy_dtc_packages: number
	declare strategy_dtc_third_party: number
	declare strategy_dtc_auctions: number
	declare strategy_dtc_bulk: number
	declare strategy_retail_specialty: number
	declare strategy_retail_small: number
	declare strategy_retail_large: number
	declare strategy_retail_restaurants: number
	declare strategy_retail_restaurants_luxury: number
	declare strategy_trade_auctions: number
	declare strategy_trade_bulk: number
	declare strategy_trade_distributors: number
	declare strategy_trade_contracts: number
	declare strategy_trade_association: number
	declare strategy_export: number
	declare strategy_national: number
	
	declare shipping_carrier_pref: string
	declare shipping_service_pref: string
	declare shipping_signature_pref: string
	declare shipping_insurance_pref: string
	declare shipping_policy_url:string
	declare shipping_allow_soft_products:boolean
	
	declare tax_inclusive: boolean
	declare preferred_paper: boolean

	declare track_source: string
	declare track_partner: string
	declare track_channel: string
	declare track_campaign: string

	/**
	 * Troly Branding Addon
	 */
	declare merchant_marketing_messages: string[]
	declare messaging_email_signature: string
	declare messaging_dm_signature: string
	
	declare messaging_email_template_url: string

	declare messaging_process_sending_emails: boolean;
	declare messaging_process_sending_sms: boolean;
	declare messaging_process_scheduling_calls: boolean;
	declare branding_colour_primary: string;
	declare branding_colour_accent: string;
	declare branding_colour_text: string;
	declare branding_colour_bg: string;
	declare branding_media_contact_name: string;
	declare branding_media_contact_email: string
	declare branding_media_contact_phone: string;
	declare branding_media_kit_url: string;

	/**
	 * Troly Referrals Addon
	 */
	declare rewardpoints_offer_as_currency:boolean;
	declare rewardpoints_direct_purchase_amount: number;
	declare rewardpoints_direct_purchase_unit: string;
	declare rewardpoints_direct_purchase_expiry: number;
	declare rewardpoints_affiliate_purchase_amount: number;
	declare rewardpoints_affiliate_purchase_unit: string;
	declare rewardpoints_affiliate_purchase_expiry: number;
	declare rewardpoints_threshold_level_1: number;
	declare rewardpoints_threshold_level_2: number;
	declare rewardpoints_threshold_level_3: number;

	declare rewards_signup_url_qr: string;
	declare rewards_signup_url: string;
	declare rewards_signup_url_override: string;

	/**
	 * Troly Memberships Addon
	 */
	declare clubs_signup_url: string;
	declare clubs_signup_url_override: string;

	/**
	 * Model Relationships
	 */
	declare shipping_rules?: ShippingRule[];
	declare integrations?: Integration[]; // not having a default value allows detecting the data was not loaded..
	declare warehouses?: Warehouse[];
	declare clubs?: Club[];
	declare tags?: Tag[];
	declare tasks?: Task[];
	declare opportunities?: Task[];
	declare company_tags?: CompanyTag[];
	declare users?: User[];
	declare company_users?: CompanyUser[];

	declare templates?: CompanyTemplate[];


	/**
	 * The overall sales stats for the whole company, across all memberships types, all warehouses, all integrations, etc. Should be 18 months max
	 */
	declare orders_stats: SalesStat[];
	declare orders_stat: SalesStat;
	declare warehouses_stats: StockStat[]
	declare warehouses_stat: StockStat
	declare customers_stats: DemographicStat[]
	declare customers_stat: DemographicStat
	declare integrations_stats: SystemStat[]
	declare integrations_stat: SystemStat[]

	declare stat: PipelineStat;
	declare stats: PipelineStat[];

	/**
	 * Contains products associated with this Company. In most cases, we handle products through the product Service (which maps it's own endpoint)
	 * however in the case of signup/onboarding, we leverage the ability for the signup/:id/company endpoint to save and return a company object WITH products.
	 * If this becomes a hassle or overtly inconsistent -- we should consider injecting the product service in the signup service and handle products through that.
	 */
	declare products: Product[];


	/** 
	 * DEMO DATA Management
	 */
	_orders_statsDefault: SalesStat[] = [
		new SalesStat({ starts_at: "2023-04-01T00:00:00.000Z", collect_count: 22, collect_total_value: 3520, customers_sales_count: 34, customers_sales_value: 5928, members_sales: 13, members_sales_value: 2925, shipments_count: 43, shipments_total_value: 8271, orders_direct_value: 10943, orders_indirect_value: 28976, completed_avg_value: 99.22, orders_offline_value: 10943, orders_online_value: 78943 }),
		new SalesStat({ starts_at: "2023-01-01T00:00:00.000Z", collect_count: 20, collect_total_value: 4183, customers_sales_count: 24, customers_sales_value: 8374, members_sales: 18, members_sales_value: 4594, shipments_count: 22, shipments_total_value: 2773, orders_direct_value: 23765, orders_indirect_value: 30513, completed_avg_value: 101.69, orders_offline_value: 23765, orders_online_value: 81765 }),
		new SalesStat({ starts_at: "2022-10-01T00:00:00.000Z", collect_count: 18, collect_total_value: 3928, customers_sales_count: 21, customers_sales_value: 7882, members_sales: 26, members_sales_value: 6483, shipments_count: 29, shipments_total_value: 12881, orders_direct_value: 17645, orders_indirect_value: 26455, completed_avg_value: 92.83, orders_offline_value: 17645, orders_online_value: 58645 }),
		new SalesStat({ starts_at: "2022-07-01T00:00:00.000Z", collect_count: 29, collect_total_value: 5629, customers_sales_count: 46, customers_sales_value: 9283, members_sales: 21, members_sales_value: 9473, shipments_count: 38, shipments_total_value: 10272, orders_direct_value: 16302, orders_indirect_value: 32919, completed_avg_value: 81.85, orders_offline_value: 16302, orders_online_value: 78302 })
	]

	_customers_statDefault: DemographicStat = {
		ltm_collected_sales_value: 827,
		ltm_shipped_sales_value: 728,
		ltm_sales_percentile: 50,
		ltm_membership_sales_value: 1120,

		sales_units: 48,
		sales_value: 1820
	} as DemographicStat

	_statDefault = new PipelineStatSample(0, 1120, 25, 43);
	_statsDefault: PipelineStat[] = [
		new PipelineStatSample(1, 847, 5, 30),
		new PipelineStatSample(2, 724, 10, 25),
		new PipelineStatSample(3, 584, 2, 30),
		new PipelineStatSample(4, 442, 1, 10),
		new PipelineStatSample(5, 624, 3, 30),
		new PipelineStatSample(6, 847, 30, 35),
		new PipelineStatSample(7, 986, 12, 13),
		new PipelineStatSample(8, 1178, 5, 15),
		new PipelineStatSample(9, 1023, 10, 25),
	]

	_warehouses_statsDefault: StockStat[] = [
		new StockStatSample(1, 847, 5, 30),
		new StockStatSample(2, 724, 10, 25)
	]

	constructor(values: Object={}) {

		super('company', values);

		if (values) {
			let k = 'packaging'
			if (values[k] && values[k].length) {
				values[k].map((obj, i) => { this[k][i] = new Carton(obj) });
			}

			k = 'addresses'
			if (values[k] && values[k].length) {
				values[k].map((obj, i) => { this[k][i] = new Address(obj) });
			}

			k = 'users'
			if (values[k] && values[k].length) {
				values[k].map((obj, i) => { this[k][i] = new User(obj) });
			}

			k = 'clubs'
			if (values[k] && values[k].length) {
				values[k].map((obj, i) => { this[k][i] = new Club(obj) });
			}

			k = 'integrations'
			if (values[k] && values[k].length) {
				values[k].map((obj, i) => { 
					this[k][i] = buildCorrectIntegrationObject(obj)
				});
			}

			k = 'warehouses' // when cached/stored in localStorage and not retrieved+constructed, we need to make sure we have correct objects to work with
			if (values[k] && values[k].length) {
				values[k].map((obj,i) => { this[k][i] = new Warehouse(obj) });
			}

			k = 'opportunities' // when cached/stored in localStorage and not retrieved+constructed, we need to make sure we have correct objects to work with
			if (values[k] && values[k].length) {
				values[k].map((obj,i) => { this[k][i] = new Task(obj) });
			}

			k = 'products' // when cached/stored in localStorage and not retrieved+constructed, we need to make sure we have correct objects to work with
			if (values[k] && values[k].length) {
				values[k].map((obj,i) => { this[k][i] = new Product(obj) });
			}

			k = 'documents'
			if (values[k] && values[k].length && !(values[k][0] instanceof Document)) {
				values[k].map((obj, i) => { this[k][i] = new Document(obj) });
			}

			k = 'templates' // when cached/stored in localStorage and not retrieved+constructed, we need to make sure we have correct objects to work with
			if (values[k] && values[k].length) {
				values[k].map((obj,i) => { this[k][i] = new CompanyTemplate(obj) });
			}

			k = 'shipping_rules' // when cached/stored in localStorage and not retrieved+constructed, we need to make sure we have correct objects to work with
			if (values[k] && values[k].length) {
				values[k].map((obj,i) => { this[k][i] = new ShippingRule(obj) });
			}

			k = 'affiliated_companies' 
			if (values[k] && values[k].length) {
				values[k].map((obj,i) => { this[k][i] = new Company(obj) });
			}



			k='billing_period'
			if (values[k]) { this[k] = new BillingPeriod(values[k]); }

			k='primary_address'
			if (values[k]) {  this[k] = new Address(values[k]); }

			k='platform_membership'
			if (values[k]) { this[k] = new Membership(values[k]); }
			
			k='platform_company'
			if (values[k]) { this[k] = new Company(values[k]); }
			
			k='platform_customer'
			if (values[k]) { this[k] = new Customer(values[k]); }

			k='platform_plans'
			if (values[k] && values[k].length) {
				values[k].map((obj,i) => { this[k][i] = new Club(obj) });
			}

			k='account_adjustments'
			if (values[k] && values[k].length) {
				values[k].map((obj,i) => { this[k][i] = new ForwardLedger(obj) });
			}

			k='stat'
			if (values[k]) { this[k] = new PipelineStat(values[k]); }
		}
	}

	public toString(): string {
		return this.business_name ? this.business_name : this.legal_name;
	}

	public get initials(): string {
		return this.business_name?.toUpperCase().split(" ").map(word => word[0]).join("");
	}
	
	/**
	 * Convenience method to locate a User in the list of CompanyUsers loaded in `.users`
	 * @param id 
	 * @returns 
	 */
	public user(id:uuid): User | null {
		return this.users?.find(_ => _.id == id) || null
	}

	public get confirmedUsers(): User[] {
		return this.users?.filter(_ => _.current_sign_in_ip) || []; // any users who has never signed in is excluded -- this include the connected platforms too.
	}

	/**
	 * Used to merge together the current users active with this company, along with any old user that were involved with a record (eg. order processing)
	 * @param previous: User[] array of users attached to a record 
	 * @returns 
	 */
	public currentOrPreviousUsers(previous:User[]) {
		return (this.users || []).concat(previous)
	}

	/**
	 * 
	 * @param id 
	 * @returns 
	 */
	public club(id:uuid): Club | null {
		return this.clubs?.find(_ => _.id == id)
	}

	public get club_ids(): uuid[] { return this.clubs?.map(_ => _.id) || [] }

	public getClubSegments(club_id?: uuid): IClubSegment[] {
		if (this.club(club_id)) {
			return this.club(club_id).segments || []
		}
		return []
	}

	public template(id:uuid|string, channel:string='email'): CompanyTemplate {
		if (id.match(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)) {
			return this.templates?.find(_ => _.id == id) || null
		} else {
			return this.templates?.find(_ => _.name == id && _.channel == channel) || null
		}
	}

	/**
	 * 
	 * @param provider 
	 * @returns 
	 */
	public addonInstalled(provider_or_id: string): Integration | null {
		return this.addonsInstalled([provider_or_id])[0] || null;
	}

	public canSendSMS(): boolean { 
		// arch: later we should be asking the API for integrations with a certain 'capability' (see Integration Interfaces) but for now, we just hard code those integrations which support SMS messaging and whether they are enabled.
		const builtInEnabled = this.addonInstalled('TrolyMerchant')?.params['process_sending_sms'] != 'no' || false
		const gatewayEnabled = this.addonsInstalled().filter(_ => { ['Twillio'].includes(_.provider)}).length > 0
		return builtInEnabled || gatewayEnabled; 
	}
	public canSendDM(): boolean { return false; }

	get geo_coords_qr_code(): Document { return this.getDocumentByIdent('geo_coords_qr_code') }
	get vcard_qr_code(): Document { return this.getDocumentByIdent('vcard_qr_code') }
	get shared_wifi_qr_code(): Document { return this.getDocumentByIdent('shared_wifi_qr_code') }

	get rewards_doc(): Document { return this.getDocumentByIdent('rewards_doc') }
	get feedback_doc(): Document { return this.getDocumentByIdent('feedback_doc') }
	get clubs_signup_doc(): Document { return this.getDocumentByIdent('clubs_signup_doc') }

	get rewards_signup_qr_code(): Document { return this.getDocumentByIdent('rewards_signup_qr_code') }
	get clubs_signup_qr_code(): Document { return this.getDocumentByIdent('clubs_signup_qr_code') }

	protected getDocumentByIdent(ident:string): Document { return this.documents?.find(_ => _.ident == ident); }

	/**
	 * 
	 * @param providers 
	 * @returns 
	 */
	public addonsInstalled(providers_or_ids?: string[]): Integration[] {
		return this.integrations?.filter(_ => (!providers_or_ids || providers_or_ids.length == 0 || providers_or_ids.includes(_.provider) || providers_or_ids.includes(_.id?.toString())) && _.id && _.status && _.status != 'uninstalled').sort((a,b) => a.name.localeCompare > b.name.localeCompare ? -1 : 1) || [];
	}
	
	/**
	 * 
	 * @param provider_or_id 
	 * @returns 
	 */
	public addon(provider_or_id: string): Integration | null {
		let addons = this.addonsInstalled([provider_or_id]);
		return addons.length > 0 ? addons[0] : null;
	}

	public getIcon(thumbnail:boolean=true): string {
		if (this.logo_img?.url) {
			return thumbnail ? this.logo_img.thumbnail.url : this.logo_img.url;
		}
	}

	/**
	 * 
	 * @param provider 
	 * @returns 
	 */
	public warehouse(id): Warehouse | null {
		return this.warehouses?.find(_ => _.id == id) || null;
	}

	/**
	 * 
	 * @param provider 
	 * @returns 
	 */
	public addonStatus(provider_or_id: string): string {
		let addons = this.addonsInstalled([provider_or_id]);
		return addons.length > 0 ? addons[0].status : 'uninstalled';
	}

	public addonsByInterface(interface_implemented:string, excludeProviders:string[]=[]): Integration[] {
		return this.addonsInstalled().filter(o => o.interfaces(interface_implemented) && (excludeProviders.length == 0 || !excludeProviders.includes(o.provider))) || [];
	}
	/**
	 * 
	 */
	public shippingIntegrations(excludeProviders:string[]=[]): ShippingIntegration[] {
		return this.addonsByInterface('Interfaces::Fulfilment::Labelling', excludeProviders) as ShippingIntegration[];
	}

	/**
	 * 
	 */
	public ecommerceIntegrations(excludeProviders:string[]=[]): Integration[] {
		return this.addonsByInterface('Interfaces::Ecommerce::OnlineSales', excludeProviders);
	}

	/**
	 * 
	 */
	public paymentIntegrations(excludeProviders:string[]=[]): PaymentIntegration[] {
		return this.addonsByInterface('Interfaces::Revenue::Payments', excludeProviders) as PaymentIntegration[];
	}

	/**
	 * 
	 */
	public billingIntegrations(excludeProviders?:string[]): Integration[] {
		const providersWithBilling = ['A3C2','Outshinery','PremiumSupport'];
		if (excludeProviders) { excludeProviders.push('TrolyBilling') } else { excludeProviders = ['TrolyBilling'] };
		return this.addonsInstalled().filter(o => providersWithBilling.includes(o.provider) && !excludeProviders.includes(o.provider) ) || [];
	}

	/**
	 * Returns a list of shipping services for the given provider, or all if none given.
	 * @param provider
	 * @returns
	 */
	public getShippingServicesForProvider(provider:string): string[] {
		return this.integrations?.find(_ => _.provider == provider)?.data['service_types'] || [];
	}

	assign_stats(value: DemographicStat): boolean {
		if (value.sales_value > 0) {
			this.customers_stat = value;
		} else {
			this.customers_stat = this._customers_statDefault;
		}
		return this.customers_stat != this._customers_statDefault;
	}

	toLocalStorage(action?: string): Company {
		action = action || 'logout';

		if (action == 'login') {
			//return this.cleanObjectProperties(new Company(this)) as Company;
			// !!! We have a problem with cleaning properties when storing in local storage, due to how variables are passed as references.
			// !!! this means the return object is also cleaned up, and the service._next object also is.
			// !!! ultimately, while a user is logged on, all underscore attributes should be maintained in order to have a consistent object state (eg isStale is maintained.)
			// !!! this was more obvious with company.integrations.translations.fields which uses _ to separate sections.
			return this;
		} else {
			return new Company({
				id: this.id,
				business_name: this.business_name,
				logo_img: this.logo_img,
				hero_img_url: this.hero_img_url,
				branding_colour_primary: this.branding_colour_primary
			});
		}
	}

	get referralDiscount(): number {
		const discounts = {
			AU: 30,
			CA: 30,
			NZ: 30,
			US: 20,
			IT: 20,
			ES: 20,
			ZA: 350,
		}
		return discounts[this.alpha2] * 12;
	}

	public get current_plan(): Club | null {
		if (this.platform_plans?.length > 0 && this.billing_period) {
			return this.platform_plans.find(_ => _.id == this.billing_period.starts_at_club_id)
		}
		if (this.platform_plans?.length > 0 && this.platform_membership) {
			return this.platform_plans.find(_ => _.id == this.platform_membership.club_id)
		}
	}

	public get next_plan(): Club | null {
		if (this.platform_plans?.length > 0 && this.billing_period) {
			return this.platform_plans.find(_ => _.id == this.billing_period.ends_at_club_id)
		}
	}

	public get plannedUpgrade():number {
		if (this.billing_period?.starts_at_club_id == this.billing_period?.ends_at_club_id) { return 0; }
		if (this.next_plan.short_name == 'grow' || (this.next_plan.short_name == 'pro' && this.current_plan.short_name == 'starter')) { return 1; }
		if (this.next_plan.short_name == 'starter' || (this.next_plan.short_name == 'pro' && this.current_plan.short_name == 'grow')) { return -1; }
	}


	public isPickupWarehouse(location: Warehouse): boolean {
		return this.orders_warehouse_id && this.orders_warehouse_id == location.id
	}
	public isShippingWarehouse(location: Warehouse): boolean {
		return this.shipments_warehouse_id && this.shipments_warehouse_id == location.id
	}


	public get partnerName(): string | null {
		if (Object.keys(this.affiliated_companies || {}).length == 1) {
			return Object.keys(this.affiliated_companies || {})[0]
		}
	}
	public get partnerLogoUrl():string {
		if (Object.keys(this.affiliated_companies || {}).length == 1) {
			const partner = Object.keys(this.affiliated_companies)[0]
			switch (partner) {
				case 'Outshinery':
					//return 'https://assets-global.website-files.com/61fa355c7ae67c2c0d81a4ec/61fd08492e9386eab9277cfd_Outshinery-logo-original-inline.svg';
					return 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSck9OyI9yhryihC4ea-JYu5rwEOgBw1cL6l_ScoEvYK289OVLjGCHODZBWeXf0wqqK-Hg&usqp=CAU';
				case 'Vivino':
					return 'https://images.vivino.com/web/press/logo_white_on_red.png';
			}
		}
		return 'https://res.cloudinary.com/troly/image/upload/a_2,w_100,h_100/cdn/brand/3.0/troly-logo-circle-transparent-bg.png'
	}

	public hasCurrentAdjustmentByCode(code:string): Date | null {
		const now = new Date()
		return this.account_adjustments.find(_ => _.code == code && _.starts_at <= now && now <= _.ends_at)?.ends_at
	}
}

export class ForwardLedger extends TrolyObject { 
	
	declare id: uuid;
	declare code:string
	declare description:string
	
	declare starts_at:Date 
	declare ends_at:Date
				
	declare funds_monthly:number 
	declare funds_total:number

	declare fees_monthly:number
	declare fees_yearly:number

	declare status: string;

	constructor(values?:{}) {
		super('forward_ledger', values);
	}
}

export interface IReferral {
	id: uuid
	fname: string
	email: string
	mobile: string
}

export interface ILedgerSummary {

	fees: number,
	funds: number,
	transactions_processed: number,
	records_affected: number,
	providers: {
		[provider:string]: {
			fees: number,
			funds: number,
			transactions_processed: number,
			records_affected: number,
			ledgers?: IMiniLedger[]
		}
	}

}

export class BillingPeriod extends TrolyObject {
	
	declare starts_at:Date
	declare starts_at_club_id:uuid
	declare ends_at:Date
	declare ends_at_club_id:uuid

	declare previous_bp_id:uuid
	declare next_bp_id:uuid
	
	declare status: string;
	declare invoice_id: uuid;
	
	declare integrations?: Integration[]

	declare db_region:'eu-fra'|'ca-tor'|'us-nyc'|'au-syd';

	declare membership_id?: uuid;

	declare ledgers_summary: {
		[category:string]: ILedgerSummary
	};
	
	constructor(values?:{}) {
		super('billing_period', values);

		if (values) {
			let k = 'integrations'
			if (values[k] && values[k].length) {
				values[k].map((obj, i) => { this[k][i] = new Integration(obj) });
			}
		}
	}

	public freeIntegrations(): Integration[] {
		return this.integrations?.filter(_ => !_['price'] || parseInt(_['price']) == 0) || []
	}
	public invitesRewards(): IMiniLedger[] {
		return this.ledgers_summary['troly_adjustment']?.providers['TrolyRewardPoints']?.ledgers || []
	}
}