import { Component, Input, SimpleChanges, inject } from '@angular/core';
import { TrolyComponent } from 'src/app/core/components/troly.component';
import { ITrolyLoadingStatus } from 'src/app/core/models/form_objects';
import { Address } from 'src/app/core/models/troly/address.model';
import { Company } from 'src/app/core/models/troly/company.model';
import { Customer } from 'src/app/core/models/troly/customer.model';
import { uuid } from 'src/app/core/models/utils.models';
import { CompanyService } from 'src/app/core/services/troly/company.service';
import { CustomerService } from 'src/app/core/services/troly/customer.service';
import { TrolyService } from 'src/app/core/services/troly/troly.service';
import { SweetAlertOptions } from 'sweetalert2';

@Component({
	selector: 'addresses-on-file',
	templateUrl: './addresses-on-file.component.html',
	styleUrls: ['./addresses-on-file.component.scss'],
})
export class AddressesOnFileComponent extends TrolyComponent {

	@Input() mode: 'select-to-pay' | 'maintain' = 'maintain';
	
	@Input() company_id!: uuid;
	@Input() customer_id?: uuid;
	@Input() order_id?: uuid;

	@Input() showAddAddress:boolean=false
	@Input() showLastUsed:boolean=true
	@Input() showHours:boolean=false

	@Input() addresses:Address[]=[];
	@Input() primaryAddressId:uuid;
	@Input() secondaryAddressId:uuid;
	@Input() otherAddressId:uuid;

	override selectedRecord:Address = new Address();

	/**
	 * Additional services used this this component. 
	 * ? Keeping in mind CompanyService and UserService are already available in TrolyComponent. 
	 */
	protected customerService: CustomerService = inject(CustomerService);


	override loading:{records:ITrolyLoadingStatus, record:ITrolyLoadingStatus,troly:ITrolyLoadingStatus}={records:undefined,record:undefined,troly:undefined};

	
	public operations:{[key:string]:string}={};
	public setPreferredAddress(addr:Address, attr:string) {
		this.operations[addr.id.toString()] = "SAVING"

		let payload:Company|Customer;
		let service:CompanyService|CustomerService;
		if (this.customer_id) {
			payload = new Customer({id:this.customer_id})
			service = this.customerService
		} else {
			payload = new Company({id:this.selectedCompany.id})
			service = this.companyService
		}
		payload[attr] = addr.id;

		(service as TrolyService<Company | Customer>).save(payload).subscribe((_) => {
			this.operations = {}
			this.operations[addr.id.toString()] = "SAVED"
			this.primaryAddressId = _['delivery_address_id']
			this.secondaryAddressId = _['billing_address_id']
		})
	}

	public isPrimaryAddress(addr:Address):boolean {
		return this.primaryAddressId && addr.id == this.primaryAddressId && this.customer_id == null
	}
	public isDeliveryAddress(addr:Address):boolean {
		return this.primaryAddressId && addr.id == this.primaryAddressId && this.customer_id != null
	}
	public isBillingAddress(addr:Address):boolean {
		return this.secondaryAddressId && addr.id == this.secondaryAddressId
	}

	protected receiveInputParams(changes:SimpleChanges):void {
		if (changes.addresses && changes.addresses.currentValue) {
			this.addresses = changes.addresses.currentValue.map(_ => new Address(_)).sort( (c1,c2)=> c1.before(c2, false) );
			this.addresses = this.addresses || [];
			this.markAsLoaded('records');
		}

		if (changes.record) {
			this.selectedRecord = changes.record.currentValue instanceof Address ? changes.record.currentValue : new Address(changes.record.currentValue);
		}
		
		super.receiveInputParams(changes);
	}

	public confirmRemove(addr:Address, modalTitle, modalBody) {

		let options: SweetAlertOptions = this.alertService.alertOptions('warning', 'continue', 'cancel');
		options.title = modalTitle;
		options.html = modalBody;
		//options.footer = this.crispHelpdeskLink('Learn about scheduled membership orders','hkzlue');

		this.alertService.confirm(options).then((_) => {
			this.removeAddress(addr)
		})
	}

	public publishAddress(addr:Address) {
		if (this.customer_id && this.customerService.record$.value.id == this.customer_id) {
			let customer = this.customerService.record$.value
			this.pushOrUpdateRecord(customer.addresses, addr)
			this.customerService.record$.next(customer)
		} else if (this.selectedCompany.id == this.company_id) {
			let company = this.companyService.record$.value
			this.pushOrUpdateRecord(company.addresses, addr)
			this.companyService.record$.next(company)
		}
	}

	public removeAddress(addr:Address) {
		if (this.customer_id) {
			this.remove(this.customerService, addr.id, this.customer_id)
		} else {
			this.remove(this.companyService, addr.id, this.selectedCompany.id)
		}
	}

	protected remove(service:CustomerService|CompanyService, address_id:uuid, record_id:uuid): void {

		service.removeAddress(address_id, <any>service.make({id:record_id})).subscribe({
			next: (_: Address) => {
				this.removeRecord(this.addresses, _)
				this.service.snack('Address deleted', 'success');
			}, 
			error: (_) => {
				this.service.snack('Address could not be removed', 'error');
			}
		})
	}
}