import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, EMPTY, Observable } from 'rxjs';

@Injectable()
export class TrolyInterceptor implements HttpInterceptor {

	protected route: ActivatedRoute = inject(ActivatedRoute);
	protected router: Router = inject(Router);

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

		//console.log('TrolyInterceptor', req);

		// Handle the request and catch errors
		return next.handle(req).pipe(
			catchError(error => {

				
				if (isTrolyApiValidationError(error)) {

					// arch: whether we should handle errors via TrolyAPI.catchErrors or here is debatable;
					// arch: ultimately this interceptor sees ALL http requests (including non-TrolyAPI requests)
					// arch: so we need to be careful; However looking at changes in Angular 18 re: interceptors, 
					// arch: it's pretty clear Angular is moving towards Authentication/ErrorHandling/Logging/DataTransformation via interceptors
				
					const errorKeys = Object.keys(error.error)
					console.warn('API Validation Error:', error.error[errorKeys[0]].errors);
					throw new TrolyValidationError({
						error:error.error[errorKeys[0]], 
						status:4001, statusText:`Bad Request: Validation failed for ${errorKeys[0]} (${errorKeys.length})`, 
						url:error.url
					});

				} else if (error instanceof HttpErrorResponse) {

					// Handle HTTP errors
					if (error.status === 500) {

					} else if (error.status === 401 || error.status === 403) {
						
						if (!this.router.url.match('/start')) { 
							this.router.navigate(['/start/now'], { queryParams: { return: this.router.url }, fragment: this.route.snapshot.fragment || 'expired' });
						} 
						return EMPTY
					
					} else if (error.status === 404) {
						console.error('Invalid URL (Not Found)', error.url);
						
					} else if (error.status === 422) {
						// You might trigger a re-authentication flow or redirect the user here
						debugger;
						// Specific handling for CSRF errors
						console.error('CSRF error:', error);
						//return EMPTY
					} else if (error.status >= 300 && error.status < 400) {
						// Handle redirect responses
						console.error('Redirect error:', error);

					} else {
						debugger;
						// Handle other HTTP error codes
						console.error('HTTP error:', error);
					}
				} else {
					// Handle non-HTTP errors
					console.error('An error occurred:', error);
				}

				// Re-throw the error to propagate it further
				throw error;
				
			})
		);
	}
}


/**
 * Checks if the given error is a 400 Bad Request error from the server.
 *
 * @param error The error object to check.
 * @returns True if the error is an HttpErrorResponse with status code 400 (Bad Request), false otherwise.
 */
export function isTrolyApiValidationError(error: any) {
	return (error instanceof HttpErrorResponse && error.status == 400 && Object.keys(error.error || {}).length > 0);
}

export class TrolyValidationError extends HttpErrorResponse {

}