import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { DomSanitizer, SafeStyle } from "@angular/platform-browser";

@Component({
    selector: 'star-rating',
    templateUrl: './star-rating.component.html',
    styleUrls: ['./star-rating.component.scss'],
})
export class StarRatingComponent implements OnChanges {

    public stretch:number;
    public imageUrl:SafeStyle;
    public bgUrl:SafeStyle;

    @Input() stars:number;
    @Input() scale:number=5;
	 @Input() variation:number=0.5;
	 
	 @Input() changePermitted:boolean=false;

    @Input() status:string='enabled';
	 @Output() change:EventEmitter<number> = new EventEmitter<number>();
    
    constructor (protected sanitizer: DomSanitizer) {

    }
    ngOnChanges(changes: SimpleChanges): void {
		if(changes.stars) {
      	this.stretch = this.stars / this.scale * 100;
		}
    }

	 captureNewValue(event) {
		
		event.preventDefault();
		event.stopPropagation();

		if (this.changePermitted) {
			//console.log(event, event.target);
			//console.log(event.target.offsetWidth, event.offsetX);
			const scoreToScale = event.offsetX / event.target.offsetWidth * this.scale;
			const adjustedForVariation = Math.ceil(scoreToScale / this.variation) * this.variation

			this.change.emit(adjustedForVariation);
		}
	}
}