<table class="table table-striped table-hover">
	<thead><tr>
		<th style="width:30px"></th>
		<th>Address</th>
		<th style="width:40%" *ngIf="customer_id != null">Last Used</th>
		<th style="width:40%" *ngIf="customer_id == null">Hours</th>
		<th><i *ngIf="showAddAddress" (click)="openModal(addAddress)" class="bx bx-plus-circle font-size-16 text-primary cursor-pointer"></i></th>
	</tr></thead>
	<tbody *ngIf="addresses?.length > 0">
		<tr *ngFor="let address of addresses | sortBy">
			<td class="pe-0 text-center">

				<i *ngIf="isPrimaryAddress(address)" placement="right"
					ngbTooltip="This is where customers visit you"
					class="bx bxs-store text-dark opacity-25 d-block mb-1 font-size-15"></i>

				<i *ngIf="isDeliveryAddress(address)" placement="right"
					ngbTooltip="Preferred address for shipping"
					class="bx bxs-truck text-dark opacity-25 d-block mb-1 font-size-15"></i>

				<i *ngIf="isBillingAddress(address)" placement="right"
					ngbTooltip="Appears on invoices"
					class="bx bxs-dollar-circle text-dark opacity-25 d-block mb-1 font-size-14"></i>
					
			</td>
			<td>
				<h6>
					<a href (click)="!!openModal(addAddress, address)">{{ address.line1 }}</a>
					<span *ngIf="address.label" class="ms-2 badge bg-primary bg-soft text-primary align-top">
						<ng-container *ngVar="['home','work','holiday_home','office','partner','mailling','warehouse','other'].includes(address.label) as isPreset">
							<span *ngIf="isPreset">
								{{ 'Models.Address.Attributes.Label.' + address.label | translate }} 
								<i *ngIf="address.label == 'home'" class="bx bxs-home text-primary"></i>
								<i *ngIf="address.label == 'work'" class="bx bxs-buildings text-primary"></i>
							</span>
							<span *ngIf="!isPreset">{{ address.label }}</span>
						</ng-container>
					</span>
					<span class="ms-1 badge bg-warning bg-soft text-orange align-top" *ngIf="address.sync_warehouse">Stock</span>
					
					<header-loading-indicator class="ms-1" [status]="operations[address.id]"></header-loading-indicator>
				</h6>
				<p *ngIf="address.line2">{{ address.line2 }}</p>
				<p>{{ address.line3 + (address.line3 && address.line4 ? ', ':'') + address.line4 }}</p>
				<code *ngIf="address.delivery_instructions">{{ address.delivery_instructions }}</code>
			</td>
			<td style="width:40%" *ngIf="customer_id != null">
				<ng-container *ngIf="address.last_used_at">
					<p><a href (click)="!!toggleFromNow();" class="text-primary">
						<span *ngIf="!__fromNow">{{ moment(address.last_used_at).format(datetimeShort) }}</span>
						<span *ngIf="__fromNow">{{ moment(address.last_used_at).fromNow()  }}</span>
					</a></p>
					<p>Total sales of {{ address.total_order_value_inc | currency }}.</p>
					<p>{{ address.order_ids.length}} orders(s), {{ address.shipment_ids.length }} shipped.</p>
				</ng-container>
				<ng-container *ngIf="!address.last_used_at">
			  		<p>Never used</p>
				</ng-container>
			</td>
			<td style="width:40%" *ngIf="customer_id == null">
				N/A
			</td>
			<td class="text-center">
				<span ngbDropdown>
					 <button ngbDropdownToggle>
						  <i class="bx bx-dots-vertical-rounded"></i>
					 </button>
					 <div ngbDropdownMenu>
						<button ngbDropdownItem (click)="openModal(addAddress, address)">
							<i class="bx bx-pencil"></i>
							<span>Edit Address</span>
						</button>
						<div class="dropdown-divider"></div>
						<button ngbDropdownItem [disabled]="isDeliveryAddress(address)" (click)="setPreferredAddress(address,'delivery_address_id')">
							<i class="bx bxs-truck"></i>
							<span>Preferred for Deliveries</span>
						</button>
						<button ngbDropdownItem [disabled]="isBillingAddress(address)" (click)="setPreferredAddress(address,'billing_address_id')">
							<i class="bx bxs-dollar-circle"></i>
							<span>Use for Billing</span>
						</button>
						<div class="dropdown-divider"></div>
						<button ngbDropdownItem class="text-danger" [disabled]="isPrimaryAddress(address) || isBillingAddress(address) || isDeliveryAddress(address)"
							(click)="confirmRemove(address, 'Permanently delete this address?', 'Changes take effect immediately and information about this address is forever unrecoverable.')"
						>
							<i class="bx bx-trash-alt"></i>
							<span>Delete</span>
						</button>
					 </div>
				</span>
		  </td>
		</tr>
	</tbody>
	<caption class="p-4" *ngIf="!addresses || addresses.length == 0">
		<status-hint  
			 icon="bx-tachometer"
			 [loading]="false"
			 title=""
			 class="text-secondary"
			 size="small"
			 description="No addresses are are currently saved on file."
		>
	</status-hint>
  </caption>
</table>
<ng-template #addAddress>
	<create-address-modal [record]="selectedRecord" [customer_id]="customer_id" (recordSaved)="publishAddress($event)"></create-address-modal>
</ng-template>
