<div class="card {{ loading.record }}" [ngClass]="{ 'is-modal': bsCssClassPrefix == 'modal' }">
    
			<div class="{{ bsCssClassPrefix }}-header">
            <h4 class="{{ bsCssClassPrefix }}-title" *ngIf="!form.get('id')?.value">Create a new Customer</h4>
            <h4 class="{{ bsCssClassPrefix }}-title" *ngIf="form.get('id')?.value">Customer Details for {{ form.get('fname').value
                }}</h4>
            <button type="button" class="btn-close" [disabled]="form.seconds > 0"
                (click)="resolveModal('x')"></button>
        </div>
        <div class="{{ form.loading }} {{ bsCssClassPrefix }}-body">

			<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown)="submitOnEnter($event)" class="{{ form.cssClass }}">

            <form-status [formGroup]="form" successAction="redirect" (dismissCallback)="resolveModal($event)"
                [record_label]="form.get('email').value">

                <span #status code="NO_CONTACT">An email or phone is required to identify this contact.</span>
                <span #status code="ALREADY_EXISTS" action="redirect"><strong>Existing record</strong> found for
                    {{form.get('email').value}}.</span>
            </form-status>

            <div class="row">
                <div class="col-6">
                    <mat-form-field>
                        <mat-label>First Name</mat-label>
                        <input matInput formControlName="fname" type="text" />
                        <mat-error *ngIf="form.get('fname').errors?.required">Required to search for this person
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-6">
                    <mat-form-field>
                        <mat-label>Last Name</mat-label>
                        <input matInput formControlName="lname" type="text" />
                    </mat-form-field>
                </div>
                <div class="col-6">
                    <mat-form-field>
                        <mat-label>Email</mat-label>
                        <input matInput name="do-not-auto-complete-email" formControlName="email" type="email"
                            autocomplete="off" />
                        <mat-error>Must be a valid email address</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-6">
                    <mat-form-field>
                        <mat-label>Mobile</mat-label>
                        <input matInput formControlName="mobile" type="text" />
                        <mat-error>Digits, hyphens and spaces only</mat-error>
                    </mat-form-field>
                </div>
            </div>
			</form>
			<form [formGroup]="membershipForm">	
				<div class="row">
					<div class="col-6">
						<mat-form-field>
							<mat-label>Club</mat-label>
							<mat-select formControlName="club_id">
								<mat-option *ngFor="let club of selectedCompany.clubs" [value]="club.id">
									{{ club.short_name }}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
					
					<div class="col-6">
						<mat-form-field>
							<mat-label>Segment</mat-label>
							<mat-select formControlName="segment_id" [required]="membershipForm.get('club_id').value && (selectedCompany.club(membershipForm.get('club_id').value).segments.length > 0)">
								<ng-container *ngIf="membershipForm.get('club_id').value && selectedCompany.club(membershipForm.get('club_id').value).segments">
									<mat-option *ngFor="let segment of selectedCompany.club(membershipForm	.get('club_id').value).segments" [value]="segment.id">
										{{ segment.name }}
									</mat-option>
								</ng-container>
							</mat-select>
						</mat-form-field>
					</div>
				</div>	
			</form>
		</div>
		<div class="{{ bsCssClassPrefix }}-footer">
			<button type="submit" (click)="onSubmit()" class="btn btn-primary {{ form.loading }}"
					[disabled]="form.cannotSubmit">
					Save
			</button>
		</div>
</div>