import { Validators } from "@angular/forms";
import { mergeDeep } from "src/app/shared/utils";
import { TrolyObject } from "../troly_object";
import { uuid } from "../utils.models";

export class CompanyTemplate extends TrolyObject {

	override _trolyPropertyArray: {} = { params: Object }

	declare company_id: uuid
	declare name: string;
	declare channel: ITemplateChannel;

	declare status: 'required'|'enabled'|'disabled';

	declare subject?: string;
	declare body: string;
	_body = [Validators.required]

	declare translations: { [locale:string]: { subject?: string, body: string } };

	declare latest_version:string;
	declare current_version:string;

	/** last date at which this template was customised */
	declare customised_at: Date;

	constructor(values?: Object) {
		super('company_template', values);
	}

	public get alwaysEnabled(): boolean {
		// this list is maintained by hand and also is in the backend -- unlikely to change much and never based on user interactions, so no need to "ask" the api for this info
		return 'info_wishlist|info_customer_summary|info_customer_summary_trade|info_newsletter_unsubscribe|member_cancellation|staff_disputed_charge|custom|direct_campaign|pickup_bulk_draft|shipment_bulk_draft'.split('|').includes(this.name)
	}
	
	/**
	 * function to compare (and sort) objects
	 * @param b 
	 * @param asc 
	 * @returns 
	 */
	public localeCompare(b:CompanyTemplate, asc:boolean=true): number {
		const channelWeights = {sms:1, email:-1, call:0}
		if (asc) { 
			return this.sortWeight == b.sortWeight ? channelWeights[this.channel] : this.sortWeight - b.sortWeight
		}
		else { 
			return this.sortWeight == b.sortWeight ? channelWeights[this.channel] * -1 : b.sortWeight - this.sortWeight
		}
	}

	public get category(): string {


		const template_categories = {

			'direct_cart_abandonned':				'direct',
			'direct_customer_inactive':			'direct',
			'direct_high_value_customer':			'direct',
			'direct_campaign_anniversary':		'direct',
			'direct_purchase_anniversary':		'direct',
			'direct_pet_birthday':					'direct',
			
			'info_customer_summary':				'info',
			'info_customer_summary_trade':		'info',
			'direct_partner_birthday':				'direct',
			'direct_happy_anniversary':			'direct',

			'direct_customer_birthday':			'direct',
			'direct_campaign':						'direct',
			'direct_reward_program':				'direct',
			'direct_reward_reminder':				'direct',
				
			'info_bounced_email':					'info',
			'info_spamreport_email':						'info',
			'info_newsletter_subscribe':			'info',
			'info_newsletter_unsubscribe':		'info',
			'info_order_hold':						'info',
			'info_reward_threshold_1':				'info',
			'info_reward_threshold_2':				'info',
			'info_reward_threshold_3':				'info',
			'info_reward_threshold_3_details':	'info',
			'info_joint_account_confirmation':	'membership',
			
			'pickup_changed':					'pickup',
			'shipment_changed':				'shipment',
			'payment_gift_new':						'info',

			'info_wishlist':							'info',
			'info_stock_available':					'info',
			'info_reward_awarded':					'info',

			'drip_all_1':								'drip',
			'drip_all_2':								'drip',
			'drip_all_3':								'drip',
			'drip_all_4':								'drip',
			'drip_all_5':								'drip',
			'drip_all_6':								'drip',
			'drip_all_7':								'drip',
			'drip_all_8':								'drip',

			'member_invite':							'membership',
			'member_new':								'membership',
			'member_upgrade':							'membership',
			'member_downgrade':						'membership',
			'member_inactive':						'membership',
			'member_precancellation':				'membership',
			'member_cancellation':					'membership',
			'member_suspended':						'membership',
			'member_reinstated':						'membership',
			'member_renew':							'membership',
			'member_offer':							'membership',

			'payment_cc_expiry':						'payments',
      	'payment_cc_expired':					'payments',
			'payment_cc_missing':					'payments',
			'payment_declined':						'payments',
			'payment_refunded':						'payments',
			'payment_request':						'payments',
			'payment_disputed_charge':				'payments',
			'payment_accounts_payable':			'payments',
			'payment_successful':					'payments',
			
			'staff_orders_to_process':			'internal',
			'staff_stock_warning':					'internal',
			'staff_task_assigned':					'internal',
			'staff_financial_summary':				'internal',
			'staff_integration_degraded':			'internal',
			'staff_team_summary':					'internal',
			'staff_management_summary':			'internal',

			'shipment_bulk_draft':					'membership_orders',
			'shipment_bulk_confirmed':				'membership_orders',
			'pickup_bulk_draft':						'membership_orders',
			'pickup_bulk_confirmed':				'membership_orders',
			
			'pickup_new':								'pickup',
			'pickup_new_member':						'pickup',
			'pickup_packed':							'pickup',
			'pickup_labelled':						'pickup',
			'pickup_completed':						'pickup',
			'pickup_feedback':						'pickup',
			
			'shipment_new':							'shipment',
			'shipment_new_member':					'shipment',
			'shipment_labelled':						'shipment',
			'shipment_labelled_no_tracking':		'shipment',
			'shipment_packed':						'shipment',
			'shipment_in_transit':					'shipment',
			'shipment_delivered':					'shipment',
			'shipment_delivered_no_tracking':	'shipment',
			'shipment_dispatch':						'shipment',
			'shipment_dispatch_no_tracking':		'shipment',
			'shipment_delayed':						'shipment',
			'shipment_out_for_delivery':			'shipment',
			'shipment_feedback':						'shipment',

		}

		return template_categories[this.name] || 'other'
	}
	
	public get replacementVariables(): {[name:string]:string[]} {

		const customer_tags = 	['user:fname', 'customer:greeting', 'customer:phone', 'customer:greeting_full', 'customer:phone_alt', 'customer:mobile', 'customer:fname_with_partner','customer:greeting_with_partner', 'customer:fname', 'customer:lname', 'customer:email', 'customer:card_1line', 'customer:delivery_address', 'customer:delivery_suburb', 'customer:delivery_instructions', 'customer:login_url', 'customer:password_reset_url', 'customer:preferences_url', 'customer:meaningful_product','customer:all_phones','customer:rewards_tracking_url','customer:meaningful_product_url','customer:meaningful_product','customer:greeting_full','customer:preferences_url','customer:login_url','customer:password_reset_url','customer:reward_points_balance', 'customer:affiliate_code', 'customer:rewards_short_url'];
		
		const membership_tags = ['membership:name', 'membership:next_purchase_date', 'membership:number', 'membership:suspend_until_date', 'membership:card_url', 'membership:benefits','membership:benefits_list','membership:length_months','membership:purchases','membership:card_url']
		
		const payment_tags = 	['payment:confirmation_code', 'card:brand', 'card:last4', 'card:expiry_days', 'payment:created_at', 'payment:amount', 'payment:status_details', 'payment:receipt_url',]
		
		const order_tags = 		['user:fname', 'order:number', 'order:value', 'order:product_sample', 'order:full_content', 'order:billing_address', 'order:billing_suburb', 'order:tracking_image_url', 'order:tracking_image', 'order:tracking_url', 'order:planned_completion_date', 'order:planned_processing_date', 'warehouse:region', 'warehouse:business_name', 'order:checkout_url','order:completed_at','order:delay_until_date','order:feedback_url','order:number','order:planned_completion_date','order:planned_processing_date','order:product_sample','order:products_table','order:reward_points_earned','order:tracking_image','order:tracking_url','order:personal_message','order:packaging_notes','order:products_with_qty','order:products_no_qty','order:products_table'];

		const pickup_tags = 		['warehouse:map_url', 'warehouse:address_block', 'warehouse:customer_access_instructions'];
		
		const shipment_tags = 	['shipment:selected_carrier','shipment:tracking_codes','shipment:last_carrier_update', 'shipment:address:1line', 'shipment:address:line1', 'shipment:address:line2', 'shipment:address:suburb','shipment:address:name', 'shipment:address:postcode', 'shipment:delivery_address_block','shipment:address:instructions','shipment:planned_delivery_window','shipment:delivered_at','shipment:delivery_instructions','shipment:delivery_name','shipment:delivery_region','shipment:delivery_suburb','shipment:selected_carrier'];

		const company_tags = 	['company:business_name', 'company:legal_name', 'company:logo_img_url', 'company:phone', 'company:phone_alt', 'company:mobile', 'company:email', 'company:all_phones', 'company:address_1line','company:address_map_url','company:address:suburb','company:website_url','company:feedback_url','company:merchant_marketing_message','company:branding_colour_primary','company:clubs_signup_url','company:random_email_signoff', 'company:rewardpoints_affiliate_purchase_amount','company:rewardpoints_direct_purchase_amount','company:merchant_socialchannels_list', 'company:values_list', 'company:merchant_socialchannels_images','user:fname'];

		const warehouse_tags = [ 'warehouse:address_block', 'warehouse:manager_name', 'warehouse:business_name', 'warehouse:map_url' ]

		const template_only_tags = {
			direct_customer_birthday: { birthday: ['customer:days_to_birthday','customer:birth_date'] },
			direct_pet_birthday: { birthday: ['customer:pet_name', 'customer:pet_birthday', 'customer:pet_days_to_birthday'] },
			direct_partner_birthday: { birthday: ['customer:partner_days_to_birthday',' customer:partner_name','customer:partner_birthday_date'] },
			direct_happy_anniversary: { anniversary: ['customer:partner_name','customer:days_to_anniversary','customer:anniversary_date'] },
			direct_cart_abandonned: { order: order_tags },
			direct_purchase_anniversary: { order: order_tags },
			direct_campaign_anniversary: { campaign: ['campaign:customers_url', 'campaign:details', 'campaign:months_from_sales', 'campaign:recorded_sales_unit', 'campaign:recorded_sales_value', 'campaign:staff_name', 'campaign:tag_slug'] },

			info_order_hold: { order: ['order:delay_until_date']},
			info_stock_available: { product: ['product:hero_img_url','product:name','product:website_url','product:price'] },

			info_joint_account_confirmation: { partner: ['partner:email','partner:fname','partner:mobile']},

			payment_gift_new: { order: ['order:recipient_name'] },

			info_reward_awarded: { order: ['order:reward_points_value', 'order:reward_points_expiry_date', 'order:referral_fname'] },

			member_upgrade: { membership: ['membership:previous_name'] },
			member_downgrate: { membership: ['membership:previous_name'] },
			member_invite: { membership: ['membership:signup_url'] },

			pickup_labelled: { pickup: ['pickup:now','pickup:60mins','pickup:45mins','pickup:30mins','pickup:15mins'] },

			payments_accounts_payable: { customer: ['customer:billing_email','customer:business_name'] }, 

			staff_integration_degraded: { integration: ['integration:name', 'integration:status', 'integration:last_error_details', 'integration:last_error_date', 'integration:platform_url','integration:logs_error_table'] },
			staff_management_summary: { report: ['report:orders_processing_table','report:staff_sales_table','users:signed_in_table'] },
			staff_financial_summary: { report: ['report:takings_table','report:stock_table','report:settlements_table','report:orders_table', 'report:period'] },

			staff_orders_to_process: { warehouse: warehouse_tags.push('warehouse:orders_summary_table','warehouse:orders_to_process','warehouse:orders_with_notes_table','warehouse:earliest_completion_date','warehouse:troly_orders_url') },

			staff_stock_warning: { report : ['company:low_stock_table','company:low_stock_warehouse_table'] },
									
			staff_task_assigned: { task: ['task:summary', 'task:details', 'task:due_date', 'task:assigned_by_name', 'task:details', 'task:due_date', 'task:platform_url'] },

			staff_team_summary: { report: ['report:membership_changes_table', 'users:interactions_recorded_table', 'report:performance_summary_table', 'report:period', 'report:pipeline_changes_table','users:orders_processed_table','users:tags_summary_table'] },

		}

		let tags = {};
		tags['company'] = company_tags;
		if (this.category != 'internal') { tags['customer'] = customer_tags; }
		
		switch (this.category) {
			case 'direct':
				break;

			case 'info':
				break;

			case 'marketing':
				break;

			case 'membership':
				tags['membership'] = membership_tags
				break;

			case 'payments':
				tags['order'] = order_tags;
				tags['payment'] = payment_tags

				if (['payment_cc_expiry','payment_cc_expired','payment_cc_missing','payment_successful','payment_declined'].includes(this.name)) {
					tags['membership'] = membership_tags
				}

				break;

			case 'internal':
				//tags = tags.concat(company_tags)

				if (this.name == 'staff_disputed_charge') {	
					tags['order'] = order_tags.push('order:created_at','order:completed_at');
					tags['payment'] = payment_tags.push('payment:dispute_reason','payment:dispute_status','payment:dispute_date','payment:dispute_ref');
				}

			case 'membership_orders':

				tags['warehouse'] = warehouse_tags;
				tags['order'] = order_tags;
				tags['membership'] = membership_tags;
				if (this.name.startsWith('shipment')) { tags['shipment'] = shipment_tags; }
				else { tags['pickup'] = pickup_tags; }

			case 'pickup':

				tags['warehouse'] = warehouse_tags;
				tags['order'] = order_tags;
				tags['pickup'] = pickup_tags;

				
				break;

			case 'shipment':
				tags['warehouse'] = warehouse_tags;
				tags['order'] = order_tags;
				tags['shipment'] = shipment_tags;

				if (self.name.endsWith('no_tracking')) { delete tags['shipment']['tracking_codes']; }

				break;
		}

		if (this.channel == 'task' || this.channel == 'call') {
			if (tags['customer']) { tags['customer'].push('customer:platform_url'); }
			if (tags['order']) { tags['order'].push('order:platform_url'); }
			if (tags['product']) { tags['product'].push('product:platform_url'); }
		}

		if (template_only_tags[this.name]) {
			tags = mergeDeep(tags, template_only_tags[this.name])
		}
		

		return tags;
	}

	/**
	 * This is just a static list to help define th order in which we want templates to show.
	 */
	get sortWeight(): number {

		const channelVariant = (this.channel == 'email' ? 0.0 : (this.channel == 'sms' ? 0.1 : (this.channel == 'call' ? 0.2 : 0.3)));

		return [

			'info_newsletter_subscribe',
			'info_newsletter_unsubscribe',
			'info_bounced_email',
			'info_spamreport_email',
			'info_reward_awarded',
			'info_reward_threshold_1',
			'info_reward_threshold_2',
			'info_reward_threshold_3',
			'info_reward_threshold_3_details',
			'info_order_hold',
			'payment_gift_new',
			'info_wishlist',
			'info_stock_available',
			
			'direct_cart_abandonned',
			'direct_customer_birthday', 
			'direct_partner_birthday',
			'direct_happy_anniversary',
			'direct_pet_birthday',
			'direct_campaign_anniversary',
			'direct_purchase_anniversary',
			'direct_reward_program',
			'direct_reward_reminder',
			'direct_customer_inactive',
			'direct_campaign',
			'info_customer_summary',
			'info_customer_summary_trade',
			
			'drip_all_1','drip_all_2','drip_all_3','drip_all_4','drip_all_5','drip_all_6','drip_all_7', 'drip_all_8', 'drip_all_feedback',
		
			'member_invite','member_new','member_renew','member_upgrade','member_downgrade','member_suspended', 'member_reinstated', 'member_precancellation','member_cancellation',
			'info_joint_account_confirmation',
		
			'payment_cc_missing', 'payment_cc_expiry', 'payment_cc_expired', 'payment_declined', 'payment_successful', 'payment_refunded', 'payment_accounts_payable', 'payment_disputed_charge',
		
			'pickup_bulk_draft',				// in the membership orders list
			'shipment_bulk_draft',			// in the membership orders list
			'pickup_bulk_confirmed',		// in the membership orders list
			'shipment_bulk_confirmed',		// in the membership orders list
			
			'pickup_new',
			'pickup_new_member',				// in the membership orders list
			'pickup_changed',
			'pickup_packed',
			'pickup_labelled',
			'pickup_completed',
			'pickup_feedback',
			
			'shipment_new',
			'shipment_new_member',				// in the membership orders list
			'shipment_changed',
			'shipment_packed',
			'shipment_labelled',
			'shipment_labelled_no_tracking',
			'shipment_dispatch',
			'shipment_dispatch_no_tracking',
			
			'shipment_in_transit',
			'shipment_delayed',
			'shipment_out_for_delivery',
			'shipment_delivered',
			'shipment_delivered_no_tracking',
			'shipment_feedback',
		
			'staff_orders_to_process',
			'staff_disputed_charge',
			'staff_team_summary',
			'staff_financial_summary',
			'staff_management_summary'

		].indexOf(this.name) + channelVariant;
	}

	get settingsToCapture(): {[key:string]:string[] } | null {

		let settings = {};

		switch (this.name) {

			case 'pickup_feedback':
			case 'shipment_feedback':
				settings['timeframe'] = [0,3,5,7,14];
				break;

			case 'staff_orders_to_process':
				settings['timeframe'] = ['0.second', '12.hours', '1.day'];
				break;
			
			case 'staff_team_summary':
				settings['timeframes'] = ['1.day','1.week','1.month'];
				break;

			case 'staff_financial_summary':
				settings['timeframes'] = ['1.day','1.week','1.month','1.quarter'];
				break;

			case 'staff_management_summary':
				settings['timeframes'] = ['1.week','1.month','1.quarter'];
				break;

			case 'payment_successful':
			case 'payment_refunded':
				settings['scope'] = ['only_customers','only_members', 'only_bulk']
				break;

			case 'direct_customer_inactive':
				settings['scope'] = ['only_customers','only_members']
				settings['timeframe'] = [90,180,365,540];
				break;

			case 'info_bounced_email':
				settings['scope'] = ['only_contacts', 'only_customers', 'only_members']
				settings['timeframe'] = [0, 1, 3, 7, 14];
				break;

			case 'payment_cc_missing':
			case 'payment_cc_expired':
				settings['timeframe'] = ['once', '1.month', '1.quarter','1.year'];
				break;

			case 'payment_cc_expiry':
				settings['timeframes'] = [3,7,10,14,21];
				break;

			case 'drip_all_2': case 'drip_all_3': case 'drip_all_4': case 'drip_all_5': case 'drip_all_6': case 'drip_all_7': case 'drip_all_8':
				settings['timeframe'] = [3,7,10,14,21];
				break;

			case 'direct_reward_reminder':
				settings['timeframe'] = [90,180,365,540];
				break;

			case 'direct_high_value_customer':
				settings['timeframe'] = ['1.month','1.quarter','6.months','1.year','18.months'];
				break;

			case 'direct_customer_birthday':
			case 'direct_pet_birthday':
			case 'direct_happy_anniversary':
				settings['timeframe'] = [0,3,7,10,14,21];
				settings['scope'] = ['all_contacts', 'all_customers', 'all_members']
				break;
			
			case 'direct_personal_celebration':
				settings['timeframe'] = [0,3,7,10,14,21];
				settings['scope'] = ['anniversary', 'birthday', 'petday']
				break;

			case 'direct_campaign_anniversary':
				settings['timeframe'] = [30,60,90,180,365,540];
				break;
			case 'direct_purchase_anniversary':
				settings['timeframe'] = [30,60,90,180,365,540];
				settings['scope'] = ['only_contacts', 'only_customers', 'only_members']
				break;
		}

		switch (this.channel) {
			case 'task':
				settings['priority'] = ['low','high'];
				settings['assigned_to'] = ['random','warehouse_manager'];

				if (['direct_cart_abandonned'].includes(this.name)) {
					settings['scope'] = ['all_contacts','only_customers','only_members']
					settings['timeframe'] = ['1.hour','6.hours','1.day','1.week'];
				}
				break;
			case 'call':
				settings['priority'] = ['low','high'];
				break;
		}

		return Object.keys(settings).length == 0 ? null : settings;
	}


	public illegalVariablesFound(content:string): string[] {

		const allowedTags = this.replacementVariables;
		const flattenedTags = Object.keys(allowedTags).reduce((acc, key) => acc.concat(allowedTags[key]), []);

		let illegalVars = [];
		let variables = content.match(/\*\|(.*?)\|\*/g) || []; // Regex to match all of our *|variables|*

		variables.forEach((v:string) => {
			const tag = v.slice(2, -2);
			if (!flattenedTags.includes(tag)) { illegalVars.push(v); }
		});

		return illegalVars;
  }


	private _locale:string;
	public get locale():string { return this._locale }
	public set locale(value:string) {
		this._locale = value;
		this.subject = this.translations ? (this.translations[value]?.subject || this.subject) : this.subject;
		this.body = this.translations ? (this.translations[value]?.body || this.body) : this.body;
	}

}

export type ITemplateChannel = 'sms'|'email'|'call'|'task';
