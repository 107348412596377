import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import Swal, { SweetAlertOptions, SweetAlertPosition } from 'sweetalert2';


const duration = 15000;

@Injectable({
	providedIn: 'root',
})
export class AlertService {

	/**
	* The name or identifier of the current class, not otherwise available when running in "production mode". 
	* It is used to output debugging information on the console, and also attached to translations of labels, product tours, etc
	*/
	public readonly __name:string = 'AlertService';

	protected translateService: TranslateService = inject(TranslateService);
	
	public alertOptions(level: 'info'|'question'|'error'|'warning', confirmStr: string, cancelStr?: string): SweetAlertOptions {

		let actionClass = 'btn-dark';
		switch (level) {
			case 'info': 		actionClass = 'btn-secondary'; 	break;
			case 'question': 	actionClass = 'btn-primary'; 		break;
			case 'error': 		actionClass = 'btn-warning'; 		break;
			case 'warning':	actionClass = 'btn-danger'; 		break;
		}

		let options = {
			icon: level as SweetAlertOptions['icon'],
			confirmButtonText: this.translateService.instant(confirmStr.match(/\./) ? confirmStr : `Buttons.Alerts.${confirmStr}`),
			customClass: {
				confirmButton: `btn ${actionClass}`,
				cancelButton: 'btn btn-light',
			},
			buttonsStyling: false,
			position: 'top' as SweetAlertPosition
		};

		if (cancelStr) {
			options['cancelButtonText'] = this.translateService.instant(cancelStr.match(/\./) ? cancelStr : `Buttons.Alerts.${cancelStr}`);
			options['showCancelButton'] = true;
			options['reverseButtons'] = true;
		}

		return options;
	}

	public confirm(options:SweetAlertOptions): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {
			Swal.fire(options).then((_) => { 
				if(_.isConfirmed) { resolve(true) } else { reject(false) }
			}, (_) => {  reject(false) });
		});
	}

	public checkConfirmation(options:SweetAlertOptions): Promise<boolean> {
		return new Promise<boolean>((resolve) => {
			Swal.fire(options).then((_) => { 
				if(_.isConfirmed) { resolve(true) } else { resolve(false) }
			}, (_) => { resolve(false) });
		});
	}

}
