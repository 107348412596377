import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, inject } from '@angular/core';
import { TrolyModal } from 'src/app/core/components/troly.modal';
import { Product } from 'src/app/core/models/troly/product.model';
import { ProductService } from 'src/app/core/services/troly/product.service';
import { TrolyValidationError } from 'src/app/core/services/troly/troly.interceptor';
import { TrolyAngularModule } from 'src/app/shared/angular.module';
import { TrolyMaterialModule } from 'src/app/shared/material.module';
import { TrolySharedModule } from 'src/app/shared/shared.module';

@Component({
	selector: 'create-product-modal',
	templateUrl: './create-product.modal.html',
	styleUrls: ['./create-product.modal.scss'],
	standalone: true,
	imports: [TrolyAngularModule, TrolyMaterialModule, TrolySharedModule]
})
export class CreateProductModal extends TrolyModal<Product> {

	/**
	 * The name or identifier of the current class, not otherwise available when running in "production mode".
	 * @see TrolyComponent.__name (Override)
	 */
	override readonly __name:string = 'CreateProductModal';

	@Input("record") record?: Product = new Product();

	_formFields = ['id', 'name', 'variety', 'price', 'weight', 'units_produced', 'number'];
	_formDefaults = { price:0 }

	productVarieties = [];//'_drinks', 'wine', 'beer', 'spirit', 'drink', '_foods', 'produce', 'meat', 'meat_alt', 'food', '_others', 'bundle', 'goods', 'virtual']

	/**
	 * Additional services used this this component. 
	 * ? Keeping in mind CompanyService and UserService are already available in TrolyComponent. 
	 */
	protected productService: ProductService = inject(ProductService);
	
	constructor() {
		super();

		// setting the default service for forms + obtaining/saving data in this component.
		this.service = this.productService;

		this.productVarieties = this.translateService.instant(`Models.Product.Values.variety.__sort`).split('|');
		this.initForm()

	}

	protected attachChangeNotifiers(): void {
		super.attachChangeNotifiers();
		
		this.form.get('variety').valueChanges.subscribe(_ => this.updateFormForSelectedVariety(_));
		this.form.get('units_produced').valueChanges.subscribe(_ => this.updateFormForSelectedVariety(this.form.get('variety').value));

	}

	onSubmit(): void {

		this.markAsLoading(this.form);
		let changes = this.form.getChanges();

		this.form.disable();
		this.service.create(new Product(changes)).subscribe({
			next: (_: Product) => {

				this.form.controls['id'].setValue(_.id);
				this.form.resetCodes({ success: 'CREATED' });//'Customer successfully created, redirecting...';

				// delay redirection by a second so the user can see the success message
				this.form._timer = setTimeout(() => {
					// See routerNaviate: https://www.tektutorialshub.com/angular/angular-navigating-between-routes/
					this.resolveModal('timeout');
				}, this.form.countdown());
				
				this.record = _;

			}, 
			error: (err: HttpErrorResponse) => {

				if (err instanceof TrolyValidationError) {
					this.form.assignValidationErrors(err.error);
					this.markAsLoaded(this.form);
				} else {
					this.unknownApiError('9257-SLIPPER-prolong-bounce')
				}
			}

		});
	}

	// overriding to support redirect to produc trecord
	public resolveModal(from?: string): boolean {

		if (from == 'redirect' || from == 'timeout') {
			// See routerNaviate: https://www.tektutorialshub.com/angular/angular-navigating-between-routes/
			this.router.navigate([`/products/${this.form.controls['id'].value}`]);
		}

		return super.resolveModal(from); // clear the form timer and closes active popup
	}

	updateFormForSelectedVariety(variety: string): void {
		
		let captureWeight:boolean = true;
		let capturePrice:boolean = true;

		switch(variety) {
			case 'bundle':
				captureWeight = parseInt(this.form.get('units_produced').value) > 0;
				capturePrice = captureWeight
				break;
			case 'event':
			case 'service':
			case 'virtual':
				captureWeight = false;
				break;
		}
		
		if (captureWeight) {
			this.form.get('weight').setValidators(this.record._weight); // required etc.
			this.form.get('weight').enable();
		} else {
			this.form.get('weight').clearValidators();
			this.form.get('weight').disable();
		}
		if (capturePrice) {
			this.form.get('price').setValidators(this.record._price); // required etc.
			this.form.get('price').enable();
		} else {
			this.form.get('price').clearValidators(); 
			this.form.get('price').disable();
		}
		this.form.updateValueAndValidity();

	}

}
