import { Injectable } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, filter, map, Observable } from 'rxjs';


@Injectable({
	providedIn: 'root',
})
export class LocalCacheService {

	/**
	* The name or identifier of the current class, not otherwise available when running in "production mode". 
	* It is used to output debugging information on the console, and also attached to translations of labels, product tours, etc
	*/
	public readonly __name:string = 'LocalCacheService';
	
	private readonly STORAGE_KEYS = {
		USER_CACHE: 'uc',
	}
	
	/**
	 * The UI nofifier sends "user preferences changed" notification, anything which is used locally to render certain ui elements, or in a certain way, and cached.
 	*/
	public uiNotifier$: BehaviorSubject<{}>;

	/**
	 * The DATA notifier sends "data changed" notifications. Data changed is cached records/lists of records, that will be refreshed via API calls.
	 */
	public dsNotifier$: BehaviorSubject<{}>;

	constructor() {

		let db = JSON.parse(window.localStorage.getItem(this.STORAGE_KEYS.USER_CACHE)) || {};
		Object.keys(db).forEach((key) => {
			if (db[key]['expires'] < Date.now()) { // same as this.storedWithExpiry(key), just more effective
				delete db[key];
			}
		})

		this.uiNotifier$ = new BehaviorSubject<{}>(
			Object.keys(db).filter(_ => _.match(/^ui\./)).reduce((_ui, _uiKey) => {
				_ui[_uiKey.replace(/^ui\./, '')] = db[_uiKey]['value'];
				return _ui;
			}, {})
		)
		this.dsNotifier$ = new BehaviorSubject<{}>(
			Object.keys(db).filter(_ => _.match(/^ds\./)).reduce((_ds, _dsKey) => {
				_ds[_dsKey.replace(/^ds./, '')] = db[_dsKey]['value'];
				return _ds;
			}, {})
		)

	}

	/**
	 * Attaches the localStorage 'dataStore' notifier for the current user, and updates a local property on change
	 * @param service the service to attach to
	 * @param key {string} the storage/notification key to look for, eg `ui.SomeComponentClass.propertyName`
	 * @param property {string} the local property on `this` to update. defaults to whatever is after the last dot (.) in the key value passed
	 * @returns the current value if one exists onload, else nil
	 */
	public attachStorageNotifier<U>(key: string, allow_undefined:boolean=false): Observable<U> {
		const comparatorFn = (a, b) => JSON.stringify(a) === JSON.stringify(b);
		return this.uiNotifier$.pipe(filter(_ => _[key] !== undefined || allow_undefined), map(_ => _[key] as U), distinctUntilChanged(comparatorFn));
	}

	
	/**
	 * forcefully updates a new value
	 * @param key 
	 * @param value 
	 * @param expiry in hours
	 * @returns 
	 */
	public storeDbKey<T>(key: string, value?: T, expiry: number = 48): T {
		let result = this.storedWithExpiry(`ds.${key}`, value, expiry);
		if (result?.value !== undefined) {
			let o = this.dsNotifier$.value || {}; 
			o[key] = result.value;
			this.dsNotifier$.next(o)
		}
		return result ? (result.value as T) : null;
	}

	/**
	 * Checks and retrieve or returns default value
	 * @param key 
	 * @param value 
	 * @returns 
	 */
	public cachedDbKey<T>(key: string, defaultValue?: T): T {
		let result = this.storedWithExpiry(`ds.${key}`, undefined, 0);
		return result ? (result.value as T) : defaultValue;
	}

	/**
	 * @param key forcefully stores a new UI setting
	 * @param value 
	 * @returns 
	 */
	public storeUiKey<T>(key: string, value?: T, expiry_in_hours: number = 720): T {
		let result = this.storedWithExpiry(`ui.${key}`, value, expiry_in_hours);
		if (result?.value !== undefined) {
			let o = this.uiNotifier$.value || {}; 
			o[key] = result.value;
			this.uiNotifier$.next(o)
		}
		return result ? (result.value as T) : null;
	}

	/**
	 * Checks and retrieve or returns default value
	 * @param key 
	 * @param value 
	 * @returns 
	 */
	public cachedUiKey<T>(key: string, defaultValue?: T): T {
		let result = this.storedWithExpiry(`ui.${key}`, undefined, 0);
		return result ? (result.value as T) : defaultValue;
	}

	/**
	 * 
	 * @param key 
	 */
	protected deleteKey(key: string): void {
		this.storedWithExpiry(key, undefined, -1);
	}

	/**
	 * 
	 * @param key 
	 */
	public deleteDbKey(key: string): void {
		this.deleteKey(`ds.${key}`);
	}

	/**
	 * 
	 * @param key 
	 */
	public deleteUiKey(key: string): void {
		this.deleteKey(`ui.${key}`);
	}

	/**
	 * retrieved stored keys matching a certain pattern
	 * @param target 
	 * @returns 
	 */
	private keysToMatch(target: string): string[] {
		let db = JSON.parse(window.localStorage.getItem(this.STORAGE_KEYS.USER_CACHE)) || {}
		return Object.keys(db).filter(_ => _.match(target))
	}

	/**
	 * retrieves all keys patching the target, and increments by a certain value
	 * @param target 
	 * @param increment
	 */
	public incrementUiCounter(target: string, increment: number = 1): void {
		this.keysToMatch(`^ui.${target}`).forEach((k) => {
			let cache = this.storedWithExpiry<IUiCounter>(k, undefined, 0); // passing 0 as expiry prevents deletion -- required as we bypass cachedDbKey|cachedUiKey so that we can get the entire object

			if (cache?.value?.count != undefined) {
				let newValue = cache.value.count + increment;
				// note: the ui counters are stored as [value, timestamp], so we need to keep the 'old' timestamp, and next time the .list method is called this will be 
				// updated based on the value not maching with the new. This is because showing 'new' doesn't EVERY new record should be counted. So we count it, temporarily..
				this.storeUiKey(k.replace(/^ui\./,''), {count:newValue, checkValue:undefined, chokeUntil:cache.value.chokeUntil}, 48);
			} else {
				this.storeUiKey(k.replace(/^ui\./,''), {count:increment, checkValue:undefined, chokeUntil:undefined}, 48);
			}
		});
	}

	/**
	 * 
	 * @param key 
	 * @returns 
	 */
	public getUiCounter(key) : IUiCounter {
		return this.cachedUiKey<IUiCounter>(key, {count:undefined});
	}

	/**
	 * 
	 * @param key 
	 * @param current_count 
	 * @param expiry_in_hours 
	 * @param check_value 
	 * @param chokeUntil 
	 */
	public setUiCounter(key:string, current_count:number, expiry_in_hours:number, check_value?:string, chokeUntil?:number): IUiCounter {

		chokeUntil ||= Date.now() + (30 * 1000)
		return this.storeUiKey<IUiCounter>(key, { count:current_count, checkValue:check_value, chokeUntil:parseInt(chokeUntil.toString()) }, expiry_in_hours);
	}

	/**
	 * Will cause for a counter to be flushed if the check_value doesn't match the current value.
	 * This DOESN'T retrieve a new counter, but propagates locally.
	 * @param key 
	 * @param check_value 
	 */
	public flushBadCounter(key:string, check_value:string): void {
		const cache = this.cachedUiKey<IUiCounter>(key);
		if (cache == undefined || cache.checkValue != check_value) {
			this.storeUiKey<IUiCounter>(key, {count:(cache?.count || 0), checkValue:undefined, chokeUntil:undefined}, 1); 
		}
	}

	/**
	 * Deletes all stored setting matching a target expression, alternatively, deletes all non-persistent setting
	 * This is used to clear product-list- when receiving an updated Product through sockets, or clearing user-count when a new User is received.
	 * Any key that ends in .persist is not deleted (and also not deleted on logout -- see {TrolyComponent.logout})
	 * @param target 
	 */
	public flushLocalStorage(target?: string) {
		if (target) {
			this.keysToMatch(target).forEach((k) => {
				this.deleteKey(k);
			});
		} else { // when loggin out, we just call flushLocalStorage()
			this.keysToMatch('[a-z]').forEach((k) => {
				if (!k.match(/persist$/)) {
					this.deleteKey(k);
				}
			})
		}
	}

	/**
	 * 
	 * 
	 * storedWithExpiry(k) => retrieves if exists, checks for expiry
	 * storedWithExpiry(k, v) => sets to v
	 * storedWithExpiry(k, ?, <0 ) => forcefully deletes
	 * * storedWithExpiry(k, ?, <0 ) => forcefully deletes
	 * @param key 
	 * @param value 
	 * @param expiry 
	 * @returns 
	 */
	private storedWithExpiry<U>(key: string, value: any = '-', expiry: number = -1): { value: U, expires: number } {

		let mode = (value == '-' && expiry < 0) ? 'delete' : (value != '-' && expiry > 0 ? 'set' : 'get');

		//let notify: boolean = false;
		let result = null;

		let db = JSON.parse(window.localStorage.getItem(this.STORAGE_KEYS.USER_CACHE)) || {}

		if (mode == 'set') {
			result = {
				value: value,
				expires: (Date.now() + (expiry * 60 * 60 * 1000))
			}
			db[key] = result;
			window.localStorage.setItem(this.STORAGE_KEYS.USER_CACHE, JSON.stringify(db));
			//notify = true;
		} else if (db[key]) {
			if (mode == 'delete' || db[key]['expires'] < Date.now()) {
				delete db[key]
				window.localStorage.setItem(this.STORAGE_KEYS.USER_CACHE, JSON.stringify(db));
				//notify = true;
			} else {
				result = db[key];
			}
		}

		return result;
	}

}

export interface IUiCounter {

	count: number;
	checkValue?: string;
	chokeUntil?: number;

}
