<div id="layout-wrapper" class="{{ selectedCompany.partnerName }} {{ authenticatedUser.expertise_level }}" *ngIf="authenticatedUser?.id">

	<app-topbar [menuItems]="!isVertical ? menuItems : null"
			(toggleLayoutPrefClicked)="onToggleLayoutPreference($event)"
			(verticalSidebarCollapseClicked)="onToggleSidebarCompact()" (helpRequestClicked)="onHelpRequest($event)"
			(menuActionClicked)="layoutAction($event)">
	</app-topbar>

	
	<div class="d-none" id="sticky-subnav-wrapper">*</div>

	<app-sidebar *ngIf="isVertical" [isCondensed]="isCondensed" [menuItems]="isVertical ? menuItems : null" (menuActionClicked)="layoutAction($event)">
	</app-sidebar>

<mat-drawer-container style="height:100%;background:transparent" [hasBackdrop]="false">
	<mat-drawer-content>
	  
		<div class="main-content">
			<div class="page-content">
				<!-- content -->
				<router-outlet></router-outlet>
				<div class="text-end font-size-11 pe-3">
					<span *ngIf="dbRegion == 'us-nyc' || dbRegion == 'us-sfo-1'">Hand made en Oregon, 🇺🇸 USA</span>
					<span *ngIf="dbRegion == 'ca-tor'">Fabriqué minutieusement à Québec, 🇨🇦 Canada</span>
					<span *ngIf="dbRegion == 'au-syd'">Handcrafted in 🇦🇺 Australia</span>
					<span *ngIf="dbRegion == 'eu-fra'">Hecho a mano en 🇪🇸 España</span>
				</div>
			</div>
			<app-footer></app-footer>

		</div>
	</mat-drawer-content>
	<mat-drawer class="card" position="end" #drawer [mode]="drawerMode" style="top:85px;width:25rem;border-bottom-left-radius: var(--bs-card-border-radius);border-top-left-radius: var(--bs-card-border-radius);z-index:100;border:none;position:fixed;">
		<div class="card-header pb-0">
			<i class="btn-close font-size-12 float-end cursor-pointer text-secondary" (click)="toggleDrawerPin('close')"></i>
			<i class="bx font-size-18 float-end me-2  cursor-pointer" [ngClass]="{'text-primary bxs-area':drawerMode == 'side', 'bx-area text-secondary' : drawerMode == 'over'}" (click)="toggleDrawerPin()"></i>
			<h4>&nbsp;</h4>
		</div>
		<div class="card-body pt-0">
			<div class="row">
				<div class="col-12 pt-2">
					<mat-tab-group>
						<mat-tab label="Bookmarks">
							<h6>&nbsp;</h6>
							<status-hint
								*ngIf="recentService.favourites['order'].length + recentService.favourites['customer'].length + recentService.favourites['product'].length == 0"
								icon="bx-bookmark" 
								class="text-secondary"
								size="small"
								title="No bookmarks created"
							>
							<p>To rapidly find information you used regularly, you can easily create bookmark to help you navigate around.</p><p>You can enable notifications to track changes made.</p>
						</status-hint>
							<h6 *ngIf="recentService.favourites['order'].length">Orders</h6>
							<ul class="favourites bx-ul" *ngIf="recentService.favourites['order'].length"><li *ngFor="let item of recentService.favourites['order']">
								<i class="text-secondary bx bx-trash cursor-pointer font-size-13" (click)="recentService.toggleFavourite(item)"></i>
								<a *ngIf="item.status == 'draft'" [routerLink]="['/orders/', item.id]">{{ item }}</a>
								<a *ngIf="item.status != 'draft'" [routerLink]="['/orders/', item.id, 'process']">{{ item }}</a>
							</li></ul>
						
							<h6 *ngIf="recentService.favourites['customer'].length">Customers</h6>
							<ul class="favourites bx-ul" *ngIf="recentService.favourites['customer'].length"><li *ngFor="let item of recentService.favourites['customer']">
								<i class="text-secondary bx bx-trash cursor-pointer font-size-13" (click)="recentService.toggleFavourite(item)"></i>
								<a [routerLink]="['/customers/', item.id]">{{ item }}</a>
							</li></ul>
						
							<h6 *ngIf="recentService.favourites['product'].length">Products</h6>
							<ul class="favourites bx-ul" *ngIf="recentService.favourites['product'].length"><li *ngFor="let item of recentService.favourites['product']">
								<i class="text-secondary bx bx-trash cursor-pointer font-size-13" (click)="recentService.toggleFavourite(item)"></i>
								<a [routerLink]="['/products/', item.id]">{{ item }}</a>
							</li></ul>

						</mat-tab>
						<mat-tab label="Tasks">
							<h6>Overdue</h6>

							<h6>Today</h6>

							<h6>Later This Week</h6>

							<h6>Planned this month</h6>
						</mat-tab>
					</mat-tab-group>
					<ngb-alert type="warning" [dismissible]="false">
						<h6><span class="badge bg-dark">extra</span> New feature alert!</h6>
						<p>Bookmarks and Tasks give you a quick and direct access to your most important records.</p><p>We are curious to hear from you on how this is useful to you and when you think you would use something like this. We welcome your <a href>feedback</a></p>
					</ngb-alert>
				</div>
			</div>
		</div>
	</mat-drawer>
 </mat-drawer-container>

</div>

<ng-template #createIntegration>
	<create-integration-modal [record]="selectedIntegration"></create-integration-modal>
</ng-template>

<ng-template #createCustomer>
	<create-customer-modal [record]="selectedCustomer"></create-customer-modal>
</ng-template>

<ng-template #createProduct>
	<create-product-modal [record]="selectedProduct"></create-product-modal>
</ng-template>

<ng-template #createSms>
	<create-sms-modal [record]="selectedCustomer" [record_ids]="selectedRecordIds"></create-sms-modal>
</ng-template>

<ng-template #createEmail>
	<create-email-modal [record]="selectedCustomer" [record_ids]="selectedRecordIds"></create-email-modal>
</ng-template>

<ng-template #createInteraction>
	<create-interaction-modal [record]="selectedInteraction" [customer]="selectedCustomer"></create-interaction-modal>
</ng-template>

<ng-template #createTask>
	<create-task-modal [record]="selectedTask"></create-task-modal>
</ng-template>

<ng-template #documentViewer>
	<document-viewer-modal [record]="selectedDocument" />
</ng-template>

<ng-template #editOrder>
	<edit-order-modal [record]="selectedOrder"></edit-order-modal>
</ng-template>