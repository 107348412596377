import { Injectable, inject } from '@angular/core';
import { TimeoutError } from 'rxjs';
import { filter, timeout } from 'rxjs/operators';

import { ActivatedRoute, Router } from '@angular/router';

import { CookieService } from 'ngx-cookie-service';
import { Company } from '../models/troly/company.model';
import { User } from '../models/troly/user.model';
import { uuid } from '../models/utils.models';
import { CompanyService } from './troly/company.service';
import { UserService } from './troly/user.service';

@Injectable({
	providedIn: 'root'
})
export class AuthService {

	/**
	 * The name or identifier of the current class, not otherwise available when running in "production mode". 
	 * It is used to output debugging information on the console, and also attached to translations of labels, product tours, etc
	 */
	public readonly __name:string = 'AuthService';

	protected userService: UserService = inject(UserService);
	protected companyService: CompanyService = inject(CompanyService);

	protected cookieService: CookieService = inject(CookieService);
	protected route: ActivatedRoute = inject(ActivatedRoute);
	protected router: Router = inject(Router);

	constructor() {

		// Ensure both userService and companyService are aware of the 
		// current user and company objects, if any. Both are 
		this.userService.record$.next(this.userService.storedUser());
		this.companyService.record$.next(this.userService.storedCompany());
		
	}

	private authenticatePromise: Promise<boolean>;
	public authenticate(id: uuid, default_co_id?:uuid): Promise<boolean> {

		if (!this.authenticatePromise) {
			
			this.authenticatePromise = new Promise<boolean>((resolve) => {
				
				const params = {with_company_users:true, with_companies:true}
				this.userService._find(id, params).pipe(filter(_ => !!_)).subscribe({
					next: (_user: User) => {

						if (_user.company_users.length == 0) {	
							// user is not an administrator of any companies
							resolve(false);
						} else {
						
							// validate and/or reset the company_id requested
							default_co_id = _user.companies.find(_ => _.id == default_co_id)?.id || _user.companies[0]?.id

							// here we are mypassing the normal url builder because the user service will only call 
							// endpoint/:id/method dynamically for the users endpoint
							// so it's either this mucking around or we need to call using the companyService -- but we don't have a confirmation of access at this point (?)
							this.companyService._find(default_co_id).pipe(filter(_ => !!_)).subscribe({
								next: (_company) => {
									this.login(_user, _company);
									resolve(true);
								},
								error: (err) => {
									debugger;
									// note: when a user looses access to a company they have logger on before, we force using the first of the companies they DO have access to.
//									this.companyService._find(_user.companies[0].id).pipe(filter(_ => !!_)).subscribe({
//										next: (_company) => { this.login(_user, _company); resolve(true); },
//										error: (err) => { resolve(false); }
//									})
								}
							})
						}
					}, 
					error: (err) => { 
						debugger;
						console.error(err);
						this.logout();
						resolve(false); 

					},
					complete: () => { 
						setTimeout(() => { this.authenticatePromise = null }, 3000);
					}
				})
			})
		
//			this.authenticatePromise.then((_) => {
//				this.confirmAccessPromise = new Promise((resolve) => { resolve(_) });
//			})
		}

		return this.authenticatePromise;

	}

	public isAuthenticatedClientSide(): boolean {

		let authenticated = false;

		//const sessionCookie = this.cookieService.get('_7201y_535510n')
		const csrf = this.userService.storedCsrf()
		const user = this.userService.storedUser();
		const company = this.userService.storedCompany();

		// How do we recognise a valid session:
		// session cookie must be set, has a csrf token stored
		// user store has ID and companies
		// company has legal name (if company object is missing ==>  will be loaded in .authenticate() above.
		if (//sessionCookie != "Hello World" &&
			csrf != null &&
			user?.id != null && user?.companies?.length >= 1 &&
			company?.legal_name != null) {
			authenticated = true;
		}

		return authenticated;
	}

	public login(u, c): void {

		u = this.userService.storedUser(u, 'login');
		c = this.userService.storedCompany(c, 'login');

		this.userService.sendToDataLayerGTM(u,c);
	}
	
	public logout(fragment?:string) {

		let u: User = this.userService.storedUser();
		let c: Company = this.userService.storedCompany();

		this.userService.storedCompany(c, 'logout');
		this.userService.storedUser(u, 'logout');
		this.userService.flushLocalStorage();

		this.cookieService.set('_7201y_535510n','cookie can be deleted', null, '/')
		if (this.cookieService.get('_7201y_535510n') != 'cookie can be deleted') {
			// https-only cookies can only be deleted by a server response, hence our request here:
			this.userService.logout().subscribe(_ => {
				this.redirectToLogin(fragment);
			});
		} else {
			this.redirectToLogin(fragment);
		} 
		this.cookieService.delete('_7201y_535510n') // this doesn't delete the httponly cookie set by the api, but deletes our test.
	}

	protected redirectToLogin(loginFragment?:string) {
		if (!this.router.url.match('/start')) { 
			this.router.navigate(['/start/now'], { queryParams: { return: this.router.url }, fragment: loginFragment || this.route.snapshot.fragment || 'unauthorized' });
		} else if (loginFragment && document.location.hash != loginFragment) {
			this.router.navigate(['.'], { fragment: loginFragment });
		}
	}

	public changeCompany(company_id:uuid, region:'eu-fra'|'ca-tor'|'us-nyc'|'au-syd'): void {
		const previousRegion = localStorage.getItem('db');
		localStorage.setItem('db', region);
		this.companyService.flushLocalStorage();
		this.companyService._find(company_id).pipe(filter(_ => !!_)).subscribe({
			next: (c) => {
				const u = this.userService.storedUser();
				this.userService.sendToDataLayerGTM(u,c);
			
				//debugger;
				document.location = '/dashboard'
			}, 
			error: (err) => { 
				localStorage.setItem('db', previousRegion);
			}
		});
	}

	private confirmAccessPromise: Promise<boolean>;
	// fetch the currently logged in user based on their session against our back-end
	public confirmAccess(): Promise<boolean> {

		if (!this.confirmAccessPromise) {

			this.confirmAccessPromise = new Promise((resolve, reject) => {

				this.userService.sessionGET('sign_in').pipe(timeout(3000)).subscribe({
					next: (_) => {
						if (!_ || !_.id) {
							this.logout();
							resolve(false)
						} else {
							if (this.isAuthenticatedClientSide()) { // we have the correct indicators the current session is fine locally also,
								resolve(true)
							} else if (_.id) {
								// local storage doesn't show a concistend session state with the server, let's reinitialize the local session for the user id returned by the server.
								this.authenticate(_.id).then((_ok) => {
									resolve(_ok);
								})
							} else {
								resolve(false)
							}
						}
					}, 
					error: (e) => { 
						this.logout(); 
						if (e instanceof TimeoutError) {
							reject(e); 
						} else {
							resolve(false); 
						}
					}
				});
			});

			this.confirmAccessPromise.finally(() => {
				setTimeout(() => { this.confirmAccessPromise = null }, 30000);
			})
		}

		return this.confirmAccessPromise;
	}

}
