import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { debounceTime, distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';

import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { NgxScannerQrcodeComponent, ScannerQRCodeResult } from 'ngx-scanner-qrcode';
import { TrolyCard } from 'src/app/core/components/troly.card';
import { TrolyIndexedObject } from 'src/app/core/models/search_objects';
import { Customer } from 'src/app/core/models/troly/customer.model';
import { Order } from 'src/app/core/models/troly/order.model';
import { Product } from 'src/app/core/models/troly/product.model';
import { TrolyObject } from 'src/app/core/models/troly_object';
import { ITrolyLoadingStatus, TrolySearch } from '../../../core/models/form_objects';

@Component({
	selector: 'search-dropdown',
	templateUrl: './search-dropdown.component.html',
	styleUrls: ['./search-dropdown.component.scss']
})

/**
 * Topbar component
 */
export class SearchDropdownComponent extends TrolyCard {

	/**
	 * The name or identifier of the current class, not otherwise available when running in "production mode".
	 */
	override readonly __name:string = 'SearchDropdownComponent';
	override readonly __path:string = 'shared/widgets/search-dropdown';

	_formFields = ['search'];
	_formDefaults = { search: '' };

	records: TrolyIndexedObject[] = null;
	totalRecords = 0;

	protected readonly COMPONENT_CACHE_KEYS = {
		'SCANNER_MODE': 'search.withScannerEnabled.persist'
	}

	/**
	 * Overriding TrolyComponent.loading to add elements required by this component
	 */
	override loading: { record: ITrolyLoadingStatus, records: ITrolyLoadingStatus, troly: ITrolyLoadingStatus }={record:undefined,records:undefined,troly:undefined};

	@Input() disabled: boolean = false;
	@Input() matFieldClass: string = '';
	@Input() inputRequired: boolean = false;
	@Input() allowCreate: boolean = false;
	@Input() allowScanner: boolean = false;
	@Input() showGraphic: boolean = true;

	@Input() advanced: boolean = true;
	@Input('clearOnSelect') clearOnSelect: boolean = false;
	@Input() defaultValue: string = '';
	@Input() icon: string = "bx-search-alt";
	//@Input() defaultValue:Product | Customer | Order;

	@Input() fieldSize: 'small'|'auto' = 'small';
	public get panelWidth() { 
		return this.fieldSize == 'small' ? '375px' : '35vw'
	}
	@Input() searchScope: string = 'cop'; // any combination of the first letter of a model to search 'c'ustomer, 'o'rder and/or 'p'roduct
	@Input() searchLabel: string = 'Search..';
	@Input() searchHint: string = '';

	@Input() defaultSearchParams:{}={}
	@Input() pageSize: number = 10;
	@Output() selectCallback: EventEmitter<TrolyObject> = new EventEmitter<TrolyObject>();

	@Input() altSelection:string;
	@Output() selectCallbackAlt: EventEmitter<TrolyObject> = new EventEmitter<TrolyObject>();

	constructor() {

		super();

		this.record = new TrolyObject()
		this.initForm(false, true, 'change');
		this._formDefaults['search'] = this.defaultValue;

		this.form.get('search').valueChanges.pipe(
			debounceTime(800), distinctUntilChanged(), takeUntil(this.observablesDestroy$),
			filter(_ => _ != this.defaultValue)
		).subscribe((_) => { if (_.length > 2) { this.onSubmit() } });

		if (this.disabled) { this.form.disable(); } // ngOnChanges is "sometimes" (init of the component vs load of the component) triggered before the form is created, so we correctly apply the disabled status here too

		this.loadTranslation('shared/widgets/', 'SharedI18n.Widgets');

	}

	protected receiveInputParams(changes: SimpleChanges): void {

		if (changes['defaultValue'] && this.defaultValue != "") { /** when a default is set,if it's not empty, then we'll run a search to find the record to display */
			this.resetSearch(this.defaultValue);
		}

		if (changes['disabled'] && this.form) {
			this.disabled ? this.form.disable() : this.form.enable();
		}

		if (changes['searchScope']) {
			this.searchScope = changes['searchScope'].currentValue.toLowerCase();
		}

		super.receiveInputParams(changes);
	}

	/**
	 * allows the user to further scope the search to a specific model(s)
	 */
	userScope:string = '';
	toggleUserScope(scope:string) {
		if (this.userScope.indexOf(scope) > -1) {
			this.userScope = this.userScope.replace(scope, '');
		} else {
			this.userScope += scope;
		}
		this.onSubmit();
	}

	// Handling topbar search
	onSubmit() {
		// only search if we have more than 2 chars entered
		if (this.form.get('search').value.length > 2) {
			this.markAsLoading(this.form);
			this.records = null;

			let params = Object.assign(this.defaultSearchParams, {
				search: this.form.get('search').value, 
				limit: 10, p: 1, 
				scope: this.userScope.length > 0 ? this.userScope : this.searchScope
			})
			
			this.companyService.searchIndex<TrolySearch<TrolyIndexedObject>>(null,params).subscribe({
				next: (_resp) => {
					this.totalRecords = _resp.meta.count;
					this.records = _resp.results;
					this.markAsLoaded(this.form);
				},
				error: (err) => {
					if (this.form.assignValidationErrors(err.error)) {
						this.markAsLoaded(this.form);
					} else {
						this.unknownApiError('5623-canned-TOWEL-toy', null, 'records');
					}
				}
			});
		} else {
			this.resetSearch()
		}
	}

	onSelect(option, e?:Event) {
		if (Object.keys(option).length > 0) {

			let selection: TrolyObject;

			switch (option.class_name) {
				case 'Customer':
					selection = new Customer({id:option.id, fname:option.heading }); // for a customer search result, heading should be the 'full name'
					break;
				case 'Order':
					selection = new Order({id:option.id, billing_name:option.heading});
					break;
				case 'Product':
					selection = new Product({id:option.id, name:option.heading });
					break;
				default:
					selection = new TrolyObject('unknown', {id:option.id});
			}

			if (e) {
				e.preventDefault(); e.stopPropagation();
				this.selectCallbackAlt.emit(selection)
			} else {
				this.selectCallback.emit(selection)
				this.resetSearch(this.clearOnSelect ? '' : option.heading)
			}
		}
	}

	@ViewChild(MatAutocomplete) autoComplete: MatAutocomplete;
	@ViewChild(MatAutocompleteTrigger) topbarSearch: MatAutocompleteTrigger;
	@ViewChild(NgxScannerQrcodeComponent) cameraScanner!: NgxScannerQrcodeComponent;

	protected afterViewInit() {
		super.afterViewInit();
		this.cameraScanner.data.pipe(takeUntil(this.observablesDestroy$)).subscribe(e => this.successfulScan(e));
		
		this.autoComplete.opened.pipe(filter(_ => this.allowScanner), takeUntil(this.observablesDestroy$)).subscribe(_ => {
			if (this.localCache.cachedUiKey<boolean>(this.COMPONENT_CACHE_KEYS.SCANNER_MODE)) { 
				this.cameraScanner.start() 
			}
		});
	}

	toggleCamera() {
		const statusEnabled = this.cameraScanner.isStart
		statusEnabled ? this.cameraScanner.stop() : this.cameraScanner.start()
		this.localCache.storeUiKey<boolean>(this.COMPONENT_CACHE_KEYS.SCANNER_MODE, !statusEnabled);
	}

	/**
 	* Set/toggles the darkmode to the next status, or to a defined value -- called externally too // from the top bar navigation menu.
 	* @param value 
 	*/
	public set darkMode(value: null | 'on' | 'off' | 'auto') {
		value = value || (this.darkMode == 'auto' ? 'on' : (this.darkMode == 'on' ? 'off' : 'auto'))
	}

	private sannerActive:boolean = false;
	private ignoreScans:boolean = false;
	successfulScan(e:ScannerQRCodeResult[]) {
		
		if (this.ignoreScans) { return; }
		
		if (e.length > 0) {
		
			this.ignoreScans = true;
			this.records = null;
			let params = { url: e[0].value, limit: 1, p: 1, scope: this.searchScope }
			this.companyService.searchIndex<TrolySearch<TrolyIndexedObject>>(null, params).subscribe({
				next: (_resp) => {
					debugger;
					this.totalRecords = _resp.meta.count;
					this.records = _resp.results;
					this.markAsLoaded(this.form);
					this.ignoreScans = false;

					if (this.totalRecords == 1) { 
						this.topbarSearch.closePanel();
						this.onSelect(this.records[0]); 
					}
				},
				error: (err) => {
					if (this.form.assignValidationErrors(err.error)) {
						this.markAsLoaded(this.form);
					} else {
						this.unknownApiError('5623-canned-TOWEL-toy', null, 'records');
					}
				}
			});
		}
	}
	
	createRecord(name:string, scope:string) {
		let params = {}

		switch (scope) {
			case 'c':
				params['createCustomer'] = true;
				params['customer'] = {fname:name}
				break;
			case 'p':
				params['createProduct'] = true;
				params['product'] = {name:name}
				break;
			case 't':
				params['createTask'] = {name:name}
				break;
		}
		
		if (Object.keys(params).length > 0) {  
			this.topbarSearch.closePanel();
			this.layoutAction(params) 
		}
	}

	// when the search input is emptied or they leave the input box
	public resetSearch(value: string = null):boolean {
		// only reset if the length of the search is 0
		this.records = null;
		this.totalRecords = 0;
		if (value != null) {
			if (this.form && this.form.get('search')) {
				this.form.get('search').setValue(value);
			}
		} else {
			this.form.get('search').setValue('');
			this.onSelect({}); // when the search string is completely removed, we want to notify the caller that the result is no longer valid
		}

		return false // used as onclick, needs to be returning false to prevent href navigation
	}
}