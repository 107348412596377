import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild, inject } from "@angular/core";
import { SocketProgressWidget } from "src/app/shared/widgets/sockets/socket-progress.widget";
import { IJobStatusDetail } from "../models/troly/job.model";
import { Job, TrolyObject } from "../models/troly_object";
import { uuid } from "../models/utils.models";
import { WebsocketService } from "../services/troly/websocket.service";
import { TrolyModal } from "./troly.modal";

@Component({
	providers: [],
	template: ''
})
/**
 * The base class for all modal windows is TrolyModal. 
 * This BulkModal is handling the operations that a modal can perform in bulk. 
 * This include processing ALL, processing ONE, and processing SELECTED records.
 */
export abstract class BulkModal<T extends TrolyObject> extends TrolyModal<Job> {

	/**
	 * Defines whether this modal is operating on a single record, a selected set of records, or all possible records
	 */
	protected operatingMode:'single'|'selected'|'all'='all';

	/**
	 * List of records created / updated by this bulk operation, with optional error details;
	 */
	declare records?:T[];

	/**
	 * Defines the action to be performed on the record (or API method name to be called)
	 */
	protected operation:string;

	/**
	 * Array of record IDs to be processed in bulk
	 */
	@Input() record_ids:uuid[] = [];
	@Input() all_record_ids:uuid[] = [];

	// although we are returning a generic 'TrolyObject', we also pass additional attibutes 
	// like __reload as instructions, henc why we need Emitter<any>
	@Output() onProcessingCallback: EventEmitter<any> = new EventEmitter();
	@ViewChild(SocketProgressWidget) socketProgressWidget!: SocketProgressWidget;

	constructor() {
		super();

		this.initForm();

		this.loadTranslation('modules/orders/summary-page/tabs/processing-tab/bulk-modals', 'SharedI18n.BulkModals') // required to access translations for the company templates
	}

	/**
	 * Additional services used this this component. 
	 * ? Keeping in mind CompanyService and UserService are already available in TrolyComponent. 
	 */
	protected ws: WebsocketService = inject(WebsocketService);

	protected receiveInputParams(changes: SimpleChanges): void {

		// Based on the input parameters received, we set the correct operating mode.
		if (this.record_ids.length > 0) { this.operatingMode = 'selected'; }
		if (this.record?.id) { this.operatingMode = 'single'; }
		
		super.receiveInputParams(changes);

		if (this._formFields.length == 0) {
			// forcefully mark as dirty to ensure the form.cannotSubmit let's us submit although no user input is required.
			this.form.markAsDirty(); 
		}
	}

	public set mode(mode:'single'|'selected'|'all') {
		if(this.operatingMode != 'all') { console.error(`${this.__name}.operatingMode cannot be changed once set. current value ${this.operatingMode}, setting to ${mode}`) } 
		else { this.operatingMode = mode }
	}

	/**
	 * If the modal has confirmed with the user that it's ok to bypass the workflow or not (ususally for singleOrders)
	 */
	public bypassWorkflowConfirmed:boolean = false;
	public bypassWorkflowAvailable:boolean = false;

	/**
	 * If the popup has indicated that we have a bypass available, but the user has not confirmed it, then we cannot submit
	 */
	public get cannotSubmit(): boolean {
		return this.form.cannotSubmit || 
			(this.bypassWorkflowAvailable && this.operatingModeSingle && !this.bypassWorkflowConfirmed) ||
			this.form.code != null;
	}

	public onSubmit(payload?: any, request_params?:{}) {

		// when called from the POS or directly working on a single order, the [record] is set, and so is an record.ID (amongst other things) 
		// however, we don't want to call /orders/3324/process_packing, instead, we want to call /orders/process_packing with (optional) ids 
		// being posted as array.

		payload ||= this.form.getChanges() || new TrolyObject();
		
		if (this.operatingModeSingle && this.record?.id) 									{ payload['ids'] = [this.record.id]; } 
		else if (this.operatingModeSelectedOne || this.operatingModeSelectedMany)	{ payload['ids'] = this.record_ids; } 
		else if (this.operatingModeAll) 															{ payload['ids'] = this.all_record_ids; }

		this.socketProgressWidget.status = 'submitted';// shows work is about to take place

		request_params ||= {}
		if (this.bypassWorkflowConfirmed) { request_params['bypass_workflow'] = true }

		if (this.operation == null) { throw `${this.__name}.onSubmit operation not set` }
		this.ws.subscribeSocketJob(this.service.createJob(payload, this.operation, request_params)).subscribe({
			next: (_) => {

				if (_.type == 'queued') {
					// we're receiving the internal observer notification for the channel to be connected
					this.socketProgressWidget.status = 'pending'; // shows work is about to take place
					return;
				}
				
				// for every socket message received, send a copy to the status bar for update
				this.socketProgressWidget.receiveSocketMessage(_);

				payload['__operation'] = this.operation;

				if (_.type == 'error') {

					this.form.resetCodes({ error: 'SERVER' }, this.form.errorDetails) // error details may have been updated from the socket, so we don't want to reset here; 
					this.markAsLoadingError(this.form);
					this.onProcessingCallback.emit({ ...payload, ...{ '__reload': _.type } }); // sending a request to reload the records due to error

				//} else if (_.type == 'progress') {
				// message.type can be progress, which is just an indicator of how many things are being processed, how many were successfully processed, and how many failed.
				// we don't handle it here because this is handled in the SocketProgressWidget
				} else if (_.type == 'result') {
					/**
					 * in the context of a Job, the 'result' message is a 'deliverable' being confirmed, while the processing continues.
					 * This 'deliverable' is MORE than just status update and often contains a link to access more results or to download a document
					 */
					this.form.resetCodes({ info: 'RESULTS' })
				} else if (_.type == 'end') {
					// job has finished. set the form status accordingly

					const m  = _ as IJobStatusDetail

					if (m.fail > 0 || m.ok == 0) {
						// we have had NO progress, or CLEARLY at least one failure
						if (this.operatingMode == 'single') {
							this.form.resetCodes({ error: 'ONE_FAILED' })
						} else if (m.ok == 0) {
							this.form.resetCodes({ error: 'ALL_FAILED' })
						} else {
							this.form.resetCodes({ error: 'SOME_SUCCESS' })
						}
						this.markAsLoadingError(this.form);
					} else {
						// no failures AND progress was made, this is a successful outcome
						if (this.operatingMode == 'single') {
							this.form.resetCodes({ success: 'ONE_SUCCESS' })
						} else {
							this.form.resetCodes({ success: 'ALL_SUCCESS' })
						}
						
						if (this.operatingModeSingle) {
							this.form._timer = setTimeout(() => {
								this.resolveModal('timeout');
							}, this.form.countdown(5));
						}

					}
					this.onProcessingCallback.emit({ ...payload, ...{ '__reload': _.type } }); // sending a request to reload the records due to task completion
				}
			},
			error: (_) => {
				console.log(_);
				this.form.resetCodes({ error: 'SERVER' }) // error details may have been updated from the socket, so we don't want to reset here;
			}
		});
	}

	public resolveModal(from?: string, vars?: any): boolean {
		vars ||= {}
		vars['recordsWereChanged'] = ['SOME_SUCCESS','ONE_SUCCESS','ALL_SUCCESS'].includes(this.form.code)
		return super.resolveModal(from, vars);
	}

	get operatingModeSingle(): boolean {  return this.operatingMode == 'single' }
	get operatingModeAll(): boolean {  return this.operatingMode == 'all' }
	get operatingModeSelected(): boolean {  return this.operatingMode == 'selected' }
	get operatingModeSelectedOne(): boolean {  return this.operatingMode == 'selected' && this.record_ids.length == 1 }
	get operatingModeSelectedMany(): boolean {  return this.operatingMode == 'selected' && this.record_ids.length > 1 }
}