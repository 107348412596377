
import { TrolyCard } from 'src/app/core/components/troly.card';


import {
	ApexAxisChartSeries,
	ApexChart, ApexDataLabels, ApexFill, ApexGrid, ApexLegend, ApexPlotOptions, ApexStates, ApexTitleSubtitle, ApexTooltip,
	ApexXAxis, ApexYAxis
} from "ng-apexcharts";
import { takeUntil } from 'rxjs';
import { Stat } from "../models/troly/stats.model";

export type ChartOptions = {
	series: ApexAxisChartSeries;
	chart: ApexChart;
	xaxis: ApexXAxis;
	colors: string[];
	grid: ApexGrid;
	markers: any; //ApexMarkers;
	stroke: any; //ApexStroke;
	yaxis: ApexYAxis | ApexYAxis[];
	dataLabels: ApexDataLabels;
	title: ApexTitleSubtitle;
	legend: ApexLegend;
	plotOptions: ApexPlotOptions;
	fill: ApexFill;
	states: ApexStates;
	tooltip: ApexTooltip;
};


export abstract class TChartComponent extends TrolyCard {

	override readonly __name:string = 'TChartComponent';

	chartView: string;
	chartViews: string[]=[];

	public chartOptions: Partial<ChartOptions>;

	protected _chartDefaults = {
		area: {
			chart: { type: 'area', height: 250, toolbar: { show: false } },
			stroke: { curve: 'smooth', width: 2 },
			fill: {
				type: 'gradient',
				gradient: {
					shadeIntensity: 0.9,
					inverseColors: false,
					//                    opacityFrom: 0,
					//                  opacityTo: 0.6
				}
			},
			dataLabels: { enabled: false },
		},
		column: {
			chart: { type: 'line', height: 250, stacked: true, toolbar: { show: false } },
			stroke: { curve: 'smooth', width: 2 },
			markers: { size: 4 },
			dataLabels: { enabled: false },
			plotOptions: {
				bar: { columnWidth: '55%' },
			},
			states: {
				hover: {
					filter: { type: 'none', value: 1 }
				},
			},
			fill: {
				//opacity:0.6 // we're doing this via css so that we can better target the columns, not the line.
			}
		},
		bar: {
			chart: { type: 'bar', stacked: true, stackType: '100%', toolbar: { show: false }, offsetY: -28 },
			legend: { show:false },
			grid: { show:false,  padding: { top:0,bottom:0 } },
			stroke: { width: 1, colors: ['#fff'] },
			dataLabels: { enabled: false }, // hides the data points on the bars
			plotOptions: {
				bar: {
				  horizontal: true,
				}
			 }
		},
		radar: {
			chart: { type: 'radar', height: 350, toolbar: { show: false } },
			fill: { opacity: 0.1 }, 
			markers: { size: 4 },
			dataLabels: { enabled: false },
			plotOptions: {
				radar: {
				  polygons: {
					 fill: {
						colors: [this.COLOURS.SHADED, "#fff"]
					 }
				  }
				}
			 }
		},
		map: {
			chart: { type: 'heatmap', toolbar: { show: false } },
			dataLabels: { enabled: false },
		},
		cloud: {
			chart: { type: 'treemap', toolbar: { show: false } },
			plotOptions: {
				treemap: {

				}
			},
			//dataLabels : { enabled: true },
			/*grid: {
				 padding: {
					left: 0,
					right: 0
				 }
			  }*/
			labels: { offsetX: -10 }
		},
		line: {
			chart: { type: 'line', height: 250, toolbar: { show: false } },
			stroke: { curve: 'smooth', width: 2 },
			fill: { type: 'solid' },
			markers: { size: 4 },
			labels: { show:false }
		},
		none: {} // The ChartComponent is used on some cards which have a "view toggle" like some Flash cards.
	}

	constructor(_chartType: string) {

		super();

		this.chartOptions = {}
		this.chartOptions.colors = [this.COLOURS.GREEN, this.COLOURS.BLUE, this.COLOURS.YELLOW, this.COLOURS.ORANGE, this.COLOURS.PURPLE, this.COLOURS.PINK]
		this.chartOptions.dataLabels = {
			enabled: true,
			style: {
				fontSize: '12px',
				fontFamily: 'Montserrat',
			},
		}


		if (this._chartDefaults[_chartType]) {
			Object.keys(this._chartDefaults[_chartType]).map((k) => {
				this.chartOptions[k] = this._chartDefaults[_chartType][k];
			})
		} else { console.error(`${this.__name}.constructor() via TChartComponent: No chart defaults for type '${_chartType}'`) }

		this.chartOptions.series = [];
		if (!this.chartOptions.xaxis) { 
			this.chartOptions.xaxis = { categories: [] }
		} else {
			this.chartOptions.xaxis.categories = [];
		}
	}

	set defaultChartView(view:string) {
		if (!this.chartView) {
			if (!this.chartViews.includes(view)) {
				throw `Invalid view '${view}' for defaultChartView. View is not delared in chartViews.`
			} else {
				// Load the default view render from the local storage cache, 
				// and attach the notifier for changes
				this.chartView = view;
				this.localCache.attachStorageNotifier<string>(`${this.__name}.chartView`).pipe(takeUntil(this.observablesDestroy$)).subscribe(_ => this.chartView = _);
			}
		}
	}

	readonly monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

	public sum(stats1:[]|{},stats2?:[]|{},stats3?:[]|{},stats4?:[]|{},stats5?:[]|{}): number {
		let total:number = 0;
		if (Array.isArray(stats1)) {
			stats1.map((val) => { total += val });
		} else if (typeof stats1 == 'number') {
			total = stats1
		} else {
			Object.keys(stats1).map((k) => { total += stats1[k]})
		}

		if (stats2) {
			total += this.sum(stats2,stats3,stats4,stats5); // pseudo recursive, we're shifting the parameters, and only the first (stat2, which becomes stats1) gets added
		}

		return total
	}

	/**
	 * 
	 * @param data
	 * @param variation
	 * @returns 
	 */æ
	public average(data:number[], variation:string=''): number {
		let variations=[]
		if (variation == 'net') {
			for(let i = 1; i < data.length; i++) { // Calculate variation from previous period for all periods except the first (0-index) one.
				variations.push(data[i] - data[i-1])
			}
			data=variations
		} else if (variation == 'relative') {
			for(let i = 1; i < data.length; i++) { // Calculate variation from previous period for all periods except the first (0-index) one.
				variations.push((data[i] - data[i-1]) / data[i-1] * 100)
			}
			data=variations
		}
		return this.sum(data) / data.length
	}

	/**
	 * Confirms that the data received from the API is sufficient to render the charts
	 * @param stats 
	 * @returns 
	 */
	protected acceptStats(stats:Stat[],minLength): boolean {
		return Array.isArray(stats) && stats.length > minLength;
	}

	toggleChartView(view?: string): boolean {
		if (view && !this.chartViews.includes(view)) {
			throw `Invalid view '${view}' for toggleChartView. View is not delared in chartViews.`
		} else {
			if (!view) {
				const currentViewIndex = this.chartViews.indexOf(this.chartView);
				view = this.chartViews[currentViewIndex+1 == this.chartViews.length ? 0 : currentViewIndex+1]
			}
			this.localCache.storeUiKey<string>(`${this.__name}.chartView`, view);
		}
		return false; // prevents href navigation on (click)
	}

}