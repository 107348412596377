import { AfterViewInit, ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild, inject } from '@angular/core';
import { filter, takeUntil } from 'rxjs';
import { Customer } from '../core/models/troly/customer.model';
import { Document } from '../core/models/troly/document.model';
import { Order } from '../core/models/troly/order.model';
import { Product } from '../core/models/troly/product.model';
import { Task } from '../core/models/troly/task.model';
import { BaseLayout } from './base.layout';

import { MatDrawer, MatDrawerMode } from '@angular/material/sidenav';
import { Integration } from '../core/models/troly/integration.model';
import { Interaction } from '../core/models/troly/interaction.model';
import { IModalReturn } from '../core/models/troly_object';
import { uuid } from '../core/models/utils.models';
import { IUiCounter, LocalCacheService } from '../core/services/local_cache.service';
import { CustomerService } from '../core/services/troly/customer.service';
import { OrderService } from '../core/services/troly/order.service';
import { ProductService } from '../core/services/troly/product.service';
import { UserService } from '../core/services/troly/user.service';
import { DocumentViewerModal } from '../shared/ui/business/document-viewer/document-viewer.modal';
import { OrderEditModal } from '../shared/ui/orders/order-edit/order-edit.modal';
import { CreateCustomerModal } from '../shared/ui/quick-create/customer/create-customer.modal';
import { CreateEmailModal } from '../shared/ui/quick-create/email/create-email.modal';
import { CreateIntegrationModal } from '../shared/ui/quick-create/integration/create-integration.modal';
import { CreateInteractionModal } from '../shared/ui/quick-create/interaction/create-interaction.modal';
import { CreateProductModal } from '../shared/ui/quick-create/product/create-product.modal';
import { CreateSmsModal } from '../shared/ui/quick-create/sms/create-sms.modal';
import { CreateTaskModal } from '../shared/ui/quick-create/task/create-task.modal';
import { RecentService } from '../shared/widgets/recent/recent.service';
import { MENU } from './sidebar/menu';
import { MenuItem } from './sidebar/menu.model';

@Component({
	selector: 'admin-layout',
	templateUrl: './admin.layout.html',
	styleUrls: ['./admin.layout.scss'],
})
export class AdminLayout extends BaseLayout implements OnInit, AfterViewInit {

	isCondensed: boolean = false;
	isVertical: boolean = true;

	menuItems: MenuItem[] = [];

	protected orderService: OrderService = inject(OrderService);
	protected customerService: CustomerService = inject(CustomerService);
	protected productService: ProductService = inject(ProductService);
	protected userService: UserService = inject(UserService);

	/**
	 * When changing page (and tabs) the recentService allows to save the records visited 'recently.
	 */
	protected recentService: RecentService = inject(RecentService);
	protected localCache: LocalCacheService = inject(LocalCacheService);


	constructor() {

		super();

		this.menuItems = MENU;

		this.companyService.record$.pipe(filter(_ => !!_), takeUntil(this.observablesDestroy$)).subscribe(_company => {
			if (_company.integrations) { // we are not loading integrations here, the topbar is already doing that and so are other components.
				_company.integrations.map((_integration) => {
					if (_integration.status != 'uninstalled') {
						this.addAddonMenu(_integration.provider, _integration.name, _integration.logo, _integration.env?.admin_url, _integration.status, _integration.show_in_navigation); // adds or updates the the menu item
					} else {
						this.addAddonMenu(_integration.provider); // removes the menu item
					}
				});
			}
		});
	}

	/** 
	 * The side drawer is controlled by the layout, however opend/closed depending on the 
	 * user-ui cached setting (see `GenericService.cacheUiKey` and `GenericService.uiNotifier$`)
	 */
	drawerMode: MatDrawerMode = 'over';
	@ViewChild('drawer') drawer: MatDrawer;

	/**
	 * Change the open/closed/over/side status of the page drawer.
	 * @param mode if 'side' or 'over' this opens the drawer. anything else, closes.
	 */
	public toggleDrawerPin(mode?:string) {
		mode ||= this.drawerMode == 'over' ? 'side' : 'over'
		this.localCache.storeUiKey(this.CACHE_KEYS.DRAWER_MODE, mode);
	}
	

	protected cd:ChangeDetectorRef = inject(ChangeDetectorRef);
	
	ngOnInit() {

		super.ngOnInit();

		// retrieve the user default setting if one.
		this.isCondensed = this.localCache.cachedUiKey<boolean>(this.CACHE_KEYS.IS_CONDENSED, this.isCondensed);
		this.isVertical = this.localCache.cachedUiKey<boolean>(this.CACHE_KEYS.IS_VERTICAL, this.isVertical);


		// listen to changes and apply the correct body classes and attributes
		this.localCache.uiNotifier$.subscribe((_db) => {
			if (_db[this.CACHE_KEYS.IS_VERTICAL] !== undefined) {
				this.isVertical = _db[this.CACHE_KEYS.IS_VERTICAL] as boolean;
			}
			document.body.setAttribute('data-layout', this.isVertical ? 'vertical' : 'horizontal');
			document.body.classList.toggle('sidebar-enable', this.isVertical)

			if (_db[this.CACHE_KEYS.IS_CONDENSED] !== undefined) {
				this.isCondensed = _db[this.CACHE_KEYS.IS_CONDENSED] as boolean;
			}

			document.body.classList.toggle('vertical-collapsed', this.isVertical && this.isCondensed);

			if (this.drawer && _db[this.CACHE_KEYS.DRAWER_MODE]) {
				// note: 👆 the drawer is only available after the view has loaded
				if (_db[this.CACHE_KEYS.DRAWER_MODE] == 'side' || _db[this.CACHE_KEYS.DRAWER_MODE] == 'over') {
					if (!this.drawer.opened) { this.drawer.open(); }
					this.drawerMode = _db[this.CACHE_KEYS.DRAWER_MODE] as MatDrawerMode;
					this.cd.detectChanges(); // this is to make sure that Angular is aware that the view might have changed based on which panel is displayed (ultimately, just the classes applied based on the currentPanel value)
				} else {
					if (this.drawer.opened) { this.drawer.close(); }
				}
			}
		});

		//
		// 1. Listening to UI notifications for badge count display
		// 2. Load the initial values
		//
		// attach live 'new customers' count badge
		this.localCache.attachStorageNotifier<IUiCounter>('order-new-count', true).subscribe((_) => {
			if (!_  										// because we have requested `attach(name, true)` for a blank value to be sent if the initial value doesn't exist
				|| _.checkValue == undefined) { 	// when the value is incremented this is reset -- the updateRecordCounter will still enforce choking requests.
				this.orderService.updateRecordsCounter('order-new-count', { created_at: '-2..0' });
			} 
			this.updateBadge('orders-all', null, _?.count || 0, 'primary');
		});

		// attach live 'new customers' count badge
		this.localCache.attachStorageNotifier<IUiCounter>('customer-new-count', true).subscribe((_) => {
			if (!_ || _.checkValue == undefined) {
				this.customerService.updateRecordsCounter('customer-new-count', { co_created_at: '-2..0' });
			} 
			this.updateBadge('customers', 'customers-list', _?.count || 0, 'success');
		});
		
		// attach live 'new products' count badge
		this.localCache.attachStorageNotifier<IUiCounter>('product-new-count', true).subscribe((_) => {
			if (!_ || _.checkValue == undefined) {
				this.productService.updateRecordsCounter('product-new-count', { created_at: '-2..0' });
			} 
			this.updateBadge('products', 'products-list', _?.count || 0, 'success');
		});

		// attach live 'new users' count badge
		this.localCache.attachStorageNotifier<IUiCounter>('user-new-count', true).subscribe((_) => {
			if (!_ || _.checkValue == undefined) {
				this.userService.updateRecordsCounter('user-new-count', { co_created_at: '-2..0' });
			} 
			this.updateBadge('user', 'users-list', _?.count || 0, 'warning');
		});
				
	}

	ngAfterViewInit(): void {
		this.windowService.layoutActionNotifier.pipe(filter(_ => !!_),takeUntil(this.observablesDestroy$)).subscribe((_) => this.layoutAction(_) );
	}

	isMobile() {
		const ua = navigator.userAgent;
		return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua);
	}

	get dbRegion():string {
		return 'eu-fra';this.localCache.cachedUiKey('db', 'us-nyc');
	}


	/**
	 * on settings button clicked from topbar
	 */
	onToggleLayoutPreference(value?: string) {

		value = value || (this.isVertical ? 'horizontal' : 'vertical')
		this.localCache.storeUiKey<boolean>(this.CACHE_KEYS.IS_VERTICAL, value == 'vertical')
	}

	/**
	 * On mobile toggle button clicked
	 */
	onToggleSidebarCompact(value?: boolean) {
		if (value === undefined) { value = !this.isCondensed; }
		this.localCache.storeUiKey<boolean>(this.CACHE_KEYS.IS_CONDENSED, value)
	}


	/**
	 * 
	 * @param event 
	 */
	onHelpRequest(event: number) {

		if ((<any>window).Intercom) {
			if (event >= 0) {
				(<any>window).Intercom(event > 0 ? 'showMessages' : 'show')
			} else {
				(<any>window).Intercom('hide')
			}
		}

		if ((<any>window).$crisp) {
			if (event >= 0) {
				(<any>window).$crisp.push(['do', 'chat:open'])
			} else {
				(<any>window).$crisp.push(['do', 'chat:close'])
			}
		}
	}

	public addAddonMenu(provider: string, name?: string, icon?: string, url?: string, status?: string, show_in_navigation?: string) {

		let addonsMenu = this.menuItems.find(_ => _.id == 'integrations');

		if (name && (
				show_in_navigation == 'always' || (['connected','error','warning'].includes(status) && show_in_navigation != 'never')
			)) {
			let index = addonsMenu.subItems.findIndex(_ => _.id == 'Addons.' + provider)
			let menu = {};

			if (index >= 0) { menu = addonsMenu.subItems[index]; }

			icon = icon || `https://res.cloudinary.com/troly/image/upload/w_200/cdn/app/static/integrations/${provider.toLowerCase()}-icon.png`

			Object.assign(menu, {
				id: 'Addons.' + provider,
				label: name.indexOf(' ― ') >= 0 ? name.split(' ― ')[0] : name,
				icon: icon,
				//link: '/frame/'+encodeURIComponent(url),
				//link: url,
				//link: '/apps/dashboard/'+provider,
				action: { createIntegration:true, integration: { provider: provider } },
				status: ['connected', 'warning', 'error'].includes(status) ? status : ''
			});

			// Add the currently installed add-ons for this company to the menu
			// items so we can display them in the collapse menu
			addonsMenu.subItems.splice(
				index >= 0 ? index : 0, // splice at the known index, or at the beginning of the array
				index >= 0 ? 1 : 0, // remove (1) known item and replace, or replace nothing (0)
				menu);

			addonsMenu.subItems.sort((a, b) => {

				// if there's no label (the ID is used to generate the label -- only for dashboard and add menus)
				// then push down, else, sort alpha
				return !a.label ? 1 : (a.label.toLowerCase() < b.label.toLowerCase()) ? -1 : (a.label.toLowerCase() > b.label.toLowerCase()) ? 1 : 0;
			})

		} else {
			// remove the element if found.
			addonsMenu.subItems = addonsMenu.subItems.filter(_ => _.id != 'Addons.' + provider);
		}
	}

	public enableTemporaryMenu(menuId: string, label: string, url: string) {

		//this.menuItems.forEach((_parent) => {
		//_parent.find
		//});
		//let [_parent, _child] = parentchild.split('/');

		//var regexp = MENU.find((parent) => { return parent.id == _parent; }).subItems.find((children) => { return children.id == _child }).link
		//let menu = this.menuItems.find((parent) => { return parent.id == _parent; }).subItems.find((children) => { return children.id == _child })

	}


	private updateBadge(mainMenu, childMenu, count, variant) {

		if (count > 0) {
			this.menuItems.map((parent) => {
				if (parent.id === mainMenu) {
					if (childMenu) {
						parent.subItems.map((child) => {
							if (child.id === childMenu) {
								// create a badge for the mainMenu.childMenu
								child.badge = {
									variant: variant, text: count, link: child.link, linkQueryParams: { }
								}
							}
						})
					} else {
						parent.badge = {
							variant: variant, text: count, link: parent.link, linkQueryParams: {  }
						}
					}
				}
			});
		} else {
			// remove the badge from the mainMenu.childMenu
			this.menuItems.map((parent) => {
				if (parent.id === mainMenu) {
					if (childMenu) {
						parent.subItems.map((child) => {
							if (child.id === childMenu) {
								child.badge = null;
							}
						})
					} else {
						parent.badge = null;
					}
				}
			});
		}
		this.cd.detectChanges(); // when we receive a socket notification (topbar), which updates the local UI cache (via incrementUiCounter), the layout receives this update and the badge is updated, but this is so far down the notification chain that Angular doesn't see it coming through... so we force a change detection here.
	}

	@ViewChild('createCustomer') createCustomerModal: TemplateRef<CreateCustomerModal>;
	@ViewChild('createProduct') createProductModal: TemplateRef<CreateProductModal>;
	@ViewChild('editOrder') editOrderModal: TemplateRef<OrderEditModal>;
	@ViewChild('createTask') createTaskModal: TemplateRef<CreateTaskModal>;
	@ViewChild('createInteraction') createInteractionModal: TemplateRef<CreateInteractionModal>;
	@ViewChild('createSms') createSmsModal: TemplateRef<CreateSmsModal>;
	@ViewChild('createEmail') createEmailModal: TemplateRef<CreateEmailModal>;
	@ViewChild('createIntegration') createIntegrationModal: TemplateRef<CreateIntegrationModal>;
	@ViewChild('documentViewer') documentViewer: TemplateRef<DocumentViewerModal>;
	
	selectedIntegration: Integration = new Integration();
	selectedInteraction: Interaction = new Interaction();
	selectedCustomer: Customer = new Customer();
	selectedDocument: Document = new Document();
	selectedOrder: Order = new Order();
	selectedProduct: Product = new Product();
	selectedTask: Task = new Task();
	selectedRecordIds: uuid[]=[]; // used to received selected records to be passed to a modal // this is not meant to be null

	public layoutAction(message: { [key: string]: any; }): void {

		this.windowService.clearModal();
		
		// this is list of records being selected and sent for a 'bulk-enabled' modal window
		if (message['record_ids']) { this.selectedRecordIds = message['record_ids'] }
		
		if (message['document']) { this.selectedDocument = message['document'] instanceof Document ? message['document'] : new Document(message['document']); }
		if (message['customer']) { this.selectedCustomer = message['customer'] instanceof Customer ? message['customer'] : new Customer(message['customer']); }
		if (message['product']) { this.selectedProduct = message['product'] instanceof Product ? message['product'] : new Product(message['product']); }
		if (message['order']) { this.selectedOrder = message['order'] instanceof Order ? message['order'] : new Order(message['order']); }
		if (message['task']) { this.selectedTask = message['task'] instanceof Task ? message['task'] : new Task(message['task']); }
		if (message['interaction']) { this.selectedInteraction = message['interaction'] instanceof Interaction ? message['interaction'] : new Interaction(message['interaction']); }
		if (message['integration']) { this.selectedIntegration = message['integration'] instanceof Integration ? message['integration'] : new Integration(message['integration']); }

		if (message['createTask']) { this.openModal(this.createTaskModal); }
		if (message['createInteraction']) { this.openModal(this.createInteractionModal); }
		if (message['createCustomer']) { this.openModal(this.createCustomerModal); }
		if (message['createProduct']) { this.openModal(this.createProductModal); }
		
		if (message['editOrder']) { this.openXLargeModal(this.editOrderModal, this.selectedOrder) }
		if (message['createIntegration']) { this.openLargeModal(this.createIntegrationModal) }

		if (message['viewDocument']) { this.openXLargeModal(this.documentViewer) }

		if (message['sendEmail']) { this.openModal(this.createEmailModal); }
		if (message['sendSms']) { this.openModal(this.createSmsModal); }

		/**
		 *  arch: There's a question around which is better: having modal-compoents
		 */
			
		this.windowService.currentModal?.result.then((_:IModalReturn) => {
			this.selectedCustomer = new Customer();
			this.selectedProduct = new Product();
			this.selectedTask = new Task();
			this.selectedIntegration = new Integration();
			this.selectedInteraction = new Interaction();
		});

	}
}
