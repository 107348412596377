import { Component, Input, inject } from '@angular/core';

import { HttpErrorResponse } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { TrolyModal } from 'src/app/core/components/troly.modal';
import { Club } from 'src/app/core/models/troly/club.model';
import { Customer } from 'src/app/core/models/troly/customer.model';
import { TrolyFormGroup } from 'src/app/core/models/troly_form';
import { CompanyService } from 'src/app/core/services/troly/company.service';
import { CustomerService } from 'src/app/core/services/troly/customer.service';
import { TrolyValidationError } from 'src/app/core/services/troly/troly.interceptor';
import { TrolyAngularModule } from 'src/app/shared/angular.module';
import { TrolyMaterialModule } from 'src/app/shared/material.module';
import { TrolySharedModule } from 'src/app/shared/shared.module';

@Component({
	selector: 'create-customer-modal',
	templateUrl: './create-customer.modal.html',
	styleUrls: ['./create-customer.modal.scss'],
	standalone: true,
	imports: [TrolyAngularModule, TrolyMaterialModule, TrolySharedModule]
})
export class CreateCustomerModal extends TrolyModal<Customer> {

	/**
	 * The name or identifier of the current class, not otherwise available when running in "production mode".
	 * @see TrolyComponent.__name (Override)
	 */
	override readonly __name:string = 'CreateCustomerModal';

	@Input("record") record?: Customer = new Customer();

	_formFields = ['id', 'fname', 'lname', 'email', 'mobile'];
	_formDefaults = {}

	membershipForm:TrolyFormGroup;
	_membershipFormFields = ['club_id', 'segment_id'];
	_membershipFormDefaults = { club_id: null, segment_id: null };

	/**
	 * Additional services used this this component. 
	 * ? Keeping in mind CompanyService and UserService are already available in TrolyComponent. 
	 */
	protected customerService: CustomerService = inject(CustomerService);
	protected comapnyService: CompanyService = inject(CompanyService);

	constructor(protected titleService: Title) {

		super();
		
		// setting the default service for forms + obtaining/saving data in this component.
		this.service = this.customerService;
		this.initForm()

		this.membershipForm = (new Club()).mergeIfUndefined(this._membershipFormDefaults).toFormGroup(this._membershipFormFields, undefined, 'blur');

		this.membershipForm.get('club_id').valueChanges.subscribe((club_id) => {
			this.membershipForm.get('segment_id').setValue(null);
			if (!club_id || this.selectedCompany.club(club_id).segments.length == 0) {
				this.membershipForm.get('segment_id').disable();
			} else {
				this.membershipForm.get('segment_id').enable();
			}
		})

	}

	
	protected loadData(): void {

		super.loadData();

		this.initRecordAttributeOnce('clubs', this.companyService.loadClubs(), this.selectedCompany);

	}

	onSubmit(): void {

		this.markAsLoading(this.form);

		let changes = this.form.getChanges();

		//if (false) {
		if (!changes['email'] && !changes['mobile']) {
			this.form.resetCodes({ error: 'NO_CONTACT' });
			this.markAsLoaded(this.form);
		} else {
			this.form.disable();
			this.service.create(new Customer(changes)).subscribe({
				next: (_: Customer) => {


					// if we have an email 'error' but a success response
					// that means we have entered an email for an existing
					// customer and must redirect them to that customer
					if (_.errors && _.errors.email) {

						this.form.controls['id'].setValue(_.id);
						this.form.resetCodes({ success: 'ALREADY_EXISTS' });
						this.form.enable();

					} else {

						this.form.controls['id'].setValue(_.id);
						this.form.resetCodes({ success: (this.record.id ? 'SAVED' : 'CREATED') });

					}

					this.record = _;

					// delay redirection by a second so the user can see the success message
					this.form._timer = setTimeout(() => {
						this.resolveModal('timeout');
					}, this.form.countdown());

				}, 
				error: (err: HttpErrorResponse) => {

					if (err instanceof TrolyValidationError) {
						
						if (err.error.errors.email) {

							this.form.controls['id'].setValue(err.error.id);
							this.form.resetCodes({ success: 'ALREADY_EXISTS' });
							this.form.enable();
						} else {
							this.form.assignValidationErrors(err.error);
							this.markAsLoaded(this.form);
						}
					} else {
						this.unknownApiError('2781-carouse-BRUNET-rubric')
					}
				}
			});
		}
	}

	// override to support redirect to created customer record
	public resolveModal(from?: string): boolean {

		if (from == 'redirect' || from == 'timeout') {
			// See routerNaviate: https://www.tektutorialshub.com/angular/angular-navigating-between-routes/
			this.router.navigate([`/customers/${this.form.get('id').value}`]);
		}

		return super.resolveModal(from); // clear the form timer and closes active popup
	}

}
