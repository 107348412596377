import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'happy-or-not',
	templateUrl: './happy-or-not.widget.html',
	styleUrls: ['./happy-or-not.widget.scss'],
})
export class HappyOrNotWidget {

	@Input() neutral_bg: string = 'ffffff';
	@Input() neutral_fg: string = '000000';

	@Input() happy_bg: string; 
	@Input() happy_fg: string; 
	@Input() unhappy_bg: string; 
	@Input() unhappy_fg: string; 

	@Input() size: number = 80;

	@Input() set value(v: 'happy'|'unhappy'|'uncertain') { this._value = v; }
	@Output() change: EventEmitter<'happy'|'unhappy'|'uncertain'> = new EventEmitter<'happy'|'unhappy'|'uncertain'>();

	public _value:'happy'|'unhappy'|'uncertain';

	ngOnInit() {
		this.change.subscribe(_ => { 
			console.log('change', _)
			this._value = _
		})
	}
	
	toggleValue(trigger:'happy'|'unhappy') {
		console.log('toggleValue', trigger)
		if (this._value == trigger) { this.change.next('uncertain') } 
		else { this.change.next(trigger); }
	}

	get happyTransformations(): {} {
		return { c:'scale', h:this.size*3, w:this.size*3, e:`replace_color:${this.happy_fg || '43A047'}:99:000000`, f:'png' }
	}
	get unhappyTransformations(): {} {
		return { c:'scale', h:this.size*3, w:this.size*3, e:`replace_color:${this.unhappy_fg || 'dd4d4d'}:99:000000`, f:'png' }
	}
}