<!-- ========== Left Sidebar Start ========== -->

<!-- Sidebar -->

<div id="sidebar-nav" class="vertical-menu">
	<ng-scrollbar visibility="hover">
        <!--- Sidemenu -->
		<div id="sidebar-menu" class="a92163b4">

			<!-- Left Menu Start -->
			<ul class="metismenu list-unstyled" id="side-menu" #sideMenu>
				<li *ngFor="let item of menuItems" class="dropdown-menu-title" (click)="showSubMenu($event)"
					routerLinkActive="active">

					<ng-container *ngTemplateOutlet="renderLink; context: { item: item, depth:0 }"></ng-container>

				</li>
			</ul>
		</div>
	</ng-scrollbar>
</div>
<!-- Left Sidebar End -->

<ng-template #renderLink let-item="item" let-depth="depth">

    <a *ngIf="item.action || hasItems(item)" id="SidebarMenu.{{item.id}}" href class="side-nav-link-ref" [ngClass]="{ 
            'has-arrow': !item.badge && hasItems(item),
            'menu-title': hasItems(item),
            'is-parent': hasItems(item),
            'mm-active': item.defaultExpanded
        }" (click)="!!layoutAction(item.action);">
        <ng-container *ngIf="item?.icon">
            <i *ngIf="['bx','md'].includes(item.icon.substring(0,2))"
                class="{{ item.icon.substring(0,2) == 'bx' ? 'bx' : 'mdi' }} {{ item.icon }}"></i>
            <img [src]="item.icon" *ngIf="!['bx','md'].includes(item.icon.substring(0,2))" />
        </ng-container>

        <em *ngIf="!item.icon && isCondensed"></em>

        <span>
            <span *ngIf="!item['label']">{{ 'Sidebar.Menu.' + item.id | translate }}</span>
            <!-- no labels specified: using translations with partial menu ID -->
            <span *ngIf="item['label'] && item['label'].indexOf('.') > 0">{{ item.label | translate }}</span>
            <!-- label specified with dot in name: using translation as full ID -->
            <span *ngIf="item['label'] && item['label'].indexOf('.') < 0">{{ item.label }}</span>
            <!-- label specified without do - not using translation -->

            <i *ngIf="item.status" class="bx align-middle ms-1 font-size-16" [ngClass]="{
                'bxs-error-circle': item.status == 'warning' || item.status == 'connected',
                'text-warning': item.status == 'warning' || item.status == 'connected',

                'bxs-x-circle': item.status == 'error',
                'text-danger': item.status == 'error'
            }"></i>

            <span *ngIf="item.badge" class="badge float-end with-link bg-{{ item.badge.variant }}">
                <a (click)="onBadgeClick($event)" [routerLink]="item.badge.link" [fragment]="item.badge.linkFragment"
                    [queryParams]="item.badge.linkQueryParams">{{ item.badge.label }}</a>
            </span>
        </span>
    </a>
    
    
    <!-- 
      the only reason why we have two links (almost identical) is to allow for [router] base links and (click)-based links, given the (click) and [routerLink] together don't play ball very well 
    -->
    <a *ngIf="!item.action && !hasItems(item)" id="SidebarMenu.{{item.id}}" href class="side-nav-link-ref" [ngClass]="{ 
            'has-arrow': !item.badge && hasItems(item),
            'menu-title': hasItems(item),
            'is-parent': hasItems(item),
            'mm-active': item.defaultExpanded
        }" [routerLink]="item.link" [queryParams]="item.linkQueryParams" [fragment]="item.linkFragment"
        routerLinkActive="active">
        <ng-container *ngIf="item.icon">
            <i *ngIf="['bx','md'].includes(item.icon.substring(0,2))"
                class="{{ item.icon.substring(0,2) == 'bx' ? 'bx' : 'mdi' }} {{ item.icon }}"></i>
            <img [src]="item.icon" *ngIf="!['bx','md'].includes(item.icon.substring(0,2))" />
        </ng-container>

        <em *ngIf="!item.icon && isCondensed"></em>

        <span>
            <span *ngIf="!item['label']">{{ 'Sidebar.Menu.' + item.id | translate }}</span>
            <!-- no labels specified: using translations with partial menu ID -->
            <span *ngIf="item['label'] && item['label'].indexOf('.') > 0">{{ item.label | translate }}</span>
            <!-- label specified with dot in name: using translation as full ID -->
            <span *ngIf="item['label'] && item['label'].indexOf('.') < 0">{{ item.label }}</span>
            <!-- label specified without do - not using translation -->

            <i *ngIf="item.status" class="bx align-middle ms-1 font-size-16" [ngClass]="{
                'bxs-error-circle': item.status == 'warning' || item.status == 'connected',
                'text-warning': item.status == 'warning' || item.status == 'connected',

                'bxs-x-circle': item.status == 'error',
                'text-danger': item.status == 'error'
            }"></i>

            <span *ngIf="item.badge" class="badge float-end with-link bg-{{ item.badge.variant }}">
                <a (click)="onBadgeClick($event)" [routerLink]="item.badge.link" [fragment]="item.badge.linkFragment"
                    [queryParams]="item.badge.linkQueryParams">{{ item.badge.text }} new</a>
            </span>
        </span>
    </a>

    <ul *ngIf="hasItems(item)" class="sub-menu" [ngClass]="{ 'mm-show': item.defaultExpanded }"
        aria-expanded="item.defaultExpanded">
        <li *ngFor="let subItem of item.subItems" [ngClass]="{ 'd-none': subItem.hidden }">
            <ng-container *ngTemplateOutlet="renderLink; context: { item: subItem, depth: depth+1 }"></ng-container>
        </li>
    </ul>

</ng-template>